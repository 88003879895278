import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import PaymentLoader from '../../components/reusableComponents/PaymentLoader';
import { FlightBookTripJackDispatch } from '../../reducers/TripjackReducer';
import {
	FlightTicketLCC1WDispatch,
	FlightBookDispatch,
	FlightBookEtravDispatch,
	FlightTicketLCCOnwardDispatch,
	FlightTicketLCCReturnDispatch,
	FlightTicketLCCDispatch,
	FlightBookOnwardDispatch,
	FlightBookReturnDispatch,
} from '../../reducers/HomeReducer';
import { busBookingDispatch } from '../../reducers/BusReducer';
import { hotelBookDispatch } from '../../reducers/HotelReducer';
function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}
const PaymentVerify = () => {
	let query = useQuery();
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		bookingformData,
		selectedFlight,
		bookingformDataReturn,
		hotelbookingData,
	} = useSelector((state) => state.ui);
	const { loadtrip } = useSelector((state) => state.tripjack);
	const { agentlogin } = useSelector((state) => state.user);
	const { loading } = useSelector((state) => state.home);
	const { loadinghoteldes } = useSelector((state) => state.hotel);
	const { busBlock, loadingbus, addBusSearch } = useSelector(
		(state) => state.bus
	);
	const { load } = useSelector((state) => state.etrav);
	console.log('formdata', bookingformData);
	console.log('busBlock', busBlock);
	// console.log(query.get('validationString'));
	useEffect(() => {
		if (query.get('status') === 'success') {
			if (selectedFlight?.body) {
				if (selectedFlight) {
					if (selectedFlight?.OneflightData?.Supplier === 'TJK') {
						dispatch(FlightBookTripJackDispatch(bookingformData, history));
					}
					if (selectedFlight?.OneflightData?.Supplier === 'TBO') {
						if (selectedFlight.OneflightData?.IsLCC === false) {
							dispatch(FlightBookDispatch(bookingformData, history));
						} else if (selectedFlight?.OneflightData?.IsLCC) {
							dispatch(
								FlightTicketLCC1WDispatch(bookingformData, history, agentlogin)
							);
						}
					} else if (selectedFlight?.OneflightData?.Supplier === 'ETR') {
						dispatch(
							FlightBookEtravDispatch(bookingformData, history, agentlogin)
						);
					}
				}
			} else if (selectedFlight?.returnBody) {
				if (selectedFlight?.selectedReturn && selectedFlight?.selectedonward) {
					if (selectedFlight?.selectedReturn[0]?.IsLCC) {
						dispatch(
							FlightTicketLCCReturnDispatch(
								bookingformDataReturn,
								history,
								selectedFlight,
								agentlogin
							)
						);
					}
					if (selectedFlight?.selectedonward[0]?.IsLCC) {
						dispatch(
							FlightTicketLCCOnwardDispatch(
								bookingformData,
								history,
								selectedFlight,
								agentlogin
							)
						);
					}
					if (selectedFlight?.selectedReturn[0]?.IsLCC === false) {
						dispatch(FlightBookReturnDispatch(bookingformDataReturn, history));
					}
					if (selectedFlight?.selectedonward[0]?.IsLCC === false) {
						dispatch(FlightBookOnwardDispatch(bookingformData, history));
					}
				} else {
					if (selectedFlight?.intFlight?.IsLCC) {
						// dispatch(FlightBookEtravDispatch(bookingformData, history, agentlogin));
						dispatch(
							FlightTicketLCCDispatch(
								bookingformData,
								history,
								selectedFlight,
								agentlogin
							)
						);
					}
					if (selectedFlight?.intFlight?.IsLCC === false) {
						dispatch(FlightBookDispatch(bookingformData, history));
					}
				}
			} else if (hotelbookingData) {
				dispatch(hotelBookDispatch(hotelbookingData, history));
			} else {
				let formdata = {
					blockKey: busBlock.result.blockKey,
					orderId: addBusSearch.result,
				};
				dispatch(busBookingDispatch(formdata, history));
				console.log('Bussection');
			}
		}
	}, []);
	return (
		<>
			<HeaderSection />
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: '#F8F8F8AD',
				}}>
				<div
					style={{
						left: '20%',
						top: '30%',
						zIndex: 1000,
						position: 'absolute',
					}}>
					{loadtrip || loading || load || loadingbus || loadinghoteldes ? (
						<>
							<div style={{ fontSize: 35, textAlign: 'center' }}>
								Don't Refresh This Page
							</div>
							<div style={{ fontSize: 35, textAlign: 'center' }}>
								Booking is UnderProcess Please Wait.....
							</div>
							<div style={{ textAlign: 'center' }}>
								<img
									style={{ height: 150 }}
									src={
										'https://i.gifer.com/origin/b4/b4d657e7ef262b88eb5f7ac021edda87.gif'
									}
								/>
							</div>
						</>
					) : (
						<>
							<div style={{ fontSize: 35, textAlign: 'center' }}>
								!!Ticket might have been issued.!!
							</div>
							<div style={{ fontSize: 35, textAlign: 'center' }}>
								Please Contact Your Supplier
							</div>
						</>
					)}
				</div>
			</div>
			{/* <PaymentLoader /> */}
		</>
	);
};

export default PaymentVerify;
