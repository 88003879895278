import React from 'react';
import { makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme) => ({
	loaderCont: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: '#F8F8F8AD',
	},
	loader: {
		left: '40%',
		top: '35%',
		zIndex: 1000,
		position: 'absolute',
		[theme.breakpoints.down('xs')]: {
			left: '15%',
		},
	},
}));

const HomeLoader = () => {
	const classes = useStyles();

	return (
		<div className={classes.loaderCont}>
			<div className={classes.loader}>
				{/* <CircularProgress color='secondary' />
				 */}
				<img src='https://www.marinersforex.com/img/LOGO-MarinersForex.png' />
			</div>
		</div>
	);
};

export default HomeLoader;
