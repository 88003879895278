import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import '../../main.css';
import LoadingButton from '@mui/lab/LoadingButton';
import PrintIcon from '@mui/icons-material/Print';
import { useLocation } from 'react-router-dom';
import { FlightMailTicketDispatch } from '../../reducers/HomeReducer';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
const useStyles = makeStyles((theme) => ({
	table: {
		background: '#fff',
		margin: '0px auto',
		width: '850px !important',
		padding: '30px 20px',
		webkitPrintColorAdjust: 'exact',
	},
	table2: {
		boxShadow: '1px 1px 15px rgba(0, 0, 0, 0.1)',
		background: '#fff',
		borderSpacing: 0,
		padding: 0,
		margin: 0,
	},
	table3: {
		width: '100%',
		padding: 0,
		margin: 0,
	},
	row: {
		width: '60%',
		padding: '15px 40px',
	},
	row2: {
		width: '50%',
		border: '1px solid #333',
		borderLeft: 0,
		padding: '8px',
	},
	row3: {
		width: '50%',
		borderTop: '1px solid #333',
		borderBottom: '1px solid #333',
		padding: '5px',
	},
}));

const ManageBookingTicket = () => {
	const { ticket, logo } = useSelector((state) => state.home);
	const location = useLocation();
	const classes = useStyles();
	const [loading, setLoading] = React.useState(false);
	const dispatch = useDispatch();
	console.log('Location', location);
	const handleClick = () => {
		setLoading(true);
		let formMail = {
			orderId: location.state.mailBody.orderID,
			userEmail: location.state.mailBody.formData.email,
		};
		dispatch(FlightMailTicketDispatch(formMail));
	};
	return (
		<>
			<HeaderSection id={1} />
			<section
				style={{
					background: '#0c2f55',
					color: 'white',
					marginTop: 50,
					padding: 5,
				}}
				id='ticket2'>
				<h1 style={{ textAlign: 'center', fontFamily: 'Poppins' }}>
					Print Your E-Ticket
				</h1>
			</section>
			<Grid
				container
				style={{ paddingLeft: 20, marginTop: 50, marginBottom: 100 }}>
				<Grid item xs={12} style={{ paddingRight: 20 }}>
					<table className={classes.table}>
						<tbody>
							<tr>
								<td>
									<table className={classes.table2}>
										<tbody>
											<tr>
												<td>
													<table className={classes.table3}>
														<tbody>
															<tr>
																<td className={classes.row}>
																	<img
																		src={logo?.result[0].logo}
																		style={{
																			height: 50,
																		}}></img>
																</td>
															</tr>
														</tbody>
													</table>
													<table className={classes.table3}>
														<tbody>
															<tr>
																<td className={classes.row2}>
																	<p
																		style={{
																			marginBottom: '5px',
																			color: '#777',
																		}}>
																		<span
																			style={{
																				fontWeight: 400,
																				fontSize: 14,
																			}}>
																			Booking Time:{' '}
																			{
																				location?.state?.result?.flightDetails?.dob?.split(
																					' '
																				)[0]
																			}
																		</span>
																	</p>
																	<p
																		style={{
																			marginBottom: '5px',
																			color: '#777',
																		}}>
																		<span
																			style={{
																				fontWeight: 400,
																				fontSize: 14,
																			}}>
																			Booking ID:
																			{
																				location?.state?.result?.flightDetails
																					?.orderId
																			}
																		</span>
																	</p>
																	<p
																		style={{
																			marginBottom: '5px',
																			color: '#777',
																		}}>
																		<span
																			style={{
																				fontWeight: 400,
																				fontSize: 14,
																			}}>
																			Booking Status:
																		</span>
																		<span
																			style={{
																				fontWeight: 'bold',
																				fontSize: 14,
																				color: 'black',
																			}}>
																			{' '}
																			CONFIRMED
																		</span>
																	</p>
																</td>
																<td className={classes.row3}>
																	<br></br>
																	<table
																		style={{
																			width: '100%',
																			marginBottom: '15px',
																		}}>
																		<tbody>
																			<tr>
																				<td style={{ width: 40 }}>
																					<p>
																						<span style={{ fontWeight: 400 }}>
																							<img
																								src={`https://nitish.musafirbazar.com/static/media/${location?.state?.result?.flightDetails?.carrier}.gif`}
																								style={{
																									marginRight: 5,
																									verticalAlign: 'middle',
																									width: 30,
																								}}></img>
																						</span>
																					</p>
																				</td>
																				<td style={{ width: 40 }}>
																					<p
																						style={{
																							fontWeight: 400,
																							fontSize: 12,
																						}}>
																						{ticket &&
																							ticket?.result &&
																							ticket?.result?.Response &&
																							ticket?.result?.Response
																								?.Response &&
																							ticket?.result?.Response?.Response
																								?.FlightItinerary?.Segments[0]
																								?.Airline.AirlineName}
																					</p>
																				</td>
																				<td style={{ textAlign: 'center' }}>
																					<p
																						style={{
																							fontWeight: 'bold',
																							fontSize: 20,
																						}}>
																						<strong>
																							{
																								location?.state?.result
																									?.flightDetails
																									?.bookingRefrence
																							}
																						</strong>
																					</p>
																					<p>
																						<span
																							style={{
																								fontWeight: 400,
																								fontSize: 12,
																								color: '#777',
																							}}>
																							Airline PNR
																						</span>
																					</p>
																				</td>
																			</tr>
																		</tbody>
																	</table>
																</td>
															</tr>
														</tbody>
													</table>
													<table className={classes.table3}>
														<tbody>
															<tr>
																<td style={{ width: '30%' }}>
																	<p
																		style={{
																			background: '#000',
																			padding: '5px',
																			color: '#fff',
																			paddingLeft: 20,
																			webkitPrintColorAdjust: 'exact',
																		}}>
																		<span
																			style={{
																				fontWeight: 400,
																				fontSize: 14,
																			}}>
																			Flight Detail
																		</span>
																	</p>
																</td>
																<td style={{ width: '70%' }} align='right'>
																	<p
																		style={{
																			background: '#000',
																			padding: 5,
																			color: '#fff',
																			webkitPrintColorAdjust: 'exact',
																		}}>
																		<em>
																			<span
																				style={{
																					fontWeight: 400,
																					fontSize: 14,
																				}}>
																				*Please verify flight timings & terminal
																				info with the airlines
																			</span>
																		</em>
																	</p>
																</td>
															</tr>
														</tbody>
													</table>
													<table
														style={{
															width: '100%',
															lineHeight: 'normal',
															padding: 0,
															margin: 0,
														}}>
														<tbody>
															<tr>
																<td style={{ width: '20%' }}>
																	<p
																		style={{
																			background: '#BCBEC0',
																			color: '#000',
																			padding: '3px',
																			paddingLeft: '20px',
																			fontSize: 12,
																		}}>
																		<span style={{ fontWeight: 400 }}>
																			FLight
																		</span>
																	</p>
																</td>
																<td style={{ width: '10%' }}>
																	<p
																		style={{
																			background: '#BCBEC0',
																			color: '#000',
																			padding: '3px',
																			paddingLeft: '20px',
																			fontSize: 12,
																		}}>
																		<span style={{ fontWeight: 400 }}>
																			Class
																		</span>
																	</p>
																</td>
																<td style={{ width: '10%' }}>
																	<p
																		style={{
																			background: '#BCBEC0',
																			color: '#000',
																			padding: '3px',
																			paddingLeft: '20px',
																			fontSize: 12,
																		}}>
																		<span style={{ fontWeight: 400 }}>
																			Stop
																		</span>
																	</p>
																</td>
																<td style={{ width: '20%' }}>
																	<p
																		style={{
																			background: '#BCBEC0',
																			color: '#000',
																			padding: '3px',
																			paddingLeft: '20px',
																			fontSize: 12,
																		}}>
																		<span style={{ fontWeight: 400 }}>
																			Departing
																		</span>
																	</p>
																</td>
																<td style={{ width: '20%' }}>
																	<p
																		style={{
																			background: '#BCBEC0',
																			color: '#000',
																			padding: '3px',
																			paddingLeft: '20px',
																			fontSize: 12,
																		}}>
																		<span style={{ fontWeight: 400 }}>
																			Arriving
																		</span>
																	</p>
																</td>
																<td style={{ width: '10%' }}>
																	<p
																		style={{
																			background: '#BCBEC0',
																			color: '#000',
																			padding: '3px',
																			paddingLeft: '20px',
																			fontSize: 12,
																		}}>
																		<span style={{ fontWeight: 400 }}>
																			Duration
																		</span>
																	</p>
																</td>
															</tr>
														</tbody>
													</table>
													{location?.state?.result?.flightDetails.supplier ===
													'ETR'
														? location?.state?.result?.flightSegments?.Segments?.map(
																(item) => {
																	return (
																		<table
																			style={{
																				borderBottom: '1px solid #cfcbcb',
																				width: '100%',
																			}}>
																			<tbody>
																				<tr>
																					<td
																						width='20%'
																						style={{
																							padding: 2,
																							fontSize: 12,
																							paddingLeft: 20,
																						}}>
																						<p>
																							<strong style={{ color: '#000' }}>
																								{item.Airline_Code}-
																								{item.Flight_Number}
																							</strong>
																						</p>
																						<p>
																							<span style={{ fontWeight: 400 }}>
																								{item.Airline_Name}
																							</span>
																						</p>
																					</td>
																					<td
																						width='10%'
																						style={{
																							padding: 2,
																							fontSize: 12,
																						}}>
																						<p>
																							<span style={{ fontWeight: 400 }}>
																								{/* {item.Airline.FareClass} */}
																							</span>
																						</p>
																					</td>
																					<td width='10%'>
																						<p style={{ fontSize: 12 }}>
																							<span style={{ fontWeight: 400 }}>
																								Non Stop
																							</span>
																						</p>
																					</td>
																					<td width='20%'>
																						<p style={{ fontSize: 12 }}>
																							<strong color='#000'>
																								{
																									item.Departure_DateTime.split(
																										' '
																									)[1]
																								}
																							</strong>
																						</p>
																						<p style={{ fontSize: 12 }}>
																							<span style={{ fontWeight: 400 }}>
																								{item.Origin}
																							</span>
																						</p>
																					</td>
																					<td width='20%'>
																						<p style={{ fontSize: 12 }}>
																							<strong color='#000'>
																								{
																									item.Departure_DateTime.split(
																										' '
																									)[1]
																								}
																							</strong>
																						</p>
																						<p style={{ fontSize: 12 }}>
																							<span style={{ fontWeight: 400 }}>
																								{item.Destination}
																							</span>
																						</p>
																					</td>
																					<td width='10%'>
																						<p style={{ fontSize: 12 }}>
																							<strong color='#000'>
																								{item.Duration} min
																							</strong>
																						</p>
																					</td>
																				</tr>
																			</tbody>
																		</table>
																	);
																}
														  )
														: location?.state?.result?.flightSegments?.Segments[0]?.map(
																(item) => {
																	return (
																		<table
																			style={{
																				borderBottom: '1px solid #cfcbcb',
																				width: '100%',
																			}}>
																			<tbody>
																				<tr>
																					<td
																						width='20%'
																						style={{
																							padding: 2,
																							fontSize: 12,
																							paddingLeft: 20,
																						}}>
																						<p>
																							<strong style={{ color: '#000' }}>
																								{item.Airline.AirlineCode}-
																								{item.Airline.FlightNumber}
																							</strong>
																						</p>
																						<p>
																							<span style={{ fontWeight: 400 }}>
																								{item.Airline.AirlineName}
																							</span>
																						</p>
																					</td>
																					<td
																						width='10%'
																						style={{
																							padding: 2,
																							fontSize: 12,
																						}}>
																						<p>
																							<span style={{ fontWeight: 400 }}>
																								{item.Airline.FareClass}
																							</span>
																						</p>
																					</td>
																					<td width='10%'>
																						<p style={{ fontSize: 12 }}>
																							<span style={{ fontWeight: 400 }}>
																								Non Stop
																							</span>
																						</p>
																					</td>
																					<td width='20%'>
																						<p style={{ fontSize: 12 }}>
																							<strong color='#000'>
																								{item.Origin.DepTime}
																							</strong>
																						</p>
																						<p style={{ fontSize: 12 }}>
																							<span style={{ fontWeight: 400 }}>
																								{
																									item.Origin.Airport
																										.AirportCode
																								}
																							</span>
																						</p>
																					</td>
																					<td width='20%'>
																						<p style={{ fontSize: 12 }}>
																							<strong color='#000'>
																								{item.Destination.ArrTime}
																							</strong>
																						</p>
																						<p style={{ fontSize: 12 }}>
																							<span style={{ fontWeight: 400 }}>
																								{
																									item.Destination.Airport
																										.AirportCode
																								}
																							</span>
																						</p>
																					</td>
																					<td width='10%'>
																						<p style={{ fontSize: 12 }}>
																							<strong color='#000'>
																								{item.Duration} min
																							</strong>
																						</p>
																					</td>
																				</tr>
																			</tbody>
																		</table>
																	);
																}
														  )}

													<table style={{ marginTop: 12, width: '100%' }}>
														<tbody>
															<tr>
																<td width='100%'>
																	<p
																		style={{
																			background: '#000',
																			color: '#fff',
																			padding: '5px',
																			paddingLeft: '20px',
																			fontSize: 14,
																			webkitPrintColorAdjust: 'exact',
																		}}>
																		Passenger Details
																	</p>
																</td>
															</tr>
														</tbody>
													</table>
													<table
														style={{
															color: '#000',
															width: '100%',
															verticalAlign: 'middle',
														}}>
														<tbody>
															<tr>
																<td
																	style={{
																		borderBottom: '1px solid #333',
																		background: '#BCBEC0',
																		webkitPrintColorAdjust: 'exact',
																	}}>
																	<p
																		style={{
																			color: '#000',
																			padding: '3px',
																			fontSize: 14,
																		}}>
																		Sr.
																	</p>
																</td>
																<td
																	style={{
																		borderBottom: '1px solid #333',
																		background: '#BCBEC0',
																		webkitPrintColorAdjust: 'exact',
																	}}>
																	<p
																		style={{
																			color: '#000',
																			padding: '3px',
																			fontSize: 14,
																		}}>
																		Name
																	</p>
																</td>
																<td
																	style={{
																		borderBottom: '1px solid #333',
																		background: '#BCBEC0',
																		webkitPrintColorAdjust: 'exact',
																	}}>
																	<p
																		style={{
																			color: '#000',
																			padding: '3px',
																			fontSize: 14,
																		}}>
																		Type
																	</p>
																</td>
																<td
																	style={{
																		borderBottom: '1px solid #333',
																		background: '#BCBEC0',
																		webkitPrintColorAdjust: 'exact',
																	}}>
																	<p
																		style={{
																			color: '#000',
																			padding: '3px',
																			fontSize: 14,
																		}}>
																		PNR & Ticket No.
																	</p>
																</td>
																<td
																	style={{
																		borderBottom: '1px solid #333',
																		background: '#BCBEC0',
																		fontSize: 14,
																		webkitPrintColorAdjust: 'exact',
																	}}>
																	Baggage
																	<span
																		style={{
																			fontSize: '8px',
																			lineHeight: '12px',
																			display: 'block',
																		}}>
																		Check-in | Cabin
																	</span>
																</td>
															</tr>
															{location?.state?.result?.flightPassengers?.map(
																(item, index) => {
																	return (
																		<tr>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					borderLeft: '1px solid #333',
																					padding: 2,
																				}}>
																				<p style={{ fontSize: 12 }}>
																					{index + 1}
																				</p>
																			</td>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p style={{ fontSize: 12 }}>
																					{''}
																					{`${item.title} ${item.firstname} ${item.lastname}`}
																				</p>
																			</td>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p
																					style={{
																						fontSize: 12,
																						fontWeight: 'bold',
																					}}>
																					{item.PaxType}
																				</p>
																			</td>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p
																					style={{
																						fontSize: 12,
																						fontWeight: 'bold',
																					}}>
																					{
																						location.state.result.flightDetails
																							.bookingRefrence
																					}
																				</p>
																			</td>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p
																					style={{
																						fontSize: 12,
																						fontWeight: 'bold',
																					}}>
																					{location?.state?.result
																						?.flightDetails.supplier === 'ETR'
																						? `${location.state.result.flightSegments.Fares.FareDetails[0].Free_Baggage.Check_In_Baggage} | ${location.state.result.flightSegments.Fares.FareDetails[0].Free_Baggage.Hand_Baggage}`
																						: location.state.result.flightSegments.Segments[0]
																								.filter(
																									(i, index) => index === 0
																								)
																								.map((item) => item.Baggage)}
																				</p>
																			</td>
																		</tr>
																	);
																}
															)}
														</tbody>
													</table>
													<table style={{ marginTop: '12px', width: '100%' }}>
														<tbody>
															<tr>
																<td>
																	<p
																		style={{
																			background: '#000',
																			color: '#fff',
																			padding: '5px',
																			paddingLeft: 20,
																			fontSize: 12,
																			webkitPrintColorAdjust: 'exact',
																		}}>
																		Fare Details
																	</p>
																</td>
															</tr>
														</tbody>
													</table>
													<table
														style={{
															border: '1px solid #333',
															width: '100%',
														}}>
														<tbody>
															<tr>
																<td
																	style={{
																		padding: 3,
																		fontSize: 12,
																		color: '#000',
																		paddingLeft: '20px',
																	}}>
																	Total Price
																</td>
																<td
																	style={{
																		padding: 3,
																		fontWeight: 'bold',
																	}}>
																	<p>
																		INR{' '}
																		{
																			location.state.result.flightDetails
																				.ticketCost
																		}
																	</p>
																</td>
															</tr>
														</tbody>
													</table>
													<table style={{ marginTop: 12, width: '100%' }}>
														<tbody>
															<tr>
																<td>
																	<p
																		style={{
																			background: '#000',
																			color: '#fff',
																			padding: '5px',
																			paddingLeft: '20px',
																			fontSize: 12,
																			webkitPrintColorAdjust: 'exact',
																		}}>
																		Important Information
																	</p>
																</td>
															</tr>
														</tbody>
													</table>
													<table
														style={{
															width: '100%',
															marginBottom: '40px',
															paddingTop: '15px',
														}}>
														<tbody>
															<tr>
																<td
																	style={{
																		padding: '0 8px',
																		fontSize: 13,
																		color: '#000',
																		width: '100%',
																	}}>
																	1 - You must web check-in on the airline
																	website and obtain a boarding pass.
																</td>
															</tr>
															<tr>
																<td
																	style={{
																		padding: '0 8px',
																		fontSize: 13,
																		color: '#000',
																		width: '100%',
																	}}>
																	1 - You must web check-in on the airline
																	website and obtain a boarding pass.
																</td>
															</tr>
															<tr>
																<td
																	style={{
																		padding: '0 8px',
																		fontSize: 13,
																		color: '#000',
																		width: '100%',
																	}}>
																	1 - You must web check-in on the airline
																	website and obtain a boarding pass.
																</td>
															</tr>
															<tr>
																<td
																	style={{
																		padding: '0 8px',
																		fontSize: 13,
																		color: '#000',
																		width: '100%',
																	}}>
																	1 - You must web check-in on the airline
																	website and obtain a boarding pass.
																</td>
															</tr>
															<tr>
																<td
																	style={{
																		padding: '0 8px',
																		fontSize: 13,
																		color: '#000',
																		width: '100%',
																	}}>
																	1 - You must web check-in on the airline
																	website and obtain a boarding pass.
																</td>
															</tr>
															<tr>
																<td
																	style={{
																		padding: '0 8px',
																		fontSize: 13,
																		color: '#000',
																		width: '100%',
																	}}>
																	1 - You must web check-in on the airline
																	website and obtain a boarding pass.
																</td>
															</tr>
														</tbody>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
					<table className={classes.table}>
						<tbody>
							<tr>
								<td>
									<LoadingButton
										id='myPrntbtn'
										onClick={() => window.print()}
										loadingPosition='start'
										startIcon={<PrintIcon />}
										variant='contained'>
										Print
									</LoadingButton>
								</td>
								{/* <td>
									<LoadingButton
										id='download'
										loadingPosition='start'
										startIcon={<DownloadForOfflineIcon />}
										variant='contained'>
										Download
									</LoadingButton>
								</td> */}
								{/* <td>
									<LoadingButton
										id='downloadwprice'
										loadingPosition='start'
										startIcon={<DownloadForOfflineIcon />}
										variant='contained'>
										Download without price
									</LoadingButton>
								</td>
								<td>
									<LoadingButton
										id='mail'
										// color='secondary'
										onClick={handleClick}
										loading={loading}
										loadingPosition='start'
										startIcon={<EmailIcon />}
										variant='contained'>
										Mail
									</LoadingButton>
								</td>
								<td>
									<LoadingButton
										id='editprice'
										// color='secondary'
										onClick={() => dispatch(toggleEditTicketModal(true))}
										// loading={loading}
										loadingPosition='start'
										startIcon={<EditIcon />}
										variant='contained'>
										Edit Price
									</LoadingButton>
								</td> */}
							</tr>
						</tbody>
					</table>
				</Grid>
			</Grid>
		</>
	);
};

export default ManageBookingTicket;
