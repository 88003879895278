import React from 'react';
import { Box, makeStyles, TableRow, TableCell } from '@material-ui/core';
import BodyText from './BodyText';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toggleFlightDetailModal } from '../../reducers/UiReducer';
const useStyles = makeStyles(() => ({
	optionsIcon: {
		height: 25,
		width: 25,
		backgroundColor: '#FDE8EE',
		padding: 3,
		borderRadius: '50%',
	},
	tableCell: {
		fontSize: 14,
		// padding: 3,
		color: 'rgb(0 0 0 / 73%)',
		fontWeight: 600,
	},
	img: {
		'& .MuiAvatar-img': {
			width: 30,
			height: 30,
		},
	},
}));
const BusPassengerRow = ({ parseData }) => {
	const classes = useStyles();
	console.log('first', parseData);
	return (
		<>
			{parseData.map((i) => {
				return (
					<TableRow style={{ marginTop: 20 }}>
						{/* <TableCell className={classes.tableCell}>
							{i.PaxType === 1
								? 'Adult'
								: i.PaxType === 2
								? 'Child'
								: i.PaxType === 3
								? 'Infant'
								: ''}
						</TableCell> */}
						<TableCell className={classes.tableCell}>{`${i.name}`}</TableCell>
						<TableCell className={classes.tableCell}>{i.gender}</TableCell>
						<TableCell className={classes.tableCell}>{i.age}</TableCell>
					</TableRow>
				);
			})}
		</>
	);
};

export default BusPassengerRow;
