import React, { useEffect, useState } from 'react';
import {
	Grid,
	TextField,
	Box,
	makeStyles,
	useTheme,
	useMediaQuery,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import bus from '../assets/images/bus.png';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { setSelectedBus, setSelectedDesBus } from '../reducers/UiReducer';
import DesktopDateBus from '../components/reusableComponents/DesktopDateBus';
import { fetchBusCityDesDispatch } from '../reducers/BusReducer';
const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiFormControl-root': {
			'& div': {
				'& div': {
					display: 'none',
				},
			},
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& input': {
			fontSize: '.8em',
			fontWeight: 600,
			padding: '0px 4px 4px 0px',
			[theme.breakpoints.down('xs')]: {
				fontSize: 12,
			},
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-184whfw-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-xzqck1-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
	},
}));
const SearchEngineBus = () => {
	const classes = useStyles();
	const { buscity, busDes } = useSelector((state) => state.bus);
	const history = useHistory();
	const dispatch = useDispatch();
	const [filterData, setFilterData] = useState('');
	const [filterData2, setFilterData2] = useState('');
	const [trip, setTrip] = useState('oneway');
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { origin,selectedBus, selectedDesBus } = useSelector(
		(state) => state.ui
	);
	const handleSubmit = (data) => {
		let formData = {
			departure_city: selectedBus.name.split(' ')[0],
			arrival_city: selectedDesBus.name.split(' ')[0],
			sourceid: selectedBus.id,
			destinationid: selectedDesBus.id,
			dateofjourney: origin,
		};
		history.push({ pathname: '/buslist', state: { formdata: formData } });
	};
	const handleSearchOrigin = (event) => {
		let value = event ? event.target.value.toLowerCase() : 'del';
		let results = [];
		// console.log(value);
		results =
			buscity &&
			buscity?.result &&
			buscity?.result?.cities?.filter((item) => {
				return (
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.state.toLowerCase().includes(value.toLowerCase())
				);
			});
		console.log('searchresult', results);
		if (event) {
		} else {
			dispatch(setSelectedBus(results ? results[0] : null));
			if (results) {
				let formData = {
					source: results[0].id,
				};
				dispatch(fetchBusCityDesDispatch(formData));
			}
		}
		if (results?.length <= 80) {
			setFilterData(results);
		}
	};
	const handleSearchOrigin2 = (event) => {
		let value = event ? event.target.value.toLowerCase() : 'del';
		let results = [];
		results =
			busDes &&
			busDes?.result &&
			busDes?.result?.cities?.filter((item) => {
				return (
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.state.toLowerCase().includes(value.toLowerCase())
				);
			});
		console.log('searchresult', results);

		if (results?.length < 80) {
			setFilterData2(results);
		}
	};
	useEffect(() => {
		handleSearchOrigin();
	}, []);
	return (
		<>
			<Formik
				initialValues={{
					Origin: '',
					Destination: '',
				}}
				validate={(values) => {
					const errors = {};
				}}>
				{({ submitForm, isSubmitting, setFieldValue, values }) => (
					<>
						<Form style={{}}>
							<Grid container spacing={2} alignItems='center'>
								<Grid item xs={12} md={12} lg={3}>
									<div className={classes.root} style={{ width: '100%' }}>
										<span
											style={{
												color: 'black',
												fontSize: matches ? 12 : '.8em',
											}}>
											From
										</span>
										<Autocomplete
											id='country-select-demo'
											sx={{
												border: '1px solid lightgrey',
												borderRadius: '4px',
												padding: '7px',
												transition: 'none',
											}}
											value={selectedBus}
											options={
												filterData && filterData.length > 0 ? filterData : []
											}
											autoHighlight
											getOptionLabel={(option) =>
												`${option.name} (${option.state})`
											}
											renderOption={(props, option) => (
												<Box
													mr={'5px'}
													component='li'
													sx={{ '& > img': { mr: 2 } }}
													{...props}>
													<img loading='lazy' width='20' src={bus} />
													<p style={{ marginLeft: 5, fontSize: 12 }}>
														{option.name} ({option.state})
													</p>
												</Box>
											)}
											onChange={(event, value) => {
												dispatch(setSelectedBus(value));
												console.log('Des', value);
												let formData = {
													source: value.id,
												};
												dispatch(fetchBusCityDesDispatch(formData));
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													onChange={handleSearchOrigin}
													name='origin'
												/>
											)}
										/>
									</div>
								</Grid>
								<Grid item xs={12} md={12} lg={3}>
									<div className={classes.root}>
										<span
											style={{
												color: 'black',
												fontSize: matches ? 12 : '.8em',
											}}>
											To
										</span>
										<Autocomplete
											id='country-select-demo'
											sx={{
												border: '1px solid lightgrey',
												borderRadius: '4px',
												padding: '7px',
												transition: 'none',
											}}
											value={
												selectedDesBus
													? selectedDesBus
													: busDes?.result?.cities[0]
											}
											options={
												filterData2 && filterData2.length > 0 ? filterData2 : []
											}
											autoHighlight
											getOptionLabel={(option) =>
												`${option.name} (${option.state})`
											}
											renderOption={(props, option) => (
												<Box
													mr={'5px'}
													component='li'
													sx={{ '& > img': { mr: 2 } }}
													{...props}>
													<img loading='lazy' width='20' src={bus} />
													<p style={{ marginLeft: 5, fontSize: 12 }}>
														{option.name} ({option.state})
													</p>
												</Box>
											)}
											onChange={(event, value) => {
												dispatch(setSelectedDesBus(value));
											}}
											renderInput={(params) => (
												<TextField {...params} onChange={handleSearchOrigin2} />
											)}
										/>
									</div>
								</Grid>
								<Grid
									item
									xs={12}
									md={12}
									lg={trip == 'oneway' ? 3 : trip == 'roundtrip' ? 6 : 3}>
									<span
										style={{ color: 'black', fontSize: matches ? 12 : '.8em' }}>
										Depart Date
									</span>
									<DesktopDateBus />
								</Grid>

								<Grid item xs={12} md={6} lg={3}>
									<Box
										onClick={handleSubmit}
										style={{
											width: '100%',
											fontSize: '20px',
											borderRadius: 4,
											backgroundColor: 'rgb(0, 55, 114)',
											padding: 6,
											textAlign: 'center',
											color: 'white',
											marginTop: 20,
											cursor: 'pointer',
										}}>
										Search Bus
									</Box>
								</Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default SearchEngineBus;
