import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import {
	makeStyles,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useDispatch } from 'react-redux';
import {
	setOrigin,
	setDepart,
	setOriginO,
	setDepartO,
} from '../../reducers/UiReducer';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		'& label': {
			display: 'none',
		},
		width: '100%',
		border:'1px solid lightgrey',
		borderRadius: 4,
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			fontSize: 14,
			padding: '10px',
			fontWeight: 600,
		},
		'& .MuiInput-root': {
			marginTop: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
	},
}));
export default function DateRangeFlight() {
	const dt = new Date();
	const [value, setValue] = React.useState([
		new Date(),
		dt.setDate(dt.getDate() + 3),
	]);
	const classes = useStyles();
	const dispatch = useDispatch();
	const convert = (str) => {
		var date = new Date(str),
			mnth = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join('-');
	};
	dispatch(setOrigin(convert(value[0])));
	dispatch(setDepart(convert(value[1])));
	dispatch(setOriginO(value[0]));
	dispatch(setDepartO(value[1]));
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DateRangePicker
				// calendars={2}
				value={value}
				disablePast
				inputFormat='dd/MM/yyyy'
				// renderDay={renderDay}
				onChange={(newValue) => {
					setValue(newValue);
				}}
				renderInput={(startProps, endProps) => (
					<React.Fragment>
						<TextField
							{...startProps}
							className={classes.selectField}
							endAdornment={
								<InputAdornment position='end'>
									<DateRangeIcon />
								</InputAdornment>
							}
						/>
						<span style={{ marginLeft: 5 }}>-</span>
						<TextField
							{...endProps}
							className={classes.selectField}
							style={{ marginLeft: 10 }}
							endAdornment={
								<InputAdornment position='end'>
									<DateRangeIcon />
								</InputAdornment>
							}
						/>
					</React.Fragment>
				)}
			/>
		</LocalizationProvider>
	);
}