const tbobooking = (
	location,
	response,
	invoice,
	dispatch,
	agentInfo,
	fareQuote,
	payment,
	FlightBookEtravDispatch,
	history,
	FlightTicketLCC1WDispatch,
	FlightBookDispatch,
	agentlogin,
	BookingFormData,
	baggageInfo,
	mealInfo,
	flightDebitAmount,
	BookingFormDataNew
) => {
	let formDataLCC = {
		TraceId: location.state.IDTBO,
		ResultIndex: location.state.OneflightData.ResultIndex,
		orderId: location.state.orderID,
		paymentId: invoice,
		Passengers:
			BookingFormDataNew?.length > 0
				? BookingFormDataNew.map((item) => {
						return {
							Title: item.title,
							FirstName: item.firstname,
							LastName: item.lastname,
							PaxType: item.PaxType,
							DateOfBirth: item.dob ? item.dob : '1998-03-09',
							Gender: 1,
							PassportNo: item.passportnumber ? item.passportnumber : '',
							PassportExpiry: item.expiry ? item.expiry : '',
							AddressLine1: 'Dhanbad',
							AddressLine2: '',
							Fare: {
								Currency: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Currency
									: '',
								BaseFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								Tax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Tax
									: '',
								YQTax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.YQTax
									: '',
								AdditionalTxnFeePub: fareQuote
									? fareQuote?.result?.Response.Results.Fare.AdditionalTxnFeePub
									: '',
								AdditionalTxnFeeOfrd: fareQuote
									? fareQuote?.result?.Response.Results.Fare
											.AdditionalTxnFeeOfrd
									: '',
								OtherCharges: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OtherCharges
									: '',
								Discount: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Discount
									: '',
								PublishedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.PublishedFare
									: '',
								OfferedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								TdsOnCommission: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnCommission
									: '',
								TdsOnPLB: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnPLB
									: '',
								TdsOnIncentive: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnIncentive
									: '',
								ServiceFee: fareQuote
									? fareQuote?.result?.Response.Results.Fare.ServiceFee
									: '',
							},
							City: 'Dhanbad',
							CountryCode: 'IN',
							CellCountryCode: '+91',
							ContactNo: location.state.formData
								? location.state.formData.phone
								: '',
							Nationality: 'IN',
							Email: location.state.formData
								? location.state.formData.email
								: '',
							IsLeadPax: true,
							FFAirlineCode: null,
							FFNumber: '',
							GSTCompanyAddress: '',
							GSTCompanyContactNumber: '',
							GSTCompanyName: '',
							GSTNumber: '',
							GSTCompanyEmail: '',
							Baggage: item.baggage ? [item.baggage] : [],
							MealDynamic: item.meal ? [item.meal] : [],
							SeatDynamic: item.seat ? [item.seat] : [],
						};
				  })
				: BookingFormData.map((item) => {
						return {
							Title: item.title,
							FirstName: item.firstname,
							LastName: item.lastname,
							PaxType: item.PaxType,
							DateOfBirth: item.dob ? item.dob : '1998-03-09',
							Gender: 1,
							PassportNo: item.passportnumber ? item.passportnumber : '',
							PassportExpiry: item.expiry ? item.expiry : '',
							AddressLine1: 'Dhanbad',
							AddressLine2: '',
							Fare: {
								Currency: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Currency
									: '',
								BaseFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								Tax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Tax
									: '',
								YQTax: fareQuote
									? fareQuote?.result?.Response.Results.Fare.YQTax
									: '',
								AdditionalTxnFeePub: fareQuote
									? fareQuote?.result?.Response.Results.Fare.AdditionalTxnFeePub
									: '',
								AdditionalTxnFeeOfrd: fareQuote
									? fareQuote?.result?.Response.Results.Fare
											.AdditionalTxnFeeOfrd
									: '',
								OtherCharges: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OtherCharges
									: '',
								Discount: fareQuote
									? fareQuote?.result?.Response.Results.Fare.Discount
									: '',
								PublishedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.PublishedFare
									: '',
								OfferedFare: fareQuote
									? fareQuote?.result?.Response.Results.Fare.OfferedFare
									: '',
								TdsOnCommission: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnCommission
									: '',
								TdsOnPLB: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnPLB
									: '',
								TdsOnIncentive: fareQuote
									? fareQuote?.result?.Response.Results.Fare.TdsOnIncentive
									: '',
								ServiceFee: fareQuote
									? fareQuote?.result?.Response.Results.Fare.ServiceFee
									: '',
							},
							City: 'Dhanbad',
							CountryCode: 'IN',
							CellCountryCode: '+91',
							ContactNo: location.state.formData
								? location.state.formData.phone
								: '',
							Nationality: 'IN',
							Email: location.state.formData
								? location.state.formData.email
								: '',
							IsLeadPax: true,
							FFAirlineCode: null,
							FFNumber: '',
							GSTCompanyAddress: '',
							GSTCompanyContactNumber: '',
							GSTCompanyName: '',
							GSTNumber: '',
							GSTCompanyEmail: '',
							Baggage: item.baggage ? [item.baggage] : [],
							MealDynamic: item.meal ? [item.meal] : [],
						};
				  }),

		// SeatDynamic: [],
	};
	console.log('FormData', formDataLCC);
	if (payment === 'offline') {
		let debit = {
			orderId: location.state.orderID,
			paymentId: payment === 'offline' ? invoice : response.razorpay_payment_id,
		};
		dispatch(flightDebitAmount(debit));
	}
	dispatch(FlightTicketLCC1WDispatch(formDataLCC, history, agentlogin));
};

export default tbobooking;
