import React from 'react';
import { Box, Hidden } from '@material-ui/core';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
const SkeletonOneway = () => {
	return (
		<>
			<Box
				display='flex'
				style={{
					boxShadow: '3px 3px 16px -4px rgb(0 0 0 / 30%)',
					padding: 8,
					background: 'white',
				}}
				mt={'20px'}>
				<Hidden only={['xs']}>
					<Box display='flex' alignItems='center'>
						<img
							src={'https://marinersforex.com/images/loaders.gif'}
							style={{ height: '135px' }}
						/>
					</Box>
				</Hidden>
				<Box style={{ width: '100%' }}>
					<Skeleton variant='text' width='100%' style={{ borderRadius: 10 }} />
					<Skeleton variant='text' width='80%' style={{ borderRadius: 10 }} />
					<Skeleton variant='text' width='60%' style={{ borderRadius: 10 }} />
					<Skeleton variant='text' width='40%' style={{ borderRadius: 10 }} />
					<Skeleton variant='text' width='20%' style={{ borderRadius: 10 }} />
					<Skeleton variant='text' width='10%' style={{ borderRadius: 10 }} />
				</Box>
			</Box>
		</>
	);
};

export default SkeletonOneway;
