import React from 'react';
import { Grid, Box, Hidden, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Container } from '@material-ui/core';
import '../../main.css';
import { useHistory } from 'react-router-dom';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import PrintTicketForm from '../../form/PrintTicketForm';
import SuccessModal from '../../components/reusableComponents/SuccessModal';
import ErrorModal from '../../components/reusableComponents/ErrorModal';
const PrintTicket = () => {
	const { flightTicket } = useSelector((state) => state.user);
	const history = useHistory();
	return (
		<>
			<HeaderSection id={1} />
			<section
				style={{
					background: '#0c2f55',
					color: 'white',
					marginTop: 50,
					padding: 5,
				}}
				id='ticket2'>
				<h1 style={{ textAlign: 'center', fontFamily: 'Poppins' }}>
					Manage Booking - Print Ticket
				</h1>
			</section>
			<Grid container style={{ paddingLeft: 20 }}>
				<Hidden only={['xs', 'sm']}>
					<Grid item xs={4}></Grid>
				</Hidden>

				<Grid item xs={12} id='ticket' lg={4}>
					<Box
						style={{
							backgroundColor: '#FF F',
							boxShadow: '0px 0px 50px -35px rgb(0 0 0 / 40%)',
							padding: 20,
						}}>
						<PrintTicketForm />
					</Box>
				</Grid>
				<Hidden only={['xs', 'sm']}>
					<Grid item xs={4}></Grid>
				</Hidden>
			</Grid>
			<Container>
				<hr />
				<Divider />
				{flightTicket?.status === 200 ? (
					<Box style={{ marginTop: 20 }}>
						<table>
							<thead>
								<tr>
									<th style={{ color: 'grey' }}>Airline</th>
									<th style={{ color: 'grey' }}>Sector</th>
									<th style={{ color: 'grey' }}>PNR</th>
									<th style={{ color: 'grey' }}>DOB</th>
									<th style={{ color: 'grey' }}>Action</th>
								</tr>
							</thead>
							<tbody style={{ marginTop: 10 }}>
								<th style={{ padding: 10 }}>
									<img
										src={`https://nitish.musafirbazar.com/static/media/${flightTicket?.result?.flightDetails?.carrier}.gif`}
									/>
								</th>
								<th>{`${flightTicket?.result?.flightDetails?.origin}-${flightTicket?.result?.flightDetails?.destination}`}</th>
								<th>{flightTicket?.result?.flightDetails?.bookingRefrence}</th>
								<th>{flightTicket?.result?.flightDetails?.dob}</th>
								<th
									style={{ cursor: 'pointer' }}
									onClick={() =>
										history.push({
											pathname: '/showticket',
											state: flightTicket,
										})
									}>
									View
								</th>
							</tbody>
						</table>
					</Box>
				) : (
					''
				)}
				<hr />
			</Container>
			<SuccessModal />
			<ErrorModal />
		</>
	);
};

export default PrintTicket;
