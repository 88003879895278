import React, { useEffect, useState } from 'react';
import './main-layout.scss';
import Sidebar from '../components/sidebar/Sidebar';
import TopNav from '../components/topnav/TopNav';
import { useSelector } from 'react-redux';
import './styles.css';
import { agentbookinghistoryDispatch } from '../reducers/UserReducer';
import { useDispatch } from 'react-redux';
const MainLayout = ({ children }) => {
	const dispatch = useDispatch();
	const { agentlogin } = useSelector((state) => state.user);
	const [tab, setTab] = useState(false);
	const handleTab = (a) => {
		setTab(a);
	};
	useEffect(() => {
		dispatch(
			agentbookinghistoryDispatch(agentlogin && agentlogin.result.result.id)
		);
	}, []);
	return (
		<>
			<div className={tab ? 'd-flex toggled' : 'd-flex'} id='wrapper'>
				<Sidebar />

				<div id='page-content-wrapper'>
					<TopNav handleTab={handleTab} tab={tab} />
					{children}
				</div>
			</div>
		</>
	);
};

export default MainLayout;
