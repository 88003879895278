import React from 'react';
import {
	Grid,
	TextField,
	Box,
	makeStyles,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import DateRangeFlight from '../components/reusableComponents/DateRangeFlight';
import BodyText from '../components/reusableComponents/BodyText';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { fetchHotelCityDispatch } from '../reducers/HotelReducer';
import {
	removeSelectedRoom,
	setAdultH,
	setHotelToggle,
	setSelectedHotel,
	setSelectedRoom,
	setChildH,
	setChildAge1,
	setChildAge2,
	setChildAge3,
	setChildAge4,
} from '../reducers/UiReducer';
const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiFormControl-root': {
			'& div': {
				'& div': {
					display: 'none',
				},
			},
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& input': {
			fontSize: '.8em',
			fontWeight: 600,
			padding: '0px 4px 4px 0px',
			[theme.breakpoints.down('xs')]: {
				fontSize: 12,
			},
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-184whfw-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-xzqck1-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
	},
}));
const SearchEngineHotel = () => {
	const classes = useStyles();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { hotelcity } = useSelector((state) => state.hotel);
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		origin,
		selectedHotelDes,
		depart,
		hoteltoggle,
		room,
		adultH,
		childH,
		childAge1,
		childAge2,
		childAge3,
		childAge4,
	} = useSelector((state) => state.ui);
	const handleHotel = (data) => {
		let formData = {
			checkInDate: origin,
			checkOutDate: depart,
			noOfNights: 1,
			countryName: selectedHotelDes.country_name,
			countryCode: selectedHotelDes.country_code,
			cityName: selectedHotelDes.city_name,
			cityId: selectedHotelDes.city_id,
			noOfRooms: room.length,
			guestNationality: 'IN',
			adultPax: adultH,
			childPax: childH,
			childAge: [...childAge1, ...childAge2, ...childAge3, ...childAge4],
			preferredCurrencyCode: 'INR',
			hotelCodeList: '0',
			starMaxRating: 5,
			startMinRating: 0,
		};
		history.push({
			pathname: '/hotellist',
			state: { hotelformData: formData },
		});
	};
	const handleSearchOrigin = (event) => {
		let value = event.target.value.toLowerCase();
		let cityData = {
			keywords: value,
			limits: 10,
		};
		dispatch(fetchHotelCityDispatch(cityData));
	};
	return (
		<>
			<Formik
				initialValues={{
					Origin: '',
					Destination: '',
				}}
				validate={(values) => {
					const errors = {};
				}}
				onSubmit={handleHotel}>
				{({ submitForm, isSubmitting, setFieldValue, values }) => (
					<>
						<Form>
							<Grid container spacing={2} alignItems='center'>
								<Grid item xs={12} md={12} lg={3}>
									<div
										className={classes.root}
										style={{ width: '100%', marginTop: matches ? 25 : '' }}>
										<span
											style={{
												color: 'black',
												fontSize: matches ? 12 : '.8em',
											}}>
											Where are you going ?
										</span>
										<Autocomplete
											id='country-select-demo'
											sx={{
												border: '1px solid lightgrey',
												borderRadius: '4px',
												padding: '7px',
												transition: 'none',
											}}
											options={
												hotelcity && hotelcity.result.length > 0
													? hotelcity.result
													: []
											}
											autoHighlight
											getOptionLabel={(option) =>
												`${option.city_name} (${option.country_name})`
											}
											renderOption={(props, option) => (
												<Box
													mr={'5px'}
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
													}}
													component='li'
													sx={{ '& > img': { mr: 2 } }}
													{...props}>
													<Box>
														<Box display='flex' alignItems='center'>
															<img
																loading='lazy'
																width='20'
																src={
																	'https://cdn-icons-png.flaticon.com/512/235/235889.png'
																}
															/>
															<p style={{ marginLeft: 5, fontSize: 14 }}>
																{option.city_name} ({option.country_name})
															</p>
														</Box>
													</Box>
													<img
														style={{ marginLeft: 10 }}
														loading='lazy'
														width='30'
														src={`https://countryflagsapi.com/svg/${option.country_code}`}
														alt=''
													/>
												</Box>
											)}
											onChange={(event, value) => {
												setFieldValue('Origin', value.code);
												dispatch(setSelectedHotel(value));
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													onChange={handleSearchOrigin}
													name='origin'
												/>
											)}
										/>
									</div>
								</Grid>

								<Grid item xs={12} md={12} lg={3} style={{ marginTop: 20 }}>
									<DateRangeFlight />
								</Grid>
								<Grid item xs={12} lg={3}>
									<Box
										style={{
											border: '1px solid #767b8e',
											borderRadius: 5,
											marginTop: 20,
										}}>
										<ul class='menu2'>
											<li>
												<a
													onClick={() =>
														hoteltoggle
															? dispatch(setHotelToggle(false))
															: dispatch(setHotelToggle(true))
													}
													style={{
														padding: 8,
														fontSize: 12,
														borderRadius: 4,
														overflow: 'hidden',
														whiteSpace: 'nowrap',
														textOverflow: 'ellipsis',
														fontWeight: 600,
														cursor: 'pointer',
													}}>
													<span style={{ fontSize: 14 }}>
														Travellers {room.length} Room
													</span>
												</a>
												{hoteltoggle ? (
													<>
														<ul
															style={{
																padding: 5,
																// height: null,
																width: matches ? 264 : 350,
																border: '1px solid grey',
															}}>
															{room.map((i, index) => {
																return (
																	<li>
																		<Box style={{ padding: 5 }}>
																			<BodyText
																				four
																				textStyle={{
																					color: 'black',
																					fontSize: 14,
																					fontWeight: 500,
																					fontFamily: 'Poppins',
																				}}>
																				Room {index + 1}
																			</BodyText>
																			<Box
																				display='flex'
																				justifyContent='space-between'>
																				<Box>
																					<span
																						style={{
																							color: 'black',
																							fontSize: 14,
																							fontWeight: 500,
																							fontFamily: 'Poppins',
																						}}>
																						Adult
																					</span>
																					<span
																						style={{
																							fontSize: 10,
																							fontWeight: 'grey',
																							marginLeft: 5,
																						}}>
																						Above 12 years
																					</span>
																					<Box
																						display='flex'
																						alignItems='center'>
																						<Box
																							style={{
																								cursor: 'pointer',
																								background: 'rgb(0, 55, 114)',
																								padding: '0px 10px',
																								// borderRadius: '5px',
																							}}
																							onClick={() => {
																								dispatch(
																									setAdultH(
																										`${
																											adultH[index] - 1
																										}A${index}`
																									)
																								);
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'white',
																								}}>
																								-
																							</BodyText>
																						</Box>
																						<Box
																							style={{
																								cursor: 'pointer',
																								background: 'lightgrey',
																								padding: '0px 10px',
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'black',
																								}}>
																								{adultH[index]
																									? adultH[index]
																									: 0}
																							</BodyText>
																						</Box>
																						<Box
																							style={{
																								cursor: 'pointer',
																								background: 'rgb(0, 55, 114)',
																								padding: '0px 10px',
																							}}
																							onClick={() => {
																								dispatch(
																									setAdultH(
																										`${
																											adultH[index]
																												? adultH[index] + 1
																												: 0 + 1
																										}A${index}`
																									)
																								);
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'white',
																								}}>
																								+
																							</BodyText>
																						</Box>
																					</Box>
																				</Box>
																				<Box>
																					<span
																						style={{
																							color: 'black',
																							fontSize: 14,
																							fontWeight: 500,
																							fontFamily: 'Poppins',
																						}}>
																						Child
																					</span>
																					<span
																						style={{
																							fontSize: 10,
																							fontWeight: 'grey',
																							marginLeft: 5,
																						}}>
																						Below 12 years
																					</span>
																					<Box
																						display='flex'
																						alignItems='center'>
																						<Box
																							style={{
																								cursor: 'pointer',
																								background: 'rgb(0, 55, 114)',
																								padding: '0px 10px',
																							}}
																							onClick={() => {
																								dispatch(
																									setChildH(
																										`${
																											childH[index] - 1
																										}C${index}`
																									)
																								);
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'white',
																								}}>
																								-
																							</BodyText>
																						</Box>
																						<Box
																							style={{
																								cursor: 'pointer',
																								background: 'lightgrey',
																								padding: '0px 10px',
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'black',
																								}}>
																								{childH[index]
																									? childH[index]
																									: 0}
																							</BodyText>
																						</Box>
																						<Box
																							style={{
																								cursor: 'pointer',
																								background: 'rgb(0, 55, 114)',
																								padding: '0px 10px',
																							}}
																							onClick={() => {
																								dispatch(
																									setChildH(
																										`${
																											childH[index]
																												? childH[index] + 1
																												: 0 + 1
																										}C${index}`
																									)
																								);
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'white',
																								}}>
																								+
																							</BodyText>
																						</Box>
																					</Box>
																				</Box>
																			</Box>
																		</Box>
																		<Box
																			display='flex'
																			style={{
																				paddingRight: '27px',
																				marginTop: 10,
																			}}>
																			{Array.from(Array(childH[index])).map(
																				(i, ind) => {
																					return (
																						<Box ml={ind === 0 ? '' : '5px'}>
																							<label for='cars'>Age:</label>
																							<select
																								onChange={(e) =>
																									dispatch(
																										index === 0
																											? setChildAge1(
																													`${e.target.value}C${ind}`
																											  )
																											: index === 1
																											? setChildAge2(
																													`${e.target.value}C${ind}`
																											  )
																											: index === 2
																											? setChildAge3(
																													`${e.target.value}C${ind}`
																											  )
																											: index === 3
																											? setChildAge4(
																													`${e.target.value}C${ind}`
																											  )
																											: ''
																									)
																								}
																								name='cars'
																								id='cars'
																								style={{
																									border: '1px solid grey',
																									paddingLeft: 10,
																									paddingRight: 10,
																									marginLeft: 5,
																								}}>
																								<option value='1'>1</option>
																								<option value='2'>2</option>
																								<option value='3'>3</option>
																								<option value='4'>4</option>
																								<option value='5'>5</option>
																								<option value='6'>6</option>
																								<option value='7'>7</option>
																								<option value='8'>8</option>
																								<option value='9'>9</option>
																								<option value='10'>10</option>
																								<option value='11'>11</option>
																								<option value='12'>12</option>
																							</select>
																						</Box>
																					);
																				}
																			)}
																		</Box>
																	</li>
																);
															})}
															<li>
																<Box
																	display='flex'
																	justifyContent='space-between'
																	style={{ marginTop: 10 }}>
																	<Box
																		onClick={() => dispatch(setSelectedRoom(1))}
																		style={{
																			border: '1px solid grey',
																			paddingLeft: '5px',
																			paddingRight: '5px',
																			cursor: 'pointer',
																		}}>
																		<span style={{ fontSize: 12 }}>
																			Add Room
																		</span>
																	</Box>
																	<Box
																		onClick={() =>
																			dispatch(removeSelectedRoom(1))
																		}
																		style={{
																			border: '1px solid #ea2330',
																			paddingLeft: '5px',
																			paddingRight: '5px',
																			cursor: 'pointer',
																			background: '#ea2330',
																			color: 'white',
																		}}>
																		<span style={{ fontSize: 12 }}>
																			Remove Room
																		</span>
																	</Box>
																	<Box
																		onClick={() =>
																			dispatch(setHotelToggle(false))
																		}
																		style={{
																			border: '1px solid grey',
																			paddingLeft: '5px',
																			paddingRight: '5px',
																			cursor: 'pointer',
																			background: 'rgb(0, 55, 114)',
																			color: 'white',
																		}}>
																		<span style={{ fontSize: 12 }}>Done</span>
																	</Box>
																</Box>
															</li>
														</ul>
													</>
												) : (
													''
												)}
											</li>
										</ul>
									</Box>
								</Grid>

								<Grid item xs={12} md={6} lg={3}>
									<input
										onClick={submitForm}
										value='Search Hotel'
										style={{
											width: '100%',
											fontSize: '20px',
											borderRadius: 4,
											backgroundColor: 'rgb(0, 55, 114)',
											padding: 8,
											textAlign: 'center',
											color: 'white',
											marginTop: 20,
											cursor: 'pointer',
										}}
									/>
								</Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default SearchEngineHotel;
