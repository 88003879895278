import React from 'react';
import { makeStyles, Button, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	agentchangePasswordDispatch,
	travellerchangePasswordDispatch,
} from '../../reducers/UserReducer';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid rgba(0, 50, 91, 0.5)',
			},
		},
		'& .MuiFormHelperText-root': {
			backgroundColor: '#F6F8FB',
			margin: 0,
			paddingTop: 10,
		},
		border: '1px solid rgba(0, 50, 91, 0.5)',
		'& .MuiSelect-icon': {
			color: '#E61855',
		},
		'& div': {
			fontSize: 18,
		},
		'& input': {
			padding: '12.5px 14px',
		},
		'& .MuiSelect-select': {
			padding: '12.5px 14px',
		},
		padding: 15,
		backgroundColor: 'white',
		marginBottom: 5,
		width: '100%',
		marginTop: 10,
		borderRadius: 5,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
			marginTop: 8,
		},
	},
	createButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 10,
		width: '100%',
		padding: 10,
		fontSize: 16,
		marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
}));
const ChangePasswordForm = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { travellerlogin, agentlogin } = useSelector((state) => state.user);

	const handleFormSubmit = (data, actions) => {
		if (
			agentlogin &&
			agentlogin.result &&
			agentlogin.result.result &&
			agentlogin.result.result.role === 'Agent'
		) {
			let formData2 = {
				userEmail:
					agentlogin &&
					agentlogin.result &&
					agentlogin.result.result &&
					agentlogin.result.result.email,
				confirmpassword: data.confirmpassword,
				newpassword: data.newpassword,
				oldpassword: data.oldpassword,
			};
			dispatch(agentchangePasswordDispatch(formData2, history));
		} else {
			let formData = {
				userEmail:
					travellerlogin &&
					travellerlogin.result &&
					travellerlogin.result.result &&
					travellerlogin.result.result.email,
				confirmpassword: data.confirmpassword,
				newpassword: data.newpassword,
				oldpassword: data.oldpassword,
			};

			dispatch(travellerchangePasswordDispatch(formData, history));
		}
	};

	return (
		<>
			<Formik
				initialValues={{
					oldpassword: '',
					newpassword: '',
					confirmpassword: '',
				}}
				validate={(values) => {
					const errors = {};
					return errors;
				}}
				onSubmit={handleFormSubmit}>
				{({ submitForm, isSubmitting, values, errors }) => (
					<>
						<Form>
							<Grid
								container
								spacing={2}
								alignItems='center'
								style={{ padding: 50 }}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<h6>Current Password</h6>
									<Field
										name='oldpassword'
										type='password'
										variant='outlined'
										className={classes.selectField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<h6>New Password</h6>
									<Field
										name='newpassword'
										type='password'
										variant='outlined'
										className={classes.selectField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<h6>New Password Again</h6>
									<Field
										name='confirmpassword'
										type='password'
										variant='outlined'
										className={classes.selectField}
									/>
								</Grid>
							</Grid>
							<Grid container style={{ marginTop: 20, padding: 50 }}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<Button
										className={classes.createButton}
										onClick={submitForm}
										style={{ marginTop: 5, fontSize: 14 }}
										// disabled={isSubmitting}
									>
										SUBMIT
									</Button>
								</Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
			<SuccessModal />
			<ErrorModal />
		</>
	);
};

export default ChangePasswordForm;
