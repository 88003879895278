import React from 'react';
import { makeStyles, Button, Grid } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	agentedituserDispatch,
	travelleredituserDispatch,
} from '../../reducers/UserReducer';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid rgba(0, 50, 91, 0.5)',
			},
		},
		'& .MuiFormHelperText-root': {
			backgroundColor: '#F6F8FB',
			margin: 0,
			paddingTop: 10,
		},
		border: '1px solid rgba(0, 50, 91, 0.5)',
		'& .MuiSelect-icon': {
			color: '#E61855',
		},
		'& div': {
			fontSize: 18,
		},
		'& input': {
			padding: '12.5px 14px',
		},
		'& .MuiSelect-select': {
			padding: '12.5px 14px',
		},
		padding: 15,
		backgroundColor: 'white',
		marginBottom: 5,
		width: '100%',
		marginTop: 10,
		borderRadius: 5,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
			marginTop: 8,
		},
	},
	createButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 5,
		width: '100%',
		padding: 10,
		fontSize: 16,
		marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
}));
const MyProfileForm = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const { travellerlogin, agentlogin } = useSelector((state) => state.user);
	const handleFormSubmit = (data, actions) => {
		if (
			agentlogin &&
			agentlogin.result &&
			agentlogin.result.result &&
			agentlogin.result.result.role === 'Agent'
		) {
			let formData2 = {
				userId:
					agentlogin &&
					agentlogin.result &&
					agentlogin.result.result &&
					agentlogin.result.result.id,
				userFirstName: data.userFirstName,
				userLastName: data.userLastName,
				userPhoneCode: '91',
				userPhone: data.userPhone,
				agencyName: data.agencyName,
			};
			dispatch(agentedituserDispatch(formData2, history));
		} else {
			let formData = {
				userId:
					travellerlogin &&
					travellerlogin.result &&
					travellerlogin.result.result &&
					travellerlogin.result.result.id,
				userFirstName: data.userFirstName,
				userLastName: data.userLastName,
				userPhoneCode: '91',
				userPhone: data.userPhone,
			};
			dispatch(travelleredituserDispatch(formData, history));
		}
	};

	return (
		<>
			<Formik
				initialValues={{
					userFirstName: agentlogin
						? agentlogin &&
						  agentlogin.result &&
						  agentlogin.result.result &&
						  agentlogin.result.result.name.trimStart().split(' ')[0]
						: travellerlogin
						? travellerlogin &&
						  travellerlogin.result &&
						  travellerlogin.result.result &&
						  travellerlogin.result.result.name.trimStart().split(' ')[0]
						: '',
					userLastName: agentlogin
						? agentlogin &&
						  agentlogin.result &&
						  agentlogin.result.result &&
						  agentlogin.result.result.name.trimStart().split(' ')[1]
						: travellerlogin
						? travellerlogin &&
						  travellerlogin.result &&
						  travellerlogin.result.result &&
						  travellerlogin.result.result.name.trimStart().split(' ')[1]
						: '',
					userPhone: agentlogin
						? agentlogin &&
						  agentlogin.result &&
						  agentlogin.result.result &&
						  agentlogin.result.result.phone
						: travellerlogin
						? travellerlogin &&
						  travellerlogin.result &&
						  travellerlogin.result.result &&
						  travellerlogin.result.result.phone
						: '',
					agencyName: agentlogin
						? agentlogin &&
						  agentlogin.result &&
						  agentlogin.result.result &&
						  agentlogin.result.result.agencyName
						: '',
				}}
				validate={(values) => {
					const errors = {};
					return errors;
				}}
				onSubmit={handleFormSubmit}>
				{({ submitForm, isSubmitting, values, errors }) => (
					<>
						<Form>
							<Grid
								container
								spacing={2}
								alignItems='center'
								style={{ marginTop: 20, padding: 50 }}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<h6>First Name</h6>
									<Field
										name='userFirstName'
										type='text'
										variant='outlined'
										className={classes.selectField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<h6>Last Name</h6>
									<Field
										name='userLastName'
										type='text'
										variant='outlined'
										className={classes.selectField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<h6>Phone Number</h6>
									<Field
										name='userPhone'
										type='text'
										variant='outlined'
										className={classes.selectField}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<h6>Agency Name</h6>
									<Field
										name='agencyName'
										type='text'
										variant='outlined'
										className={classes.selectField}
									/>
								</Grid>
							</Grid>
							<Grid container style={{ marginTop: 20, padding: 50 }}>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<Button
										className={classes.createButton}
										onClick={submitForm}
										style={{ marginTop: 5 }}
										// disabled={isSubmitting}
									>
										UPDATE
									</Button>
								</Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
			<SuccessModal />
			<ErrorModal />
		</>
	);
};

export default MyProfileForm;
