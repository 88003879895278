import { axiosInstance } from '../Axios';
import { busbookinghistoryDispatch } from './BusReducer';
import {
	loader,
	storeData,
	toggleErrorModal,
	setErrorMessage,
	setSuccessMessage,
	toggleSuccessModal,
	toggleRegisterModal,
	toggleRegisterTravellerModal,
	toggleChangeLogoModal,
	toggleHolidayModal,
} from './UiReducer';
const { createSlice } = require('@reduxjs/toolkit');

const UserReducer = createSlice({
	name: 'user',
	initialState: {
		success: false,
		error: null,
		loadinguser: false,
		agentsignup: null,
		travellersignup: null,
		travellerlogin: sessionStorage.getItem('userInfo')
			? JSON.parse(sessionStorage.getItem('userInfo'))
			: null,
		wallet: sessionStorage.getItem('walletbalance')
			? JSON.parse(sessionStorage.getItem('walletbalance'))
			: null,
		bookinghistory: sessionStorage.getItem('bookinghistory')
			? JSON.parse(sessionStorage.getItem('bookinghistory'))
			: null,
		bookinghistoryagent: sessionStorage.getItem('bookinghistoryagent')
			? JSON.parse(sessionStorage.getItem('bookinghistoryagent'))
			: null,
		changepassword: null,
		changepasswordagent: null,
		edituser: null,
		edituseragent: null,
		agentlogo: null,
		agentlogin: sessionStorage.getItem('userInfoAgent')
			? JSON.parse(sessionStorage.getItem('userInfoAgent'))
			: null,
		walletagent: sessionStorage.getItem('walletbalanceagent')
			? JSON.parse(sessionStorage.getItem('walletbalanceagent'))
			: null,
		agentmarkup: sessionStorage.getItem('agentMarkup')
			? JSON.parse(sessionStorage.getItem('agentMarkup'))
			: null,
		agentmarkupedit: null,
		agentlogoget: sessionStorage.getItem('agentlogo')
			? JSON.parse(sessionStorage.getItem('agentlogo'))
			: null,
		verify: null,
		transagent: null,
		flightTicket: null,
		forgotPassword: null,
		forgotPasswordtraveller: null,
	},
	reducers: {
		agentsignupRequest(state, action) {
			state.loadinguser = true;
		},
		agentsignupSuccess(state, action) {
			state.loadinguser = false;
			state.agentsignup = action.payload;
			state.error = null;
		},
		agentsignupFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		agentloginRequest(state, action) {
			state.loadinguser = true;
		},
		agentloginSuccess(state, action) {
			state.loadinguser = false;
			state.agentlogin = action.payload;
			state.error = null;
		},
		agentloginFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		agentlogoRequest(state, action) {
			state.loadinguser = true;
		},
		agentlogoSuccess(state, action) {
			state.loadinguser = false;
			state.agentlogo = action.payload;
			state.error = null;
		},
		agentlogoFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		agentlogogetRequest(state, action) {
			state.loadinguser = true;
		},
		agentlogogetSuccess(state, action) {
			state.loadinguser = false;
			state.agentlogoget = action.payload;
			state.error = null;
		},
		agentlogogetFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		travellersignupRequest(state, action) {
			state.loadinguser = true;
		},
		travellersignupSuccess(state, action) {
			state.loadinguser = false;
			state.travellersignup = action.payload;
			state.error = null;
		},
		travellersignupFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		travellerloginRequest(state, action) {
			state.loadinguser = true;
		},
		travellerloginSuccess(state, action) {
			state.loadinguser = false;
			state.travellerlogin = action.payload;
			state.error = null;
		},
		travellerloginFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		walletRequest(state, action) {
			state.loadinguser = true;
		},
		walletSuccess(state, action) {
			state.loadinguser = false;
			state.wallet = action.payload;
			state.error = null;
		},
		walletFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		markupRequest(state, action) {
			state.loadinguser = true;
		},
		markupSuccess(state, action) {
			state.loadinguser = false;
			state.agentmarkup = action.payload;
			state.error = null;
		},
		markupFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		markupEditRequest(state, action) {
			state.loadinguser = true;
		},
		markupEditSuccess(state, action) {
			state.loadinguser = false;
			state.agentmarkupedit = action.payload;
			state.error = null;
		},
		markupEditFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		walletagentRequest(state, action) {
			state.loadinguser = true;
		},
		walletagentSuccess(state, action) {
			state.loadinguser = false;
			state.walletagent = action.payload;
			state.error = null;
		},
		walletagentFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		travellerchangepasswordRequest(state, action) {
			state.loadinguser = true;
		},
		travellerchangepasswordSuccess(state, action) {
			state.loadinguser = false;
			state.changepassword = action.payload;
			state.error = null;
		},
		travellerchangepasswordFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		agentchangepasswordRequest(state, action) {
			state.loadinguser = true;
		},
		agentchangepasswordSuccess(state, action) {
			state.loadinguser = false;
			state.changepasswordagent = action.payload;
			state.error = null;
		},
		agentchangepasswordFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		travellerbookinghistoryRequest(state, action) {
			state.loadinguser = true;
		},
		travellerbookinghistorySuccess(state, action) {
			state.loadinguser = false;
			state.bookinghistory = action.payload;
			state.error = null;
		},
		travellerbookinghistoryFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		agentbookinghistoryRequest(state, action) {
			state.loadinguser = true;
		},
		agentbookinghistorySuccess(state, action) {
			state.loadinguser = false;
			state.bookinghistoryagent = action.payload;
			state.error = null;
		},
		agentbookinghistoryFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		agenttransRequest(state, action) {
			state.loadinguser = true;
		},
		agenttransSuccess(state, action) {
			state.loadinguser = false;
			state.transagent = action.payload;
			state.error = null;
		},
		agenttransFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		agentedituserRequest(state, action) {
			state.loadinguser = true;
		},
		agentedituserSuccess(state, action) {
			state.loadinguser = false;
			state.edituseragent = action.payload;
			state.error = null;
		},
		agentedituserFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		travelleredituserRequest(state, action) {
			state.loadinguser = true;
		},
		travelleredituserSuccess(state, action) {
			state.loadinguser = false;
			state.edituser = action.payload;
			state.error = null;
		},
		travelleredituserFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		verifyRequest(state, action) {
			state.loadinguser = true;
		},
		verifySuccess(state, action) {
			state.loadinguser = false;
			state.verify = action.payload;
			state.error = null;
		},
		verifyFail(state, action) {
			state.verify = false;
			state.error = action.payload;
		},
		flightTicketRequest(state, action) {
			state.loadinguser = true;
		},
		flightTicketSuccess(state, action) {
			state.loadinguser = false;
			state.flightTicket = action.payload;
			state.error = null;
		},
		flightTicketFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		forgotPasswordRequest(state, action) {
			state.loadinguser = true;
		},
		forgotPasswordSuccess(state, action) {
			state.loadinguser = false;
			state.forgotPassword = action.payload;
			state.error = null;
		},
		forgotPasswordFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
		forgotPasswordTravellerRequest(state, action) {
			state.loadinguser = true;
		},
		forgotPasswordTravellerSuccess(state, action) {
			state.loadinguser = false;
			state.forgotPasswordtraveller = action.payload;
			state.error = null;
		},
		forgotPasswordTravellerFail(state, action) {
			state.loadinguser = false;
			state.error = action.payload;
		},
	},
});

const { actions } = UserReducer;
const config = {
	headers: {
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': 'NjA4MzFiNDBkZmI1NQ==',
	},
};

export const {
	agentsignupRequest,
	agentsignupSuccess,
	agentsignupFail,
	agentloginRequest,
	agentloginSuccess,
	agentloginFail,
	agentlogoRequest,
	agentlogoSuccess,
	agentlogoFail,
	agentlogogetRequest,
	agentlogogetSuccess,
	agentlogogetFail,
	travellersignupRequest,
	travellersignupSuccess,
	travellersignupFail,
	travellerloginRequest,
	travellerloginSuccess,
	travellerloginFail,
	walletRequest,
	walletSuccess,
	walletFail,
	agentchangepasswordRequest,
	agentchangepasswordSuccess,
	agentchangepasswordFail,
	agentedituserRequest,
	agentedituserSuccess,
	agentedituserFail,
	agentbookinghistoryRequest,
	agentbookinghistorySuccess,
	agentbookinghistoryFail,
	agenttransRequest,
	agenttransSuccess,
	agenttransFail,
	travellerchangepasswordRequest,
	travellerchangepasswordSuccess,
	travellerchangepasswordFail,
	travellerbookinghistoryRequest,
	travellerbookinghistorySuccess,
	travellerbookinghistoryFail,
	travelleredituserRequest,
	travelleredituserSuccess,
	travelleredituserFail,
	walletagentRequest,
	walletagentSuccess,
	walletagentFail,
	markupRequest,
	markupSuccess,
	markupFail,
	markupEditRequest,
	markupEditSuccess,
	markupEditFail,
	verifyRequest,
	verifySuccess,
	verifyFail,
	flightTicketRequest,
	flightTicketSuccess,
	flightTicketFail,
	forgotPasswordRequest,
	forgotPasswordSuccess,
	forgotPasswordFail,
	forgotPasswordTravellerRequest,
	forgotPasswordTravellerSuccess,
	forgotPasswordTravellerFail,
} = actions;

export const agentSignupDispatch = (bodyData, history) => async (dispatch) => {
	try {
		dispatch(agentsignupRequest());

		const { data } = await axiosInstance.post(
			'agent/register',
			bodyData,
			config
		);
		// console.log(data);
		dispatch(agentsignupSuccess(data));
		console.log('error', data);
		if (data.status === 200) {
			dispatch(toggleSuccessModal(true));
			dispatch(toggleRegisterModal(false));
			setTimeout(() => {
				dispatch(toggleSuccessModal(false));
				// history.push('/agent');
				dispatch(setSuccessMessage(''));
			}, 3000);
			dispatch(setSuccessMessage(data.result.message));
		} else {
			dispatch(toggleErrorModal(true));
			dispatch(setErrorMessage(data.result.message));
			dispatch(toggleRegisterModal(false));
			console.log('error', data);
		}
	} catch (error) {
		dispatch(toggleErrorModal(true));
		dispatch(toggleRegisterModal(false));
		dispatch(
			setErrorMessage(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
		dispatch(
			agentsignupFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const agentloginDispatch = (bodyData, history) => async (dispatch) => {
	try {
		dispatch(agentloginRequest());

		const { data } = await axiosInstance.post('agent/login', bodyData, config);
		// console.log(data);
		dispatch(agentloginSuccess(data));

		// dispatch(
		// 	agentbookinghistoryDispatch(
		// 		data && data.result && data.result.result && data.result.result.id
		// 	)
		// );
		storeData('userInfoAgent', data);
		// sessionStorage.setItem('userInfoAgent', data);
		if (data.status === 200) {
			// console.log("200");
			dispatch(toggleSuccessModal(true));
			dispatch(
				walletBalanceAgentDispatch(
					data && data.result && data.result.result && data.result.result.email,
					data && data.result && data.result.result && data.result.result.id
				)
			);
			dispatch(
				agentbookinghistoryDispatch(
					data && data.result && data.result.result && data.result.result.id
				)
			);
			dispatch(
				busbookinghistoryDispatch(
					data && data.result && data.result.result && data.result.result.id
				)
			);
			dispatch(
				agentMarkupDispatch(
					data && data.result && data.result.result && data.result.result.id
				)
			);
			dispatch(
				agenttransactionhistoryDispatch(
					data && data.result && data.result.result && data.result.result.id
				)
			);
			let logo = {
				agent_id:
					data && data.result && data.result.result && data.result.result.id,
			};
			dispatch(agentlogogetDispatch(logo));
			// dispatch(toggleRegisterTravellerModal(false))
			setTimeout(() => {
				dispatch(toggleSuccessModal(false));
				dispatch(setSuccessMessage(''));
				// history.push('/dashboard');
			}, 1200);
			dispatch(setSuccessMessage('Login Successfully'));
		} else {
			// console.log("203");
			dispatch(toggleErrorModal(true));
			dispatch(setErrorMessage(data.result.message));
		}
	} catch (error) {
		dispatch(toggleErrorModal(true));
		dispatch(
			setErrorMessage(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
		dispatch(
			agentloginFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const agentlogoDispatch =
	(bodyData, id, history) => async (dispatch) => {
		try {
			dispatch(agentlogoRequest());

			const { data } = await axiosInstance.post(
				'agent/editlogo',
				bodyData,
				config
			);
			console.log(data);
			// dispatch(agentlogoSuccess(data));
			let logodata = {
				agent_id: id,
			};
			dispatch(agentlogogetDispatch(logodata));
			if (data.status === 200) {
				dispatch(toggleSuccessModal(true));

				// storeData('userInfo', data);
				setTimeout(() => {
					dispatch(toggleSuccessModal(false));

					dispatch(setSuccessMessage(''));
					dispatch(toggleChangeLogoModal(false));
				}, 1200);
				dispatch(setSuccessMessage('File Upload Successfully'));
			} else {
				dispatch(toggleChangeLogoModal(false));
				dispatch(toggleErrorModal(true));
				dispatch(setErrorMessage(data.result.message));
				// history.push('/dashboard');
			}
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(toggleChangeLogoModal(false));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				agentlogoFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const agentlogogetDispatch = (bodyData, history) => async (dispatch) => {
	try {
		dispatch(agentlogogetRequest());

		const { data } = await axiosInstance.post('agent/logo', bodyData, config);
		// console.log(data);
		dispatch(agentlogogetSuccess(data));
		storeData('agentlogoget', data);
		// if (data.status === 200) {
		// 	dispatch(toggleSuccessModal(true));
		// 	// storeData('userInfo', data);
		// 	setTimeout(() => {
		// 		dispatch(toggleSuccessModal(false));

		// 		dispatch(setSuccessMessage(''));
		// 		dispatch(toggleChangeLogoModal(false));
		// 	}, 1200);
		// 	dispatch(setSuccessMessage('File Upload Successfully'));
		// } else {
		// 	dispatch(toggleChangeLogoModal(false));
		// 	dispatch(toggleErrorModal(true));
		// 	dispatch(setErrorMessage(data.result.message));
		// 	// history.push('/dashboard');
		// }
	} catch (error) {
		dispatch(toggleErrorModal(true));
		// dispatch(toggleChangeLogoModal(false));
		dispatch(
			setErrorMessage(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
		dispatch(
			agentlogogetFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const agentchangePasswordDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(agentchangepasswordRequest());

			const { data } = await axiosInstance.post(
				'agent/resetpassword',
				bodyData,
				config
			);
			console.log(data);
			dispatch(agentchangepasswordSuccess(data));
			if (data.status === 200) {
				dispatch(toggleSuccessModal(true));
				// storeData('userInfo', data);
				setTimeout(() => {
					dispatch(toggleSuccessModal(false));

					dispatch(setSuccessMessage(''));
					history.push('/dashboard');
				}, 1200);
				dispatch(setSuccessMessage(data.result.message));
			} else {
				dispatch(toggleErrorModal(true));
				dispatch(setErrorMessage(data.result.message));
				// history.push('/dashboard');
			}
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				agentchangepasswordFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const agentedituserDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(agentedituserRequest());

			const { data } = await axiosInstance.post(
				'agent/edituser',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(agentedituserSuccess(data));
			if (data.status === 200) {
				dispatch(toggleSuccessModal(true));
				// storeData('userInfo', data);
				setTimeout(() => {
					dispatch(toggleSuccessModal(false));

					dispatch(setSuccessMessage(''));
					history.push('/dashboard');
				}, 1200);
				dispatch(setSuccessMessage(data.result.message));
			} else {
				dispatch(toggleErrorModal(true));
				dispatch(setErrorMessage(data.result.message));
				// history.push('/dashboard');
			}
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				agentedituserFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const agentbookinghistoryDispatch = (userId) => async (dispatch) => {
	try {
		dispatch(agentbookinghistoryRequest());
		dispatch(travellerbookinghistorySuccess(null));
		let formData = {
			userId: userId,
			// userId: 1,
		};

		const { data } = await axiosInstance.post(
			'agent/bookinghistory',
			formData,
			config
		);
		// console.log(data);
		dispatch(agentbookinghistorySuccess(data));
		storeData('bookinghistoryagent', data);
		// if (data.status === 200) {
		// 	dispatch(toggleSuccessModal(true));
		// 	// storeData('userInfo', data);
		// 	setTimeout(() => {
		// 		dispatch(toggleSuccessModal(false));
		// 		history.push('/dashboard');
		// 		dispatch(setSuccessMessage(''));
		// 	}, 1200);
		// 	// dispatch(setSuccessMessage(data.result.message));
		// } else {
		// 	dispatch(toggleErrorModal(true));
		// 	dispatch(setErrorMessage(data.result.message));
		// }
	} catch (error) {
		dispatch(toggleErrorModal(true));
		dispatch(
			setErrorMessage(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
		dispatch(
			agentbookinghistoryFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const agenttransactionhistoryDispatch = (userId) => async (dispatch) => {
	try {
		dispatch(agenttransRequest());
		let formData = {
			agent_id: userId,
			// agent_id: 1,
		};

		const { data } = await axiosInstance.post(
			'agent/agenttrans',
			formData,
			config
		);
		// console.log(data);
		dispatch(agenttransSuccess(data));
		// storeData('bookinghistoryagent', data);
		// if (data.status === 200) {
		// 	dispatch(toggleSuccessModal(true));
		// 	// storeData('userInfo', data);
		// 	setTimeout(() => {
		// 		dispatch(toggleSuccessModal(false));
		// 		history.push('/dashboard');
		// 		dispatch(setSuccessMessage(''));
		// 	}, 1200);
		// 	// dispatch(setSuccessMessage(data.result.message));
		// } else {
		// 	dispatch(toggleErrorModal(true));
		// 	dispatch(setErrorMessage(data.result.message));
		// }
	} catch (error) {
		dispatch(toggleErrorModal(true));
		dispatch(
			setErrorMessage(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
		dispatch(
			agenttransFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const agentMarkupDispatch = (userId) => async (dispatch) => {
	try {
		dispatch(markupRequest());
		let formData = {
			// userId: userId,
			userId: userId,
		};

		const { data } = await axiosInstance.post(
			'agent/agentmarkup',
			formData,
			config
		);
		// console.log(data);
		dispatch(markupSuccess(data));
		storeData('agentMarkup', data);
	} catch (error) {
		dispatch(toggleErrorModal(true));
		dispatch(
			setErrorMessage(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
		dispatch(
			markupFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const agentEditMarkupDispatch =
	(bodyData, history, id) => async (dispatch) => {
		try {
			dispatch(markupEditRequest());

			const { data } = await axiosInstance.post(
				'agent/agenteditmarkup',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(markupEditSuccess(data));
			dispatch(agentMarkupDispatch(id));
			if (data.status === 200) {
				dispatch(toggleSuccessModal(true));

				setTimeout(() => {
					dispatch(toggleSuccessModal(false));

					dispatch(setSuccessMessage(''));
					history.push('/dashboard');
				}, 1200);
			} else {
				dispatch(toggleErrorModal(true));
				dispatch(setErrorMessage(data.result.message));
			}
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				markupEditFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const walletBalanceAgentDispatch =
	(userEmail, userId) => async (dispatch) => {
		try {
			dispatch(walletagentRequest());
			let formData = {
				userEmail: userEmail,
				userId: userId,
			};

			const { data } = await axiosInstance.post(
				'agent/walletbalance',
				formData,
				config
			);
			// console.log(data);
			dispatch(walletagentSuccess(data));
			storeData('walletbalanceagent', data);
			// if (data.status === 200) {
			// 	dispatch(toggleSuccessModal(true));
			// 	storeData('userInfo', data);
			// 	setTimeout(() => {
			// 		dispatch(toggleSuccessModal(false));
			// 		history.push('/dashboard');
			// 		dispatch(setSuccessMessage(''));
			// 	}, 1200);
			// 	// dispatch(setSuccessMessage(data.result.message));
			// } else {
			// 	dispatch(toggleErrorModal(true));
			// 	dispatch(setErrorMessage(data.result.message));
			// }
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				walletagentFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const travellerSignupDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(travellersignupRequest());

			const { data } = await axiosInstance.post(
				'home/registeruser',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(travellersignupSuccess(data));
			if (data.status === 200) {
				dispatch(toggleSuccessModal(true));
				setTimeout(() => {
					dispatch(toggleSuccessModal(false));
					// history.push('/agent');
					dispatch(toggleRegisterTravellerModal(false));
					dispatch(setSuccessMessage(''));
				}, 1200);
				dispatch(setSuccessMessage(data.result.message));
			} else {
				dispatch(toggleErrorModal(true));
				dispatch(setErrorMessage(data.result.message));
				dispatch(toggleRegisterTravellerModal(false));
			}
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(toggleRegisterTravellerModal(false));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				travellersignupFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const travellerloginDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(travellerloginRequest());

			const { data } = await axiosInstance.post('home/login', bodyData, config);
			// console.log(data);
			dispatch(travellerloginSuccess(data));

			storeData('userInfo', data);
			if (data.status === 200) {
				dispatch(toggleSuccessModal(true));
				dispatch(
					walletBalanceDispatch(
						data &&
							data.result &&
							data.result.result &&
							data.result.result.email,
						data && data.result && data.result.result && data.result.result.id
					)
				);
				dispatch(
					travellerbookinghistoryDispatch(
						data && data.result && data.result.result && data.result.result.id
					)
				);
				// storeData('userInfo', data);
				setTimeout(() => {
					dispatch(toggleSuccessModal(false));

					dispatch(setSuccessMessage(''));
					history.push('/dashboard');
				}, 1200);
				dispatch(setSuccessMessage('Login Successfully'));
			} else {
				dispatch(toggleErrorModal(true));
				dispatch(setErrorMessage(data.result.message));
			}
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				travellerloginFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const walletBalanceDispatch =
	(userEmail, userId) => async (dispatch) => {
		try {
			dispatch(walletRequest());
			let formData = {
				userEmail: userEmail,
				userId: userId,
			};

			const { data } = await axiosInstance.post(
				'home/walletbalance',
				formData,
				config
			);
			console.log(data);
			dispatch(walletSuccess(data));
			storeData('walletbalance', data);
			// if (data.status === 200) {
			// 	dispatch(toggleSuccessModal(true));
			// 	storeData('userInfo', data);
			// 	setTimeout(() => {
			// 		dispatch(toggleSuccessModal(false));
			// 		history.push('/dashboard');
			// 		dispatch(setSuccessMessage(''));
			// 	}, 1200);
			// 	// dispatch(setSuccessMessage(data.result.message));
			// } else {
			// 	dispatch(toggleErrorModal(true));
			// 	dispatch(setErrorMessage(data.result.message));
			// }
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				walletFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const travellerchangePasswordDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(travellerchangepasswordRequest());

			const { data } = await axiosInstance.post(
				'home/resetpassword',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(travellerchangepasswordSuccess(data));
			if (data.status === 200) {
				dispatch(toggleSuccessModal(true));
				// storeData('userInfo', data);
				setTimeout(() => {
					dispatch(toggleSuccessModal(false));

					dispatch(setSuccessMessage(''));
					history.push('/dashboard');
				}, 1200);
				dispatch(setSuccessMessage(data.result.message));
			} else {
				dispatch(toggleErrorModal(true));
				dispatch(setErrorMessage(data.result.message));
				// history.push('/dashboard');
			}
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				travellerchangepasswordFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const travelleredituserDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(travelleredituserRequest());

			const { data } = await axiosInstance.post(
				'home/edituser',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(travelleredituserSuccess(data));
			if (data.status === 200) {
				dispatch(toggleSuccessModal(true));
				// storeData('userInfo', data);
				setTimeout(() => {
					dispatch(toggleSuccessModal(false));

					dispatch(setSuccessMessage(''));
					history.push('/dashboard');
				}, 1200);
				dispatch(setSuccessMessage(data.result.message));
			} else {
				dispatch(toggleErrorModal(true));
				dispatch(setErrorMessage(data.result.message));
				// history.push('/dashboard');
			}
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				travelleredituserFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const travellerbookinghistoryDispatch = (userId) => async (dispatch) => {
	try {
		dispatch(travellerbookinghistoryRequest());
		dispatch(agentbookinghistorySuccess(null));
		let formData = {
			// userId: userId,
			userId: 1,
		};

		const { data } = await axiosInstance.post(
			'agent/bookinghistory',
			formData,
			config
		);
		// console.log(data);
		dispatch(travellerbookinghistorySuccess(data));
		storeData('bookinghistory', data);
		// if (data.status === 200) {
		// 	dispatch(toggleSuccessModal(true));
		// 	// storeData('userInfo', data);
		// 	setTimeout(() => {
		// 		dispatch(toggleSuccessModal(false));
		// 		history.push('/dashboard');
		// 		dispatch(setSuccessMessage(''));
		// 	}, 1200);
		// 	// dispatch(setSuccessMessage(data.result.message));
		// } else {
		// 	dispatch(toggleErrorModal(true));
		// 	dispatch(setErrorMessage(data.result.message));
		// }
	} catch (error) {
		dispatch(toggleErrorModal(true));
		dispatch(
			setErrorMessage(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
		dispatch(
			travellerbookinghistoryFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const verifyAccountDispatch =
	(formData, history) => async (dispatch) => {
		try {
			dispatch(verifyRequest());

			const { data } = await axiosInstance.post(
				'home/verify',
				formData,
				config
			);
			console.log(data);
			dispatch(verifySuccess(data));
			if (data.status === 200) {
				setTimeout(() => {
					history.push('/');
				}, [5000]);
			} else {
				setTimeout(() => {
					history.push('/');
				}, [7000]);
			}
		} catch (error) {
			// dispatch(toggleErrorModal(true));

			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				verifyFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const flightTicketDispatch = (formData, history) => async (dispatch) => {
	try {
		dispatch(flightTicketRequest());

		const { data } = await axiosInstance.post(
			'flight/flightticket',
			formData,
			config
		);
		// console.log(data);
		dispatch(flightTicketSuccess(data));
		if (data.status === 200) {
		} else {
			alert('No Record Found');
		}
	} catch (error) {
		dispatch(
			setErrorMessage(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
		dispatch(
			flightTicketFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};

export const forgotPasswordDispatch =
	(formData, history) => async (dispatch) => {
		try {
			dispatch(forgotPasswordRequest());

			const { data } = await axiosInstance.post(
				'agent/forgotpassword',
				formData,
				config
			);
			// console.log(data);
			dispatch(forgotPasswordSuccess(data));
			if (data.status === 200) {
				dispatch(toggleSuccessModal(true));
				dispatch(
					setSuccessMessage(`${data.result.message} Please Check Your Email`)
				);
				setTimeout(() => {
					dispatch(toggleSuccessModal(false));
				}, [2000]);
			} else {
				dispatch(toggleErrorModal(true));
				dispatch(setErrorMessage(data.result.message));
			}
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				forgotPasswordFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const forgotPasswordTravellerDispatch =
	(formData, history) => async (dispatch) => {
		try {
			dispatch(forgotPasswordTravellerRequest());

			const { data } = await axiosInstance.post(
				'home/forgotpassword',
				formData,
				config
			);
			// console.log(data);
			dispatch(forgotPasswordTravellerSuccess(data));
			if (data.status === 200) {
				dispatch(toggleSuccessModal(true));

				dispatch(
					setSuccessMessage(`${data.result.message} Please Check Your Email`)
				);
				setTimeout(() => {
					dispatch(toggleSuccessModal(false));
				}, [2000]);
			} else {
				dispatch(toggleErrorModal(true));
				dispatch(setErrorMessage(data.result.message));
			}
		} catch (error) {
			dispatch(toggleErrorModal(true));
			dispatch(
				setErrorMessage(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
			dispatch(
				forgotPasswordTravellerFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export default UserReducer;
