import React, { useEffect, useState } from 'react';
import {
	Box,
	Grid,
	makeStyles,
	withStyles,
	FormControlLabel,
	Checkbox,
	Hidden,
	useMediaQuery,
	useTheme,
	Divider,
} from '@material-ui/core';
import Loader from '../../assets/images/Loader.svg';
import 'react-loading-skeleton/dist/skeleton.css';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
	agentlogogetDispatch,
	walletBalanceAgentDispatch,
} from '../../reducers/UserReducer';
import FlightDetailModal from '../../components/reusableComponents/FlightDetailModal';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
	setPriceSection,
	togglePriceChangeModal,
	toggleSessionExpiredModal,
} from '../../reducers/UiReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
	AddSearchFlightDispatch,
	fetchFooterMenu,
	fetchFooterSub_Menu,
	fetchlogo,
} from '../../reducers/HomeReducer';
import Login from '../../components/reusableComponents/Login';
import OnewayForm from '../../form/OneWayFlightForm';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import SkeletonOneway from '../../components/reusableComponents/SkeletonOneway';
import OneWayMobileRow from '../Mobile/OneWayMobileRow';
import Linear from '../../components/reusableComponents/Linear';
import OneWayDesktopRow from '../../components/reusableComponents/OneWayDesktopRow';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiChip-sizeSmall': {
			height: 36,
		},
	},
	accodin: {
		'& .MuiAccordionSummary-content': {
			justifyContent: 'space-between',
		},
	},
	searhform: {
		marginTop: 60,
		paddingBottom: 10,
		paddingLeft: 20,
		paddingRight: 20,
		backgroundColor: 'midnightblue',
		[theme.breakpoints.down('xs')]: {
			marginTop: 0,
		},
	},
	radio: {
		'& .MuiTypography-body1': {
			fontSize: 13,
		},
	},
}));

const GreenCheckbox = withStyles({
	root: {
		color: 'rgb(239, 51, 57)',
		'&$checked': {
			color: 'rgb(239, 51, 57)',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const OneWay = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [expanded, setExpanded] = React.useState('');
	const [stopcheck1, setStopCheck1] = useState(false);
	const [stopcheck2, setStopCheck2] = useState(false);
	const [stopcheck3, setStopCheck3] = useState(false);
	const [airlineS, setairlineS] = useState(false);
	const [morning, setMorning] = useState(false);
	const [refund, setRefund] = useState(false);
	const [nrefund, setNRefund] = useState(false);
	const [net, setNet] = useState(false);
	const [rule, SetRule] = useState(false);
	const [msearch, setMSearch] = useState(false);
	const [filter, setFilter] = useState(false);
	const [scrollVal, SetScrollVal] = useState(10);
	const [scrollValResult, SetScrollValResult] = useState(10);
	const [hasMoreresult, SethasMoreResult] = useState(true);
	const [hasMorefilterData, SethasMoreFilterData] = useState(true);
	const [result, setResult] = useState([]);
	const history = useHistory();
	const theme = useTheme();
	const location = useLocation();
	const [air, setAir] = useState(true);
	const [stp, setStp] = useState(true);
	const [out, setOut] = useState(true);
	const [rf, setRf] = useState(true);
	const {
		search,
		loading,
		onewayflightdata,
		onewayflightdataEtrav,
		tripjack,
		ltbo,
		letrav,
		loading2,
	} = useSelector((state) => state.home);
	const { selectedOrigin, selectedDepart, modify, filt } = useSelector(
		(state) => state.ui
	);
	const { agentlogin } = useSelector((state) => state.user);
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const matches2 = useMediaQuery(theme.breakpoints.down('xs'));

	const handleNetFare = (event) => {
		setNet(event.target.checked);
	};
	useEffect(() => {
		if (location.state) {
			let form = {};
			dispatch(
				AddSearchFlightDispatch(
					location.state.body,
					history,
					selectedOrigin,
					selectedDepart
				)
			);
			dispatch(setPriceSection(true));
			dispatch(togglePriceChangeModal(false));
			dispatch(toggleSessionExpiredModal(false));
			dispatch(fetchFooterMenu(form));
			dispatch(fetchFooterSub_Menu(form));
			dispatch(fetchlogo(form));
			if (agentlogin && agentlogin.status === 200) {
				let logodata = {
					agent_id: agentlogin && agentlogin.result.result.id,
				};
				dispatch(agentlogogetDispatch(logodata));
				dispatch(
					walletBalanceAgentDispatch(
						agentlogin && agentlogin.result.result.email,
						agentlogin && agentlogin.result.result.id
					)
				);
			}
			window.scrollTo(0, 0);
		} else {
			console.log('loding...');
		}
	}, [dispatch]);

	function convertH2M(timeInHour) {
		var timeParts = timeInHour.split(':');
		return Number(timeParts[0]) * 60 + Number(timeParts[1]);
	}
	function timeConvert(n) {
		var num = n;
		var hours = num / 60;
		var rhours = Math.floor(hours);
		var minutes = (hours - rhours) * 60;
		var rminutes = Math.round(minutes);
		return rhours + ' hr and ' + rminutes + ' min.';
	}

	let combineflightData = [];
	let filterData = [];
	let filterAirlineTBO = [];
	let filterAirlineETR = [];
	let filterAirlineTJK = [];
	let filterAirlineCombine = [];

	if (loading) {
		console.log('Loading');
	} else {
		let tbo =
			onewayflightdata &&
			onewayflightdata?.result &&
			onewayflightdata?.result?.Response &&
			onewayflightdata?.result?.Response?.Results[0].length > 0 &&
			onewayflightdata?.result?.Response?.Results[0];
		let Etrav =
			onewayflightdataEtrav &&
			onewayflightdataEtrav?.result &&
			onewayflightdataEtrav?.result?.TripDetails &&
			onewayflightdataEtrav?.result?.TripDetails[0]?.Flights.length > 0 &&
			onewayflightdataEtrav?.result?.TripDetails[0]?.Flights;
		let tjk =
			tripjack &&
			tripjack?.result &&
			tripjack?.result?.searchResult &&
			tripjack?.result?.searchResult?.tripInfos &&
			tripjack?.result?.searchResult?.tripInfos?.ONWARD;
		if (Etrav?.length > 0 && tbo?.length > 0 && tjk?.length > 0) {
			combineflightData = [...tbo, ...Etrav, ...tjk];
		} else if (Etrav?.length > 0 && tbo?.length > 0) {
			combineflightData = [...tbo, ...Etrav];
		} else if (Etrav?.length > 0 && tjk?.length > 0) {
			combineflightData = [...Etrav, ...tjk];
		} else if (tbo?.length > 0 && tjk?.length > 0) {
			combineflightData = [...tbo, ...tjk];
		} else if (tbo?.length > 0) {
			combineflightData = [...tbo];
		} else if (tjk?.length > 0) {
			combineflightData = [...tjk];
		} else if (Etrav?.length > 0) {
			combineflightData = [...Etrav];
		}

		// console.log("combine", combineflightData);

		filterData =
			combineflightData &&
			combineflightData.length > 0 &&
			combineflightData.slice().sort((a, b) => {
				let first = a.Fare
					? Number(
							Number(Math.round(a.Fare.Total_Amount)) -
								Number(Math.round(a.Fare.MFB_Discount))
					  )
					: a.Fares
					? Number(Math.round(a.Fares.Total_Amount))
					: a.totalPriceList
					? Number(Math.round(a.totalPriceList.fd.Total_Amount))
					: Number(
							Number(Math.round(a.Fare.Total_Amount)) -
								Number(Math.round(a.Fare.MFB_Discount))
					  );
				let second = b.Fare
					? Number(
							Number(Math.round(b.Fare.Total_Amount)) -
								Number(Math.round(b.Fare.MFB_Discount))
					  )
					: b.Fares
					? Number(Math.round(b.Fares.Total_Amount))
					: b.totalPriceList
					? Number(Math.round(b.totalPriceList.fd.Total_Amount))
					: Number(Math.round(b.Fare.Total_Amount)) -
					  Number(Math.round(b.Fare.MFB_Discount));
				return first - second;
			});
		if (
			onewayflightdata &&
			onewayflightdata?.result &&
			onewayflightdata?.result?.Response &&
			onewayflightdata?.result?.Response?.Results[0].length > 0
		) {
			filterAirlineTBO = [
				...new Set(
					onewayflightdata &&
						onewayflightdata?.result &&
						onewayflightdata?.result?.Response &&
						onewayflightdata?.result?.Response?.Results[0].length > 0 &&
						onewayflightdata?.result?.Response?.Results[0].map(
							(item) => item.AirlineCode
						)
				),
			];
		}
		if (
			onewayflightdataEtrav &&
			onewayflightdataEtrav?.result &&
			onewayflightdataEtrav?.result?.TripDetails &&
			onewayflightdataEtrav?.result?.TripDetails[0]?.Flights.length > 0
		) {
			filterAirlineETR = [
				...new Set(
					onewayflightdataEtrav &&
						onewayflightdataEtrav?.result &&
						onewayflightdataEtrav?.result?.TripDetails &&
						onewayflightdataEtrav?.result?.TripDetails[0]?.Flights.length > 0 &&
						onewayflightdataEtrav?.result?.TripDetails[0]?.Flights.map(
							(item) => item.Airline_Code
						)
				),
			];
		}
		if (
			tripjack &&
			tripjack?.result &&
			tripjack?.result?.searchResult &&
			tripjack?.result?.searchResult?.tripInfos &&
			tripjack?.result?.searchResult?.tripInfos?.ONWARD.length > 0
		) {
			filterAirlineTJK = [
				...new Set(
					tripjack &&
						tripjack?.result &&
						tripjack?.result?.searchResult &&
						tripjack?.result?.searchResult?.tripInfos &&
						tripjack?.result?.searchResult?.tripInfos?.ONWARD.map(
							(item) => item.sI[0].fD.aI.code
						)
				),
			];
		}
	}
	if (combineflightData && combineflightData.length > 0) {
		filterAirlineCombine = [
			...new Set([
				...filterAirlineTBO,
				...filterAirlineETR,
				...filterAirlineTJK,
			]),
		];
	}

	const fetchMoreData = () => {
		if (ltbo === false && letrav === false) {
			if (scrollVal >= filterData.length) {
				SethasMoreFilterData(false);
			}
		}

		setTimeout(() => {
			SetScrollVal(scrollVal + 5);
		}, 500);
	};
	const fetchMoreDataResult = () => {
		if (scrollValResult >= result.length) {
			SethasMoreResult(false);
		}
		setTimeout(() => {
			SetScrollValResult(scrollValResult + 5);
		}, 500);
	};

	//  Filter Section Starting
	const handleStop = (event) => {
		setStopCheck1(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (stopcheck1) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (onewayflightdata) {
				onewayflightdata &&
					onewayflightdata.result &&
					onewayflightdata.result.Response &&
					onewayflightdata.result.Response.Results[0].length > 0 &&
					onewayflightdata.result.Response.Results[0].map((item) => {
						if (item.Segments[0].length === 1) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (onewayflightdataEtrav) {
				onewayflightdataEtrav &&
					onewayflightdataEtrav.result &&
					onewayflightdataEtrav.result.TripDetails[0] &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.length > 0 &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.map((item) => {
						if (item.Segments.length === 1) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (tripjack) {
				tripjack &&
					tripjack.result &&
					tripjack.result.searchResult &&
					tripjack.result.searchResult.tripInfos &&
					tripjack.result.searchResult.tripInfos.ONWARD &&
					tripjack.result.searchResult.tripInfos.ONWARD.length > 0 &&
					tripjack.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.sI.length === 1) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			setResult(filterDataStop);
		}
	};
	const handleStop2 = (event) => {
		setStopCheck2(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (stopcheck2) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (onewayflightdata) {
				onewayflightdata &&
					onewayflightdata.result &&
					onewayflightdata.result.Response &&
					onewayflightdata.result.Response.Results[0].length > 0 &&
					onewayflightdata.result.Response.Results[0].map((item) => {
						if (item.Segments[0].length === 2) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (onewayflightdataEtrav) {
				onewayflightdataEtrav &&
					onewayflightdataEtrav.result &&
					onewayflightdataEtrav.result.TripDetails[0] &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.length > 0 &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.map((item) => {
						if (item.Segments.length === 2) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (tripjack) {
				tripjack &&
					tripjack.result &&
					tripjack.result.searchResult &&
					tripjack.result.searchResult.tripInfos &&
					tripjack.result.searchResult.tripInfos.ONWARD &&
					tripjack.result.searchResult.tripInfos.ONWARD.length > 0 &&
					tripjack.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.sI.length === 2) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			setResult(filterDataStop);
		}
	};
	const handleStop3 = (event) => {
		setStopCheck3(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (stopcheck3) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (onewayflightdata) {
				onewayflightdata &&
					onewayflightdata.result &&
					onewayflightdata.result.Response &&
					onewayflightdata.result.Response.Results[0].length > 0 &&
					onewayflightdata.result.Response.Results[0].map((item) => {
						if (item.Segments[0].length === 3) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (onewayflightdataEtrav) {
				onewayflightdataEtrav &&
					onewayflightdataEtrav.result &&
					onewayflightdataEtrav.result.TripDetails[0] &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.length > 0 &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.map((item) => {
						if (item.Segments.length === 3) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (tripjack) {
				tripjack &&
					tripjack.result &&
					tripjack.result.searchResult &&
					tripjack.result.searchResult.tripInfos &&
					tripjack.result.searchResult.tripInfos.ONWARD &&
					tripjack.result.searchResult.tripInfos.ONWARD.length > 0 &&
					tripjack.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.sI.length === 3) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			setResult(filterDataStop);
		}
	};
	const handleAirline = (event) => {
		SethasMoreResult(true);
		SetScrollValResult(10);
		setairlineS(event.target.name);

		if (airlineS === event.target.name) {
			setResult([]);
			SetRule(event.target.checked);
			setairlineS('');
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (onewayflightdata) {
				onewayflightdata &&
					onewayflightdata &&
					onewayflightdata.result &&
					onewayflightdata.result.Response &&
					onewayflightdata.result.Response.Results[0].length > 0 &&
					onewayflightdata.result.Response.Results[0].map((item) => {
						if (item.AirlineCode === event.target.name) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (onewayflightdataEtrav) {
				onewayflightdataEtrav &&
					onewayflightdataEtrav.result &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.length > 0 &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.map((item) => {
						if (item.Airline_Code === event.target.name) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (tripjack) {
				tripjack &&
					tripjack.result &&
					tripjack.result.searchResult &&
					tripjack.result.searchResult.tripInfos &&
					tripjack.result.searchResult.tripInfos.ONWARD &&
					tripjack.result.searchResult.tripInfos.ONWARD.length > 0 &&
					tripjack.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.sI[0].fD.aI.code === event.target.name) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			setResult(filterDataStop);
		}
	};
	const handleMorning = (event) => {
		setExpanded(event.target.name);
		setMorning(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (expanded === event.target.name) {
			setResult([]);
			SetRule(event.target.checked);
			setExpanded('');
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (onewayflightdata) {
				onewayflightdata &&
					onewayflightdata.result &&
					onewayflightdata.result.Response &&
					onewayflightdata.result.Response.Results[0].length > 0 &&
					onewayflightdata.result.Response.Results[0].map((item) => {
						let a = item.Segments[0].slice(0, 1).map((i) => {
							return i.Origin.DepTime;
						});
						let b = item.Segments[0].slice(0, 1).map((i) => {
							return i.Destination.ArrTime.substr(11, 2);
						});
						const time1 = new Date(a).getHours();
						if (event.target.name === 'Morning') {
							if (time1 >= 4 && time1 <= 11) {
								tbo.push({
									...item,
								});
							}
						} else if (event.target.name === 'Afternoon') {
							if (time1 >= 11 && time1 <= 16) {
								tbo.push({
									...item,
								});
							}
						} else if (event.target.name === 'Evening') {
							if (time1 >= 16 && time1 <= 21) {
								tbo.push({
									...item,
								});
							}
						} else if (event.target.name === 'Night') {
							if (time1 >= 21 && time1 <= 4) {
								tbo.push({
									...item,
								});
							}
						}
					});
			}

			filterCombineStop = [...tbo];
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			setResult(filterDataStop);
		}
	};
	const handleFareR = (event) => {
		setRefund(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (refund) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (onewayflightdata) {
				onewayflightdata &&
					onewayflightdata.result &&
					onewayflightdata.result.Response &&
					onewayflightdata.result.Response.Results[0].length > 0 &&
					onewayflightdata.result.Response.Results[0].map((item) => {
						if (item.IsRefundable) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (onewayflightdataEtrav) {
				onewayflightdataEtrav &&
					onewayflightdataEtrav.result &&
					onewayflightdataEtrav.result.TripDetails[0] &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.length > 0 &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.map((item) => {
						if (item.Fares.Refundable) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (tripjack) {
				tripjack &&
					tripjack.result &&
					tripjack.result.searchResult &&
					tripjack.result.searchResult.tripInfos &&
					tripjack.result.searchResult.tripInfos.ONWARD &&
					tripjack.result.searchResult.tripInfos.ONWARD.length > 0 &&
					tripjack.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.totalPriceList.fd.ADULT.rT === 1) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			setResult(filterDataStop);
		}
	};
	const handleFareN = (event) => {
		setNRefund(event.target.checked);
		SethasMoreResult(true);
		SetScrollValResult(10);
		if (nrefund) {
			setResult([]);
			SetRule(event.target.checked);
		} else {
			SetRule(event.target.checked);
			let filterCombineStop = [];
			let filterDataStop = [];
			let tbo = [];
			let etrav = [];
			let tjk = [];
			if (onewayflightdata) {
				onewayflightdata &&
					onewayflightdata.result &&
					onewayflightdata.result.Response &&
					onewayflightdata.result.Response.Results[0].length > 0 &&
					onewayflightdata.result.Response.Results[0].map((item) => {
						if (!item.IsRefundable) {
							tbo.push({
								...item,
							});
						}
					});
			}
			if (onewayflightdataEtrav) {
				onewayflightdataEtrav &&
					onewayflightdataEtrav.result &&
					onewayflightdataEtrav.result.TripDetails[0] &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.length > 0 &&
					onewayflightdataEtrav.result.TripDetails[0].Flights.map((item) => {
						if (!item.Fares.Refundable) {
							etrav.push({
								...item,
							});
						}
					});
			}
			if (tripjack) {
				tripjack &&
					tripjack.result &&
					tripjack.result.searchResult &&
					tripjack.result.searchResult.tripInfos &&
					tripjack.result.searchResult.tripInfos.ONWARD &&
					tripjack.result.searchResult.tripInfos.ONWARD.length > 0 &&
					tripjack.result.searchResult.tripInfos.ONWARD.map((item) => {
						if (item.totalPriceList.fd.ADULT.rT === 0) {
							tjk.push({
								...item,
							});
						}
					});
			}
			filterCombineStop = [...tbo, ...etrav, ...tjk];
			filterDataStop =
				filterCombineStop &&
				filterCombineStop.length > 0 &&
				filterCombineStop.slice().sort((a, b) => {
					let first = a.Fare
						? Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  )
						: a.Fares
						? Number(Math.round(a.Fares.Total_Amount))
						: a.totalPriceList
						? Number(Math.round(a.totalPriceList.fd.Total_Amount))
						: Number(
								Number(Math.round(a.Fare.Total_Amount)) -
									Number(Math.round(a.Fare.MFB_Discount))
						  );
					let second = b.Fare
						? Number(
								Number(Math.round(b.Fare.Total_Amount)) -
									Number(Math.round(b.Fare.MFB_Discount))
						  )
						: b.Fares
						? Number(Math.round(b.Fares.Total_Amount))
						: b.totalPriceList
						? Number(Math.round(b.totalPriceList.fd.Total_Amount))
						: Number(Math.round(b.Fare.Total_Amount)) -
						  Number(Math.round(b.Fare.MFB_Discount));
					return first - second;
				});
			setResult(filterDataStop);
		}
	};
	console.log('Flter', filterData);
	// Filter Section Ending
	return (
		<>
			{/* Header Starting */}
			<HeaderSection />
			{/* Header Ending */}
			{matches ? (
				<Box
					style={{
						display: 'flex',
						marginTop: '66px',
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: 10,
					}}>
					<Box onClick={() => (filter ? setFilter(false) : setFilter(true))}>
						<i class='fa-solid fa-filter' style={{ margin: 6, fontSize: 16 }}>
							{' '}
						</i>
						<span style={{ fontSize: 16 }}>Filter</span>
					</Box>

					<Box onClick={() => (msearch ? setMSearch(false) : setMSearch(true))}>
						<i class='fa fa-search' style={{ margin: 6, fontSize: 16 }}></i>
						<span style={{ fontSize: 16 }}>Modify</span>
					</Box>
				</Box>
			) : (
				''
			)}
			{/* Oneway Form Starting */}
			{!ltbo && !letrav ? (
				matches ? (
					msearch ? (
						<Box
							styel={{
								visibility: true ? 'hidden' : 'visible',
								opacity: true ? 0 : 1,
								transition: true
									? ''
									: 'visibility 0s linear 0.33s, opacity 0.33s linear',
								transitionDelay: true ? '' : '0s',

								height: matches2 ? (!modify ? 50 : 590) : '',
							}}
							className={classes.searhform}>
							<OnewayForm id={1} />
						</Box>
					) : (
						''
					)
				) : (
					<Box
						styel={{
							visibility: true ? 'hidden' : 'visible',
							opacity: true ? 0 : 1,
							transition: true
								? ''
								: 'visibility 0s linear 0.33s, opacity 0.33s linear',
							transitionDelay: true ? '' : '0s',
							height: matches2 ? (!modify ? 50 : 590) : '',
						}}
						className={classes.searhform}>
						<OnewayForm id={1} />
					</Box>
				)
			) : (
				<Linear />
			)}
			{/* Oneway Form Ending */}
			<Box
				style={{
					padding: matches ? 10 : 5,
					background: '#e5eef4',
					paddingLeft: 50,
					paddingRight: 50,
				}}>
				<Box>
					{/* <Container> */}
					<Box style={{ padding: matches ? 10 : 20 }}>
						{!ltbo && !letrav ? (
							<></>
						) : (
							<marquee direction='right'>
								<img
									src={'https://marinersforex.com/images/flightrun.png'}
									style={{ width: 100, height: 32, marginTop: 10 }}
								/>
							</marquee>
						)}

						<Grid container spacing={2}>
							<Grid item xs={12} lg={3}>
								{filter ? (
									<Box
										style={{
											padding: 15,
											boxShadow: '0 3px 30px 0 rgb(0 0 0 / 10%)',
											background: 'white',
											borderRadius: 5,
										}}>
										<Box
											display='flex'
											justifyContent='space-between'
											alignItems='center'>
											<Box display='flex' alignItems='center'>
												<i
													class='fa fa-search'
													style={{ margin: 6, fontSize: 20 }}></i>
												<span style={{ fontSize: 15 }}>Filter</span>
											</Box>
											<Box
												onClick={() => {
													filt
														? dispatch(setFilter(false))
														: dispatch(setFilter(true));
												}}>
												{filt ? (
													<ExpandLessIcon style={{ fontSize: '40px' }} />
												) : (
													<ExpandMoreIcon style={{ fontSize: '40px' }} />
												)}
											</Box>
										</Box>

										{matches2 ? (
											true ? (
												<>
													<Box
														style={{
															background: '#E0DEDE',
															padding: 5,
															cursor: 'pointer',
															marginTop: 20,
														}}
														onClick={() =>
															stp ? setStp(false) : setStp(true)
														}>
														<b style={{ fontWeight: 'bold' }}>
															{' '}
															<span
																class='air_down_icon'
																id='icon_dept'
																style={{ fontSize: 12 }}>
																▼
															</span>
															Stops:
														</b>
													</Box>
													{stp ? (
														<>
															{' '}
															<Box
																mt={matches ? '20px' : ''}
																className={classes.radio}
																style={{
																	border: '1px solid lightgrey',
																	padding: '10px',
																	transition: 'all 0.3s ease-in-out',
																}}>
																<FormControlLabel
																	control={
																		<GreenCheckbox
																			checked={stopcheck1}
																			onChange={handleStop}
																			name='1'
																		/>
																	}
																	label='Direct'
																/>
																<br />
																<FormControlLabel
																	control={
																		<GreenCheckbox
																			checked={stopcheck2}
																			onChange={handleStop2}
																			name='2'
																		/>
																	}
																	label='1'
																/>
																<br />
																<FormControlLabel
																	control={
																		<GreenCheckbox
																			checked={stopcheck3}
																			onChange={handleStop3}
																			name='3'
																		/>
																	}
																	label='2 & more'
																/>
															</Box>
														</>
													) : (
														''
													)}

													<Divider style={{ marginTop: 20 }} />
													<Box
														style={{
															background: '#E0DEDE',
															padding: 5,
															cursor: 'pointer',
														}}
														onClick={() =>
															air ? setAir(false) : setAir(true)
														}>
														<b style={{ fontWeight: 'bold' }}>
															{' '}
															<span
																class='air_down_icon'
																id='icon_dept'
																style={{ fontSize: 12 }}>
																▼
															</span>
															Airlines:
														</b>
													</Box>
													{air ? (
														<>
															{' '}
															<Box
																mt={matches ? '20px' : ''}
																className={classes.radio}
																style={{
																	border: '1px solid lightgrey',
																	padding: '10px',
																	transition: 'all 0.3s ease-in-out',
																}}>
																{filterAirlineCombine &&
																	filterAirlineCombine.length > 0 &&
																	filterAirlineCombine.map((i) => {
																		return (
																			<FormControlLabel
																				control={
																					<GreenCheckbox
																						checked={
																							airlineS === i ? true : false
																						}
																						onChange={handleAirline}
																						name={`${i}`}
																					/>
																				}
																				label={
																					<img
																						width='25px'
																						height='25px'
																						src={`https://nitish.musafirbazar.com/static/media/${i}.gif`}
																					/>
																				}
																			/>
																		);
																	})}
															</Box>
														</>
													) : (
														''
													)}

													<Divider style={{ marginTop: 20 }} />
													<Box
														style={{
															background: '#E0DEDE',
															padding: 5,
															cursor: 'pointer',
														}}
														onClick={() =>
															out ? setOut(false) : setOut(true)
														}>
														<b style={{ fontWeight: 'bold' }}>
															{' '}
															<span
																class='air_down_icon'
																id='icon_dept'
																style={{ fontSize: 12 }}>
																▼
															</span>
															Outbound Flight Times:
														</b>
													</Box>
													{out ? (
														<>
															{' '}
															<Box
																mt={matches ? '20px' : ''}
																className={classes.radio}
																style={{
																	border: '1px solid lightgrey',
																	padding: '10px',
																	transition: 'all 0.3s ease-in-out',
																}}>
																<FormControlLabel
																	control={
																		<GreenCheckbox
																			checked={
																				expanded === 'Morning' ? true : false
																			}
																			onChange={handleMorning}
																			name='Morning'
																		/>
																	}
																	label='Morning(04:00-11:00)'
																/>
																<br />
																<FormControlLabel
																	control={
																		<GreenCheckbox
																			checked={
																				expanded === 'Afternoon' ? true : false
																			}
																			onChange={handleMorning}
																			name='Afternoon'
																		/>
																	}
																	label='Afternoon(11:00-16:00)'
																/>
																<br />
																<FormControlLabel
																	control={
																		<GreenCheckbox
																			checked={
																				expanded === 'Evening' ? true : false
																			}
																			onChange={handleMorning}
																			name='Evening'
																		/>
																	}
																	label='Evening(16:00-21:00)'
																/>
																<br />
																<FormControlLabel
																	control={
																		<GreenCheckbox
																			checked={
																				expanded === 'Night' ? true : false
																			}
																			onChange={handleMorning}
																			name='Night'
																		/>
																	}
																	label='Night(21:00-04:00)'
																/>

																<br />
															</Box>
														</>
													) : (
														''
													)}
													<Divider style={{ marginTop: 20 }} />
													<Box
														style={{
															background: '#E0DEDE',
															padding: 5,
															cursor: 'pointer',
														}}
														onClick={() => (rf ? setRf(false) : setRf(true))}>
														<b style={{ fontWeight: 'bold' }}>
															{' '}
															<span
																class='air_down_icon'
																id='icon_dept'
																style={{ fontSize: 12 }}>
																▼
															</span>
															Fare Type
														</b>
													</Box>
													{rf ? (
														<>
															{' '}
															<Box
																mt={matches ? '20px' : ''}
																className={classes.radio}
																style={{
																	border: '1px solid lightgrey',
																	padding: '10px',
																	transition: 'all 0.3s ease-in-out',
																}}>
																<FormControlLabel
																	control={
																		<GreenCheckbox
																			checked={refund}
																			onChange={handleFareR}
																			name='Refundable'
																		/>
																	}
																	label='Refundable'
																/>
																<br />
																<FormControlLabel
																	control={
																		<GreenCheckbox
																			checked={nrefund}
																			onChange={handleFareN}
																			name='Non'
																		/>
																	}
																	label='Non-Refundable'
																/>
															</Box>
														</>
													) : (
														''
													)}
												</>
											) : (
												''
											)
										) : (
											<>
												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
														marginTop: 20,
													}}
													onClick={() => (stp ? setStp(false) : setStp(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 12 }}>
															▼
														</span>
														Stops:
													</b>
												</Box>
												{stp ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={stopcheck1}
																		onChange={handleStop}
																		name='1'
																	/>
																}
																label='Direct'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={stopcheck2}
																		onChange={handleStop2}
																		name='2'
																	/>
																}
																label='1'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={stopcheck3}
																		onChange={handleStop3}
																		name='3'
																	/>
																}
																label='2 & more'
															/>
														</Box>
													</>
												) : (
													''
												)}

												<Divider style={{ marginTop: 20 }} />
												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
													}}
													onClick={() => (air ? setAir(false) : setAir(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 12 }}>
															▼
														</span>
														Airlines:
													</b>
												</Box>
												{air ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															{filterAirlineCombine &&
																filterAirlineCombine.length > 0 &&
																filterAirlineCombine.map((i) => {
																	return (
																		<FormControlLabel
																			control={
																				<GreenCheckbox
																					checked={
																						airlineS === i ? true : false
																					}
																					onChange={handleAirline}
																					name={`${i}`}
																				/>
																			}
																			label={
																				<img
																					width='25px'
																					height='25px'
																					src={`https://nitish.musafirbazar.com/static/media/${i}.gif`}
																				/>
																			}
																		/>
																	);
																})}
														</Box>
													</>
												) : (
													''
												)}

												<Divider style={{ marginTop: 20 }} />
												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
													}}
													onClick={() => (out ? setOut(false) : setOut(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 12 }}>
															▼
														</span>
														Outbound Flight Times:
													</b>
												</Box>
												{out ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={
																			expanded === 'Morning' ? true : false
																		}
																		onChange={handleMorning}
																		name='Morning'
																	/>
																}
																label='Morning(04:00-11:00)'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={
																			expanded === 'Afternoon' ? true : false
																		}
																		onChange={handleMorning}
																		name='Afternoon'
																	/>
																}
																label='Afternoon(11:00-16:00)'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={
																			expanded === 'Evening' ? true : false
																		}
																		onChange={handleMorning}
																		name='Evening'
																	/>
																}
																label='Evening(16:00-21:00)'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={
																			expanded === 'Night' ? true : false
																		}
																		onChange={handleMorning}
																		name='Night'
																	/>
																}
																label='Night(21:00-04:00)'
															/>

															<br />
														</Box>
													</>
												) : (
													''
												)}
												<Divider style={{ marginTop: 20 }} />
												<Box
													style={{
														background: '#E0DEDE',
														padding: 5,
														cursor: 'pointer',
													}}
													onClick={() => (rf ? setRf(false) : setRf(true))}>
													<b style={{ fontWeight: 'bold' }}>
														{' '}
														<span
															class='air_down_icon'
															id='icon_dept'
															style={{ fontSize: 12 }}>
															▼
														</span>
														Fare Type
													</b>
												</Box>
												{rf ? (
													<>
														{' '}
														<Box
															mt={matches ? '20px' : ''}
															className={classes.radio}
															style={{
																border: '1px solid lightgrey',
																padding: '10px',
																transition: 'all 0.3s ease-in-out',
															}}>
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={refund}
																		onChange={handleFareR}
																		name='Refundable'
																	/>
																}
																label='Refundable'
															/>
															<br />
															<FormControlLabel
																control={
																	<GreenCheckbox
																		checked={nrefund}
																		onChange={handleFareN}
																		name='Non'
																	/>
																}
																label='Non-Refundable'
															/>
														</Box>
													</>
												) : (
													''
												)}
											</>
										)}
									</Box>
								) : (
									<></>
								)}
								<Hidden only={['xs']}>
									<Box
										style={{
											padding: 15,
											boxShadow: '0 3px 30px 0 rgb(0 0 0 / 10%)',
											background: 'white',
											borderRadius: 5,
										}}>
										<Box
											display='flex'
											justifyContent='space-between'
											alignItems='center'>
											<Box display='flex' alignItems='center'>
												<i
													class='fa fa-search'
													style={{ margin: 6, fontSize: 20 }}></i>
												<span style={{ fontSize: 15 }}>Filter</span>
											</Box>
										</Box>

										<>
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
													marginTop: 20,
												}}
												onClick={() => (stp ? setStp(false) : setStp(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 12 }}>
														▼
													</span>
													Stops:
												</b>
											</Box>
											{stp ? (
												<>
													{' '}
													<Box
														mt={matches ? '20px' : ''}
														className={classes.radio}
														style={{
															border: '1px solid lightgrey',
															padding: '10px',
															transition: 'all 0.3s ease-in-out',
														}}>
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={stopcheck1}
																	onChange={handleStop}
																	name='1'
																/>
															}
															label='Direct'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={stopcheck2}
																	onChange={handleStop2}
																	name='2'
																/>
															}
															label='1'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={stopcheck3}
																	onChange={handleStop3}
																	name='3'
																/>
															}
															label='2 & more'
														/>
													</Box>
												</>
											) : (
												''
											)}

											<Divider style={{ marginTop: 20 }} />
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
												}}
												onClick={() => (air ? setAir(false) : setAir(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 12 }}>
														▼
													</span>
													Airlines:
												</b>
											</Box>
											{air ? (
												<>
													{' '}
													<Box
														mt={matches ? '20px' : ''}
														className={classes.radio}
														style={{
															border: '1px solid lightgrey',
															padding: '10px',
															transition: 'all 0.3s ease-in-out',
														}}>
														{filterAirlineCombine &&
															filterAirlineCombine.length > 0 &&
															filterAirlineCombine.map((i) => {
																return (
																	<FormControlLabel
																		control={
																			<GreenCheckbox
																				checked={airlineS === i ? true : false}
																				onChange={handleAirline}
																				name={`${i}`}
																			/>
																		}
																		label={
																			<img
																				width='25px'
																				height='25px'
																				src={`https://nitish.musafirbazar.com/static/media/${i}.gif`}
																			/>
																		}
																	/>
																);
															})}
													</Box>
												</>
											) : (
												''
											)}

											<Divider style={{ marginTop: 20 }} />
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
												}}
												onClick={() => (out ? setOut(false) : setOut(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 12 }}>
														▼
													</span>
													Outbound Flight Times:
												</b>
											</Box>
											{out ? (
												<>
													{' '}
													<Box
														mt={matches ? '20px' : ''}
														className={classes.radio}
														style={{
															border: '1px solid lightgrey',
															padding: '10px',
															transition: 'all 0.3s ease-in-out',
														}}>
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={
																		expanded === 'Morning' ? true : false
																	}
																	onChange={handleMorning}
																	name='Morning'
																/>
															}
															label='Morning(04:00-11:00)'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={
																		expanded === 'Afternoon' ? true : false
																	}
																	onChange={handleMorning}
																	name='Afternoon'
																/>
															}
															label='Afternoon(11:00-16:00)'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={
																		expanded === 'Evening' ? true : false
																	}
																	onChange={handleMorning}
																	name='Evening'
																/>
															}
															label='Evening(16:00-21:00)'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={expanded === 'Night' ? true : false}
																	onChange={handleMorning}
																	name='Night'
																/>
															}
															label='Night(21:00-04:00)'
														/>

														<br />
													</Box>
												</>
											) : (
												''
											)}
											<Divider style={{ marginTop: 20 }} />
											<Box
												style={{
													background: '#E0DEDE',
													padding: 5,
													cursor: 'pointer',
												}}
												onClick={() => (rf ? setRf(false) : setRf(true))}>
												<b style={{ fontWeight: 'bold' }}>
													{' '}
													<span
														class='air_down_icon'
														id='icon_dept'
														style={{ fontSize: 12 }}>
														▼
													</span>
													Fare Type
												</b>
											</Box>
											{rf ? (
												<>
													{' '}
													<Box
														mt={matches ? '20px' : ''}
														className={classes.radio}
														style={{
															border: '1px solid lightgrey',
															padding: '10px',
															transition: 'all 0.3s ease-in-out',
														}}>
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={refund}
																	onChange={handleFareR}
																	name='Refundable'
																/>
															}
															label='Refundable'
														/>
														<br />
														<FormControlLabel
															control={
																<GreenCheckbox
																	checked={nrefund}
																	onChange={handleFareN}
																	name='Non'
																/>
															}
															label='Non-Refundable'
														/>
													</Box>
												</>
											) : (
												''
											)}
										</>
									</Box>
								</Hidden>
							</Grid>
							<Grid item xs={12} md={9} lg={9}>
								<Box
									justifyContent='space-between'
									alignItems='center'
									display='flex'>
									<Box alignItems='center' display='flex'>
										{matches ? (
											<h3>{`Flights from ${selectedOrigin.city_name} to ${
												selectedDepart.city_name
											}(${filterData ? filterData.length : 0})`}</h3>
										) : (
											<h3>{`Flights from ${selectedOrigin.city_name} to ${
												selectedDepart.city_name
											}(${filterData ? filterData.length : 0})`}</h3>
										)}
										{filterData?.length > 0 ? (
											ltbo || letrav ? (
												<img src={Loader} style={{ height: 60 }} />
											) : (
												''
											)
										) : (
											''
										)}
									</Box>

									<Hidden only={['sm', 'xs']}>
										<Box>
											{agentlogin ? (
												<Box
													display='flex'
													alignItems='center'
													justifyContent='end'>
													{/* <h5>Net Fare</h5> */}
													<FormControlLabel
														control={
															<GreenCheckbox
																checked={net}
																onChange={handleNetFare}
																name='net'
																style={{ marginLeft: 20 }}
															/>
														}
														// label='SpiceJet'
													/>
												</Box>
											) : (
												''
											)}
											<h4 style={{ marginLeft: 10, margin: 0 }}>
												{`${new Date(
													location.state.body.Segments[0].PreferredDepartureTime
												).toLocaleString('en-us', {
													weekday: 'short',
												})} ,  ${new Date(
													location.state.body.Segments[0].PreferredDepartureTime
												).getDate()} ${new Date(
													location.state.body.Segments[0].PreferredDepartureTime
												).toLocaleString('en-us', {
													month: 'short',
												})}`}
											</h4>
										</Box>
									</Hidden>
								</Box>

								{loading2 ? (
									[0, 1, 2, 3, 4].map((i) => {
										return <SkeletonOneway />;
									})
								) : ltbo && letrav ? (
									[0, 1, 2, 3, 4].map((i) => {
										return <SkeletonOneway />;
									})
								) : rule || (result && result.length > 0) ? (
									result && result.length > 0 ? (
										<InfiniteScroll
											dataLength={scrollValResult}
											next={fetchMoreDataResult}
											hasMore={hasMoreresult}
											loader={<h4>Loading...</h4>}
											endMessage={
												<p style={{ textAlign: 'center' }}>
													<b>No More Flights</b>
												</p>
											}>
											{result.map((item, index) => {
												return index < scrollValResult ? (
													matches ? (
														<OneWayMobileRow
															item={item}
															loading={loading}
															timeConvert={timeConvert}
															location={location}
															net={net}
															onewayflightdata={onewayflightdata}
															onewayflightdataEtrav={onewayflightdataEtrav}
															convertH2M={convertH2M}
															index={index}
														/>
													) : (
														<>
															<OneWayDesktopRow
																item={item}
																loading={loading}
																timeConvert={timeConvert}
																location={location}
																onewayflightdata={onewayflightdata}
																onewayflightdataEtrav={onewayflightdataEtrav}
																convertH2M={convertH2M}
																net={net}
															/>
														</>
													)
												) : (
													''
												);
											})}
										</InfiniteScroll>
									) : (
										<div
											style={{
												padding: '30px 15px',
												background: 'white',
												borderBottom: '1px solid #e6e6e6',
												textAlign: 'center',
											}}>
											<img
												src={
													'https://marinersforex.com/images/flightnotfound.png'
												}></img>
											<p style={{ fontSize: '24px', textAlign: 'center' }}>
												Sorry No Flight Found !!
											</p>
										</div>
									)
								) : filterData && filterData.length > 0 ? (
									<InfiniteScroll
										dataLength={scrollVal}
										next={fetchMoreData}
										hasMore={hasMorefilterData}
										loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
										endMessage={
											<p style={{ textAlign: 'center' }}>
												<b>No More Flights</b>
											</p>
										}>
										{filterData.map((item, index) => {
											return index < scrollVal ? (
												matches ? (
													<OneWayMobileRow
														item={item}
														loading={loading}
														timeConvert={timeConvert}
														location={location}
														onewayflightdata={onewayflightdata}
														onewayflightdataEtrav={onewayflightdataEtrav}
														convertH2M={convertH2M}
														net={net}
													/>
												) : (
													<OneWayDesktopRow
														item={item}
														loading={loading}
														timeConvert={timeConvert}
														location={location}
														onewayflightdata={onewayflightdata}
														onewayflightdataEtrav={onewayflightdataEtrav}
														convertH2M={convertH2M}
														net={net}
													/>
												)
											) : (
												''
											);
										})}
									</InfiniteScroll>
								) : ltbo === false && letrav === false ? (
									<div
										style={{
											padding: '30px 15px',
											background: 'white',
											borderBottom: '1px solid #e6e6e6',
											textAlign: 'center',
										}}>
										<img
											src={
												'https://marinersforex.com/images/flightnotfound.png'
											}></img>
										<p style={{ fontSize: '24px', textAlign: 'center' }}>
											Sorry No Flight Found !!
										</p>
									</div>
								) : (
									[0, 1, 2, 3, 4].map((i) => {
										return <SkeletonOneway />;
									})
								)}
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Box>
			<FlightDetailModal />
			<Login />
		</>
	);
};

export default OneWay;
