import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@material-ui/core';
import {
	hotelInfoDispatch,
	hotelRoomInfoDispatch,
} from '../../reducers/HotelReducer';
import './searchItem.css';

const SearchItem = ({ item }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { hotelresult, orderID } = useSelector((state) => state.hotel);
	const { room, adultH, childH, childAge1, childAge2, childAge3, childAge4 } =
		useSelector((state) => state.ui);
	function checkIfImageExists(url, callback) {
		const img = new Image();
		img.src = url;

		if (img.complete) {
			return true;
		} else {
			img.onload = () => {
				return true;
			};

			img.onerror = () => {
				return false;
			};
		}
	}
	// checkIfImageExists(
	// 	'https://fastui.cltpstatic.com/image/upload/hotels/places/hotelscms/3986/3986076/images/image_2996611_original_tn.jpg',
	// 	(exists) => {
	// 		if (exists) {
	// 			console.log('Image exists. ');
	// 		} else {
	// 			console.error('Image does not exists.');
	// 		}
	// 	}
	// );
	// checkImage(
	// 	'https://fastui.cltpstatic.com/image/upload/hotels/places/hotelscms/3986/3986076/images/image_2996611_original_tn.jpg'
	// );
	return (
		<div className='searchItem' style={{ display: matches ? '' : 'flex' }}>
			<img
				// src={
				// 	checkIfImageExists(item.HotelPicture)
				// 		? item.HotelPicture
				// 		: 'https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png'
				// }
				src={item.HotelPicture}
				alt=''
				className='siImg'
				// onError="this.src='https://upload.wikimedia.org/wikipedia/commons/d/d1/Image_not_available.png"
			/>
			<div className='siDesc'>
				<h1 className='siTitle'>{item.HotelName}</h1>
				<span className='siDistance'>{item.HotelAddress}</span>
				{/* <span className="siTaxiOp">Free airport taxi</span> */}
				<span
					className='siSubtitle'
					style={{
						width: '50ch',
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
					}}>
					{item.HotelDescription}
				</span>
				<span className='siFeatures'>
					{/* Entire studio • 1 bathroom • 21m² 1 full bed */}
				</span>
				{/* <span className='siCancelOp'>Free cancellation </span> */}
				<span className='siCancelOpSubtitle'>
					You can cancel later, so lock in this great price today!
				</span>
			</div>
			<div className='siDetails'>
				<div className='siRating'>
					<span>Excellent</span>
					<button>{`${item.StarRating}/5`}</button>
				</div>
				<div className='siDetailTexts'>
					<span className='siPrice' style={{ textDecoration: 'line-through' }}>
						₹{Number(Math.round(Number(item.Total_Amount)))}
					</span>
					<span className='siPrice'>
						₹
						{Number(
							Math.round(Number(item.Total_Amount) - Number(item.MFB_Discount))
						)}
					</span>
					<span className='siTaxOp'>Includes taxes and fees</span>
					<button
						className='siCheckButton'
						onClick={() => {
							let form = {
								resultIndex: item.ResultIndex,
								hotelcode: item.HotelCode,
								traceId: hotelresult.result.HotelSearchResult.TraceId,
							};
							history.push({
								pathname: `/hotellist/${item.HotelName}`,
								state: {
									formdata: form,
									selectedHotel: item,
									room: room,
									orderID: orderID.result,
									traceId: hotelresult.result.HotelSearchResult.TraceId,
									adultH: adultH,
									childH: childH,
									childAge1: childAge1,
									childAge2: childAge2,
									childAge3: childAge3,
									childAge4: childAge4,
									hotelformData: location.state.hotelformData,
								},
							});
						}}>
						See availability
					</button>
				</div>
			</div>
		</div>
	);
};

export default SearchItem;
