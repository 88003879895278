import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BodyText from './BodyText';
import { makeStyles, Container } from '@material-ui/core';
import '../../main.css';
import LoadingButton from '@mui/lab/LoadingButton';
import PrintIcon from '@mui/icons-material/Print';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import { useHistory } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useLocation } from 'react-router-dom';
import HeaderSection from './HeaderSection';
import { setEditPrice, toggleEditTicketModal } from '../../reducers/UiReducer';
import EditTicketPriceModal from './EditTicketPriceModal';
import moment from 'moment';
import {
	FlightMailTicketDispatch,
	fetchlogo,
} from '../../reducers/HomeReducer';
import FlightDetailRowTBO from './FlightDetailRowTBO';
const useStyles = makeStyles((theme) => ({
	table: {
		background: '#fff',
		margin: '0px auto',
		width: '850px !important',
		padding: '30px 20px',
		webkitPrintColorAdjust: 'exact',
	},
	table2: {
		boxShadow: '1px 1px 15px rgba(0, 0, 0, 0.1)',
		background: '#fff',
		borderSpacing: 0,
		padding: 0,
		margin: 0,
	},
	table3: {
		width: '100%',
		padding: 0,
		margin: 0,
	},
	row: {
		width: '60%',
		padding: '15px 40px',
	},
	row2: {
		width: '50%',
		border: '1px solid #333',
		borderLeft: 0,
		padding: '8px',
	},
	row3: {
		width: '50%',
		borderTop: '1px solid #333',
		borderBottom: '1px solid #333',
		padding: '5px',
	},
}));
const TicketDisplay = () => {
	const { ticket } = useSelector((state) => state.home);
	const { editprice } = useSelector((state) => state.ui);
	const { bookingDetail } = useSelector((state) => state.tripjack);
	const { etrav } = useSelector((state) => state);
	console.log('ticket', ticket);
	const location = useLocation();
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { agentlogin, agentlogoget } = useSelector((state) => state.user);
	const [toggle, setToggle] = useState(false);
	const { logo } = useSelector((state) => state.home);
	console.log('Location', location);
	// const bookingDet =
	// 	location?.state?.bookingDetails === ''
	// 		? JSON.parse(location?.state?.pricingDetails)
	// 		: JSON.parse(location?.state?.bookingDetails);
	// console.log('bookingDetails', JSON.parse(location.state.bookingDetails));
	console.log('selectedFlight', JSON.parse(location.state.selectedFlight));
	console.log('selectedPax', JSON.parse(location.state.selectedPax));
	console.log('pricingDetails', JSON.parse(location.state.pricingDetails));
	const handleClick = () => {
		let formMail = {
			orderId: location.state.orderId,
			userEmail: location.state.userEmail,
		};
		dispatch(FlightMailTicketDispatch(formMail));
		window.alert('Ticket Send Successfully');
	};
	useEffect(() => {
		let form = {};
		dispatch(fetchlogo(form));
	}, []);
	return (
		<>
			<HeaderSection id={1} />
			<Container style={{ marginTop: 100 }}>
				<LoadingButton
					id='back'
					onClick={() => {
						history.push('/dashboard');
						dispatch(setEditPrice(null));
					}}
					color='primary'
					loadingPosition='start'
					startIcon={<ArrowBackIosNewIcon />}
					variant='contained'>
					Back
				</LoadingButton>
			</Container>

			<table className={classes.table}>
				<tbody>
					<tr>
						<td>
							<table className={classes.table2}>
								<tbody>
									<tr>
										<td>
											<table className={classes.table3}>
												<tbody>
													<tr id={toggle ? 'header' : ''}>
														<td className={classes.row}>
															<img
																src={logo?.result[0]?.logo}
																style={{
																	maxHeight: 50,
																	maxWidth: 150,
																}}></img>
														</td>
														<td style={{ width: '40%' }}>
															<BodyText
																textStyle={{
																	fontWeight: 600,
																	marginTop: 5,
																}}>
																{agentlogin &&
																	agentlogin.result.result.agencyName}
															</BodyText>
															<p
																style={{
																	fontWeight: 400,
																	fontSize: 14,
																	color: '#777',
																}}>
																Email:{' '}
																{agentlogin && agentlogin.result.result.email}
															</p>
															<p
																style={{
																	fontWeight: 400,
																	fontSize: 14,
																	color: '#777',
																}}>
																Phone: +91{' '}
																{agentlogin && agentlogin.result.result.phone}
															</p>
															{/* <p
																style={{
																	fontWeight: 400,
																	fontSize: 14,
																	color: '#777',
																}}>
																Address: 141st Floor,New Tech Grand, Bank More,
																Dhanbad-826001
															</p> */}
														</td>
													</tr>
												</tbody>
											</table>
											<table className={classes.table3}>
												<tbody>
													<tr>
														<td className={classes.row2}>
															<p
																style={{
																	marginBottom: '5px',
																	color: '#777',
																}}>
																<span
																	style={{
																		fontWeight: 400,
																		fontSize: 14,
																	}}>
																	Booking Time:{' '}
																	{moment(
																		location.state.dob.split(' ')[0]
																	).format('DD-MM-YYYY')}
																</span>
															</p>
															<p
																style={{
																	marginBottom: '5px',
																	color: '#777',
																}}>
																<span
																	style={{
																		fontWeight: 400,
																		fontSize: 14,
																	}}>
																	Booking ID:{''}
																	{location.state.orderId}
																</span>
															</p>
															<p
																style={{
																	marginBottom: '5px',
																	color: '#777',
																}}>
																<span
																	style={{
																		fontWeight: 400,
																		fontSize: 14,
																	}}>
																	Booking Status:
																</span>
																<span
																	style={{
																		fontWeight: 'bold',
																		fontSize: 14,
																		color: 'black',
																	}}>
																	{' '}
																	{location.state.bookingStatus}
																</span>
															</p>
														</td>
														<td className={classes.row3}>
															<br></br>
															<table
																style={{
																	width: '100%',
																	marginBottom: '15px',
																}}>
																<tbody>
																	<tr>
																		<td style={{ width: 40 }}>
																			<p>
																				<span style={{ fontWeight: 400 }}>
																					{location.state.flightType ===
																						'DOM' &&
																					location.state.journeyType.toLowerCase() ===
																						'roundtrip' ? (
																						<>
																							<img
																								src={`https://nitish.musafirbazar.com/static/media/${
																									location.state.carrier.split(
																										'|'
																									)[0]
																								}.gif`}
																								style={{
																									marginRight: 5,
																									verticalAlign: 'middle',
																									width: 50,
																									height: 50,
																								}}></img>
																							<img
																								src={`https://nitish.musafirbazar.com/static/media/${
																									location.state.carrier.split(
																										'|'
																									)[1]
																								}.gif`}
																								style={{
																									marginRight: 5,
																									verticalAlign: 'middle',
																									width: 50,
																									height: 50,
																									marginTop: 5,
																								}}></img>
																						</>
																					) : (
																						<>
																							<img
																								src={`https://nitish.musafirbazar.com/static/media/${location.state.carrier}.gif`}
																								style={{
																									marginRight: 5,
																									verticalAlign: 'middle',
																									width: 50,
																									height: 50,
																								}}></img>
																						</>
																					)}
																				</span>
																			</p>
																		</td>
																		<td style={{ width: 40 }}>
																			<p
																				style={{
																					fontWeight: 400,
																					fontSize: 12,
																				}}>
																				{/* {ticket
																					? ticket &&
																					  ticket.result &&
																					  ticket.result.Response &&
																					  ticket.result.Response.Response &&
																					  ticket.result.Response.Response
																							.FlightItinerary.Segments[0]
																							.Airline.AirlineName
																					: bookingDetail
																					? bookingDetail.result.itemInfos.AIR
																							.tripInfos[0].sI[0].fD.aI.name
																					: ''} */}
																			</p>
																		</td>
																		<td style={{ textAlign: 'center' }}>
																			<p
																				id='pnr'
																				style={{
																					fontWeight: 'bold',
																					fontSize: 20,
																				}}>
																				<strong>
																					{location?.state?.bookingRefrence}
																				</strong>
																			</p>
																			<p>
																				<span
																					style={{
																						fontWeight: 400,
																						fontSize: 12,
																						color: '#777',
																					}}>
																					PNR
																				</span>
																			</p>
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>

											<table className={classes.table3}>
												<tbody>
													<tr>
														<td style={{ width: '30%' }}>
															<p
																style={{
																	background: '#000',
																	padding: '5px',
																	color: '#fff',
																	paddingLeft: 20,
																	webkitPrintColorAdjust: 'exact',
																}}>
																<span
																	style={{
																		fontWeight: 400,
																		fontSize: 14,
																	}}>
																	Flight Detail
																</span>
															</p>
														</td>
														<td style={{ width: '70%' }} align='right'>
															<p
																style={{
																	background: '#000',
																	padding: 5,
																	color: '#fff',
																	webkitPrintColorAdjust: 'exact',
																}}>
																<em>
																	<span
																		style={{
																			fontWeight: 400,
																			fontSize: 14,
																		}}>
																		*Please verify flight timings & terminal
																		info with the airlines
																	</span>
																</em>
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<table
												style={{
													width: '100%',
													lineHeight: 'normal',
													padding: 0,
													margin: 0,
												}}>
												<tbody>
													<tr>
														<td style={{ width: '20%' }}>
															<p
																style={{
																	background: '#BCBEC0',
																	color: '#000',
																	padding: '3px',
																	paddingLeft: '20px',
																	fontSize: 12,
																}}>
																<span style={{ fontWeight: 400 }}>FLight</span>
															</p>
														</td>
														<td style={{ width: '10%' }}>
															<p
																style={{
																	background: '#BCBEC0',
																	color: '#000',
																	padding: '3px',
																	paddingLeft: '20px',
																	fontSize: 12,
																}}>
																<span style={{ fontWeight: 400 }}>Class</span>
															</p>
														</td>
														<td style={{ width: '10%' }}>
															<p
																style={{
																	background: '#BCBEC0',
																	color: '#000',
																	padding: '3px',
																	paddingLeft: '20px',
																	fontSize: 12,
																}}>
																<span style={{ fontWeight: 400 }}>Stop</span>
															</p>
														</td>
														<td style={{ width: '20%' }}>
															<p
																style={{
																	background: '#BCBEC0',
																	color: '#000',
																	padding: '3px',
																	paddingLeft: '20px',
																	fontSize: 12,
																}}>
																<span style={{ fontWeight: 400 }}>
																	Departing
																</span>
															</p>
														</td>
														<td style={{ width: '20%' }}>
															<p
																style={{
																	background: '#BCBEC0',
																	color: '#000',
																	padding: '3px',
																	paddingLeft: '20px',
																	fontSize: 12,
																}}>
																<span style={{ fontWeight: 400 }}>
																	Arriving
																</span>
															</p>
														</td>
														<td style={{ width: '10%' }}>
															<p
																style={{
																	background: '#BCBEC0',
																	color: '#000',
																	padding: '3px',
																	paddingLeft: '20px',
																	fontSize: 12,
																}}>
																<span style={{ fontWeight: 400 }}>
																	Duration
																</span>
															</p>
														</td>
														<td
															style={{
																borderBottom: '1px solid #333',
																background: '#BCBEC0',
																fontSize: 14,
																webkitPrintColorAdjust: 'exact',
															}}>
															Baggage
														</td>
													</tr>
												</tbody>
											</table>
											{/* Flight Detail Table Start */}

											<table
												style={{
													borderBottom: '1px solid #cfcbcb',
													width: '100%',
												}}>
												<tbody>
													{location.state.journeyType.toLowerCase() ===
													'oneway' ? (
														<>
															{location.state.supplier === 'TBO' &&
																location?.state?.selectedFlight &&
																JSON.parse(
																	location?.state?.selectedFlight
																)?.Segments[0]?.map((item, index) => {
																	return <FlightDetailRowTBO item={item} />;
																})}
															{location.state.supplier === 'TBO' &&
																location?.state?.selectedFlight &&
																JSON.parse(
																	location?.state?.selectedFlight
																)?.Segments[1]?.map((item, index) => {
																	return <FlightDetailRowTBO item={item} />;
																})}
															{location.state.supplier === 'ETR' &&
																location?.state?.selectedFlight &&
																JSON.parse(
																	location?.state?.selectedFlight
																)?.Segments?.map((item, index) => {
																	return (
																		<tr>
																			<td
																				width='20%'
																				style={{
																					padding: 2,
																					fontSize: 12,
																					paddingLeft: 20,
																				}}>
																				<p>
																					<strong style={{ color: '#000' }}>
																						{item.Airline_Code}-
																						{item.Flight_Number}
																					</strong>
																				</p>
																				<p>
																					<span style={{ fontWeight: 400 }}>
																						{item.Airline_Name}
																					</span>
																				</p>
																			</td>
																			<td
																				width='10%'
																				style={{
																					padding: 2,
																					fontSize: 12,
																				}}>
																				<p>
																					<span style={{ fontWeight: 400 }}>
																						{
																							JSON.parse(
																								location?.state?.selectedFlight
																							)?.Fares.FareDetails[0]
																								?.FareClasses[0]?.Class_Code
																						}
																					</span>
																				</p>
																			</td>
																			<td width='10%'>
																				<p style={{ fontSize: 12 }}>
																					<span style={{ fontWeight: 400 }}>
																						Non Stop
																					</span>
																				</p>
																			</td>
																			<td width='20%'>
																				<p style={{ fontSize: 12 }}>
																					<strong color='#000'>
																						{`${moment(
																							item.Departure_DateTime
																						).format('DD-MM-YYYY')} ${
																							item.Departure_DateTime.split(
																								' '
																							)[1]
																						}`}
																					</strong>
																				</p>
																				<p style={{ fontSize: 12 }}>
																					<span style={{ fontWeight: 400 }}>
																						{item.Origin}
																					</span>
																				</p>
																			</td>
																			<td width='20%'>
																				<p style={{ fontSize: 12 }}>
																					<strong color='#000'>
																						{`${moment(
																							item.Arrival_DateTime
																						).format('DD-MM-YYYY')} ${
																							item.Arrival_DateTime.split(
																								' '
																							)[1]
																						} `}
																					</strong>
																				</p>
																				<p style={{ fontSize: 12 }}>
																					<span style={{ fontWeight: 400 }}>
																						{item.Destination}
																					</span>
																				</p>
																			</td>
																			<td width='10%'>
																				<p style={{ fontSize: 12 }}>
																					<strong color='#000'>{`${item.Duration} min`}</strong>
																				</p>
																			</td>
																			<td width='10%'>
																				<p style={{ fontSize: 12 }}>
																					<strong color='#000'>
																						{`${
																							JSON.parse(
																								location?.state?.selectedFlight
																							)?.Fares?.FareDetails[0]
																								?.Free_Baggage?.Check_In_Baggage
																						} || ${
																							JSON.parse(
																								location?.state?.selectedFlight
																							)?.Fares?.FareDetails[0]
																								?.Free_Baggage?.Hand_Baggage
																						}`}
																					</strong>
																				</p>
																			</td>
																		</tr>
																	);
																})}
															{location.state.supplier === 'TJK' &&
																JSON.parse(
																	location?.state?.selectedFlight
																)?.sI.map((item, index) => {
																	return (
																		<tr>
																			<td
																				width='20%'
																				style={{
																					padding: 2,
																					fontSize: 12,
																					paddingLeft: 20,
																				}}>
																				<p>
																					<strong style={{ color: '#000' }}>
																						{item.fD.aI.code}-{item.fD.fN}
																					</strong>
																				</p>
																				<p>
																					<span style={{ fontWeight: 400 }}>
																						{item.fD.aI.name}
																					</span>
																				</p>
																			</td>
																			<td
																				width='10%'
																				style={{
																					padding: 2,
																					fontSize: 12,
																				}}>
																				<p>
																					<span style={{ fontWeight: 400 }}>
																						{/* {} */}
																					</span>
																				</p>
																			</td>
																			<td width='10%'>
																				<p style={{ fontSize: 12 }}>
																					<span style={{ fontWeight: 400 }}>
																						Non Stop
																					</span>
																				</p>
																			</td>
																			<td width='20%'>
																				<p style={{ fontSize: 12 }}>
																					<strong color='#000'>
																						{item.dt}
																					</strong>
																				</p>
																				<p style={{ fontSize: 12 }}>
																					<span style={{ fontWeight: 400 }}>
																						{item.da.code}
																					</span>
																				</p>
																			</td>
																			<td width='20%'>
																				<p style={{ fontSize: 12 }}>
																					<strong color='#000'>
																						{item.at}
																					</strong>
																				</p>
																				<p style={{ fontSize: 12 }}>
																					<span style={{ fontWeight: 400 }}>
																						{item.aa.code}
																					</span>
																				</p>
																			</td>
																			<td width='10%'>
																				<p style={{ fontSize: 12 }}>
																					<strong color='#000'>
																						{`${item.duration} min`}
																					</strong>
																				</p>
																			</td>
																		</tr>
																	);
																})}
														</>
													) : (
														<>
															{location.state.flightType === 'INT' && (
																<>
																	{location.state.supplier === 'TBO' &&
																		location?.state?.selectedFlight &&
																		location?.state?.selectedFlight &&
																		JSON.parse(
																			location?.state?.selectedFlight
																		)?.Segments[0]?.map((item, index) => {
																			return <FlightDetailRowTBO item={item} />;
																		})}
																	{location.state.supplier === 'TBO' &&
																		location?.state?.selectedFlight &&
																		location?.state?.selectedFlight &&
																		JSON.parse(
																			location?.state?.selectedFlight
																		)?.Segments[1]?.map((item, index) => {
																			return <FlightDetailRowTBO item={item} />;
																		})}
																</>
															)}
															{location.state.flightType === 'DOM' && (
																<>
																	{location.state.supplier === 'TBO' &&
																		location?.state?.selectedFlight &&
																		location?.state?.selectedFlight &&
																		JSON.parse(
																			location?.state?.selectedFlight
																		)?.[0]?.Segments[0].map((item, index) => {
																			return <FlightDetailRowTBO item={item} />;
																		})}
																	{location.state.supplier === 'TBO' &&
																		location?.state?.selectedFlight &&
																		location?.state?.selectedFlight &&
																		JSON.parse(
																			location?.state?.selectedFlight
																		)?.[1]?.Segments[0].map((item, index) => {
																			return <FlightDetailRowTBO item={item} />;
																		})}
																</>
															)}
														</>
													)}
												</tbody>
											</table>
											{/* Flight Detail Table End */}
											<table style={{ marginTop: 12, width: '100%' }}>
												<tbody>
													<tr>
														<td width='100%'>
															<p
																style={{
																	background: '#000',
																	color: '#fff',
																	padding: '5px',
																	paddingLeft: '20px',
																	fontSize: 14,
																	webkitPrintColorAdjust: 'exact',
																}}>
																Passenger Details
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<table
												style={{
													color: '#000',
													width: '100%',
													verticalAlign: 'middle',
												}}>
												<tbody>
													<tr>
														<td
															style={{
																borderBottom: '1px solid #333',
																background: '#BCBEC0',
																webkitPrintColorAdjust: 'exact',
															}}>
															<p
																style={{
																	color: '#000',
																	padding: '3px',
																	fontSize: 14,
																}}>
																Sr.
															</p>
														</td>
														<td
															style={{
																borderBottom: '1px solid #333',
																background: '#BCBEC0',
																webkitPrintColorAdjust: 'exact',
															}}>
															<p
																style={{
																	color: '#000',
																	padding: '3px',
																	fontSize: 14,
																}}>
																Name
															</p>
														</td>
														<td
															style={{
																borderBottom: '1px solid #333',
																background: '#BCBEC0',
																webkitPrintColorAdjust: 'exact',
															}}>
															<p
																style={{
																	color: '#000',
																	padding: '3px',
																	fontSize: 14,
																}}>
																Type
															</p>
														</td>
														<td
															style={{
																borderBottom: '1px solid #333',
																background: '#BCBEC0',
																webkitPrintColorAdjust: 'exact',
															}}>
															<p
																style={{
																	color: '#000',
																	padding: '3px',
																	fontSize: 14,
																}}>
																PNR & Ticket No.
															</p>
														</td>
														{/* <td
															style={{
																borderBottom: '1px solid #333',
																background: '#BCBEC0',
																webkitPrintColorAdjust: 'exact',
															}}>
															<p
																style={{
																	color: '#000',
																	padding: '3px',
																	fontSize: 14,
																}}>
																Baggage
															</p>
														</td> */}
													</tr>
													{/* Passenger Details Table Start */}
													{location.state.supplier === 'TBO' &&
													location?.state?.bookingDetails
														? JSON.parse(
																location?.state?.bookingDetails
														  ).Response.Response.FlightItinerary.Passenger.map(
																(item, index) => {
																	return (
																		<tr>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					borderLeft: '1px solid #333',
																					padding: 2,
																				}}>
																				<p style={{ fontSize: 12 }}>
																					{index + 1}
																				</p>
																			</td>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p style={{ fontSize: 12 }}>
																					{''}
																					{`${item.Title} ${item.FirstName} ${item.LastName}`}
																				</p>
																			</td>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p
																					style={{
																						fontSize: 12,
																						fontWeight: 'bold',
																					}}>
																					{item.PaxType === 1
																						? 'Adult'
																						: item.PaxType === 2
																						? 'Child'
																						: item.PaxType === 3
																						? 'Infant'
																						: ''}
																				</p>
																			</td>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p
																					style={{
																						fontSize: 12,
																						fontWeight: 'bold',
																					}}>
																					{`${
																						location?.state?.bookingDetails
																							? JSON.parse(
																									location?.state
																										?.bookingDetails
																							  )?.Response?.Response
																									.FlightItinerary.PNR
																							: ''
																					} (${item?.Ticket?.TicketNumber})`}
																				</p>
																			</td>
																			{/* <td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p
																					style={{
																						fontSize: 12,
																						fontWeight: 'bold',
																					}}>
																					{item?.SegmentAdditionalInfo
																						? item?.SegmentAdditionalInfo[0]
																								?.Baggage
																						: ''}
																				</p>
																			</td> */}
																		</tr>
																	);
																}
														  )
														: location.state.supplier === 'TBO' &&
														  location.state.selectedPax &&
														  JSON.parse(location?.state?.selectedPax)?.map(
																(item, index) => {
																	return (
																		<tr>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					borderLeft: '1px solid #333',
																					padding: 2,
																				}}>
																				<p style={{ fontSize: 12 }}>
																					{index + 1}
																				</p>
																			</td>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p style={{ fontSize: 12 }}>
																					{''}
																					{`${item.title} ${item.firstname} ${item.lastname}`}
																				</p>
																			</td>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p
																					style={{
																						fontSize: 12,
																						fontWeight: 'bold',
																					}}>
																					{item.PaxType === 1
																						? 'Adult'
																						: item.PaxType === 2
																						? 'Child'
																						: item.PaxType === 3
																						? 'Infant'
																						: ''}
																				</p>
																			</td>
																			<td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p
																					style={{
																						fontSize: 12,
																						fontWeight: 'bold',
																					}}>
																					{`${location.state.bookingRefrence} ${
																						item?.ticketNumber
																							? item?.ticketNumber
																							: ''
																					} `}
																				</p>
																			</td>
																			{/* <td
																				style={{
																					borderBottom: '1px solid #333',
																					padding: 2,
																				}}>
																				<p
																					style={{
																						fontSize: 12,
																						fontWeight: 'bold',
																					}}>
																					{location.state.supplier === 'TBO' &&
																					JSON.parse(
																						location.state.selectedFlight
																					)
																						? item.PaxType === 3
																							? ''
																							: `${JSON.parse(
																									location.state.selectedFlight
																							  )
																									.Segments[0].filter(
																										(i, index) => index === 0
																									)
																									.map(
																										(item) => item.Baggage
																									)} | ${JSON.parse(
																									location.state.selectedFlight
																							  )
																									.Segments[0].filter(
																										(i, index) => index === 0
																									)
																									.map(
																										(item) => item.CabinBaggage
																									)}`
																						: ''}
																				</p>
																			</td> */}
																		</tr>
																	);
																}
														  )}
													{location.state.supplier === 'ETR' &&
														JSON.parse(location?.state?.selectedPax)?.map(
															(item, index) => {
																return (
																	<tr>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				borderLeft: '1px solid #333',
																				padding: 2,
																			}}>
																			<p style={{ fontSize: 12 }}>
																				{index + 1}
																			</p>
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			<p style={{ fontSize: 12 }}>
																				{''}
																				{`${item.title} ${item.firstname} ${item.lastname}`}
																			</p>
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			<p
																				style={{
																					fontSize: 12,
																					fontWeight: 'bold',
																				}}>
																				{item.PaxType === 1
																					? 'Adult'
																					: item.PaxType === 2
																					? 'Child'
																					: item.PaxType === 3
																					? 'Infant'
																					: ''}
																			</p>
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			<p
																				style={{
																					fontSize: 12,
																					fontWeight: 'bold',
																				}}>
																				{location.state.bookingRefrence}
																			</p>
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			<p
																				style={{
																					fontSize: 12,
																					fontWeight: 'bold',
																				}}>
																				{item.PaxType === 3
																					? `${
																							JSON.parse(
																								location.state.selectedFlight
																							).Fares?.FareDetails[0]
																								?.Free_Baggage?.Hand_Baggage
																					  } | ${
																							JSON.parse(
																								location.state.selectedFlight
																							).Fares?.FareDetails[0]
																								?.Free_Baggage?.Check_In_Baggage
																					  }`
																					: ''}
																				{/* {etrav &&
																					etrav.reprint &&
																					etrav.reprint.result &&
																					etrav.reprint.result.AirPNRDetails[0]
																						.Flights[0].Fares[0].FareDetails[0]
																						.Free_Baggage.Check_In_Baggage} */}
																			</p>
																		</td>
																	</tr>
																);
															}
														)}
													{bookingDetail &&
														bookingDetail?.result &&
														bookingDetail?.result?.itemInfos?.AIR
															?.travellerInfos &&
														bookingDetail?.result?.itemInfos?.AIR?.travellerInfos?.map(
															(item, index) => {
																return (
																	<tr>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				borderLeft: '1px solid #333',
																				padding: 2,
																			}}>
																			<p style={{ fontSize: 12 }}>
																				{index + 1}
																			</p>
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			<p style={{ fontSize: 12 }}>
																				{''}
																				{`${item.ti} ${item.fN} ${item.lN}`}
																			</p>
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			<p
																				style={{
																					fontSize: 12,
																					fontWeight: 'bold',
																				}}>
																				{item.pt}
																			</p>
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			{location?.state?.bookingRefrence}
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			<p
																				style={{
																					fontSize: 12,
																					fontWeight: 'bold',
																				}}>
																				{`${
																					location.state.supplier === 'TJK' &&
																					JSON.parse(
																						location?.state?.selectedFlight
																					)?.totalPriceList.fd.ADULT.bI.cB
																				} | ${
																					location.state.supplier === 'TJK' &&
																					JSON.parse(
																						location?.state?.selectedFlight
																					)?.totalPriceList.fd.ADULT.bI.iB
																				}`}
																			</p>
																		</td>
																	</tr>
																);
															}
														)}
													{location.state.supplier === 'TJK' &&
														JSON.parse(location?.state?.selectedPax)?.map(
															(item, index) => {
																return (
																	<tr>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				borderLeft: '1px solid #333',
																				padding: 2,
																			}}>
																			<p style={{ fontSize: 12 }}>
																				{index + 1}
																			</p>
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			<p style={{ fontSize: 12 }}>
																				{''}
																				{`${item.title} ${item.firstname} ${item.lastname}`}
																			</p>
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			<p
																				style={{
																					fontSize: 12,
																					fontWeight: 'bold',
																				}}>
																				{item.PaxType === 1
																					? 'Adult'
																					: item.PaxType === 2
																					? 'Child'
																					: item.PaxType === 3
																					? 'Infant'
																					: ''}
																			</p>
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			<p
																				style={{
																					fontSize: 12,
																					fontWeight: 'bold',
																				}}>
																				{location.state.bookingRefrence}
																			</p>
																		</td>
																		<td
																			style={{
																				borderBottom: '1px solid #333',
																				padding: 2,
																			}}>
																			<p
																				style={{
																					fontSize: 12,
																					fontWeight: 'bold',
																				}}>
																				{item.PaxType === 1
																					? `${
																							location.state.supplier ===
																								'TJK' &&
																							JSON.parse(
																								location?.state?.selectedFlight
																							)?.totalPriceList.fd.ADULT.bI.cB
																					  } | ${
																							location.state.supplier ===
																								'TJK' &&
																							JSON.parse(
																								location?.state?.selectedFlight
																							)?.totalPriceList.fd.ADULT.bI.iB
																					  }`
																					: ''}
																			</p>
																		</td>
																	</tr>
																);
															}
														)}
													{/* Passenger Details Table End */}
												</tbody>
											</table>
											<table style={{ marginTop: '12px', width: '100%' }}>
												<tbody>
													<tr>
														<td>
															<p
																style={{
																	background: '#000',
																	color: '#fff',
																	padding: '5px',
																	paddingLeft: 20,
																	fontSize: 12,
																	webkitPrintColorAdjust: 'exact',
																}}>
																Contact Details
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<table
												style={{
													border: '1px solid #333',
													width: '100%',
												}}>
												<tbody>
													<tr>
														<td
															style={{
																padding: 3,
																fontSize: 12,
																color: '#000',
																paddingLeft: '20px',
															}}>
															Phone: +91 {location.state.userPhone}
														</td>
														<td
															style={{
																padding: 3,
																// fontWeight: "bold",
															}}>
															<p>Email: {location.state.userEmail}</p>
														</td>
													</tr>
												</tbody>
											</table>

											<table style={{ marginTop: '12px', width: '100%' }}>
												<tbody>
													<tr>
														<td>
															<p
																style={{
																	background: '#000',
																	color: '#fff',
																	padding: '5px',
																	paddingLeft: 20,
																	fontSize: 12,
																	webkitPrintColorAdjust: 'exact',
																}}>
																Fare Details
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<table
												style={{
													border: '1px solid #333',
													width: '100%',
												}}>
												<tbody>
													<tr>
														<td
															style={{
																padding: 3,
																fontSize: 12,
																color: '#000',
																paddingLeft: '20px',
															}}>
															Total Price
														</td>
														<td
															style={{
																padding: 3,
																fontWeight: 'bold',
															}}>
															<p>
																{editprice === 0
																	? ''
																	: editprice
																	? `INR ${editprice}`
																	: `INR ${location.state.ticketCost}`}
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<table style={{ marginTop: 12, width: '100%' }}>
												<tbody>
													<tr>
														<td>
															<p
																style={{
																	background: '#000',
																	color: '#fff',
																	padding: '5px',
																	paddingLeft: '20px',
																	fontSize: 12,
																	webkitPrintColorAdjust: 'exact',
																}}>
																Important Information
															</p>
														</td>
													</tr>
												</tbody>
											</table>
											<table
												style={{
													width: '100%',
													marginBottom: '40px',
													paddingTop: '15px',
												}}>
												<tbody>
													<tr>
														<td
															style={{
																padding: '0 8px',
																fontSize: 13,
																color: '#000',
																width: '100%',
															}}>
															1 - You must web check-in on the airline website
															and obtain a boarding pass.
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: '0 8px',
																fontSize: 13,
																color: '#000',
																width: '100%',
															}}>
															2 - You must download & register on the Aarogya
															Setu App and carry a Valid ID
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: '0 8px',
																fontSize: 13,
																color: '#000',
																width: '100%',
															}}>
															3 - It is mandatory to wear a musk and carry other
															protective gear.
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: '0 8px',
																fontSize: 13,
																color: '#000',
																width: '100%',
															}}>
															4 - Reach the terminal at least 2 hours prior to
															the departure for domestic flight and 4 hors prior
															to the departure of international flight .
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: '0 8px',
																fontSize: 13,
																color: '#000',
																width: '100%',
															}}>
															5 - For departure terminal please check with the
															airline first.
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: '0 8px',
																fontSize: 13,
																color: '#000',
																width: '100%',
															}}>
															6 - Date & Time is calculated based on the local
															time of the city/destination.
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: '0 8px',
																fontSize: 13,
																color: '#000',
																width: '100%',
															}}>
															7 - Use the Airline PNR for all Correspondence
															directly with the Airline.
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: '0 8px',
																fontSize: 13,
																color: '#000',
																width: '100%',
															}}>
															8 - For rescheduling/cancellation within 4hours of
															the departure time contact the airline directly.
														</td>
													</tr>
													<tr>
														<td
															style={{
																padding: '0 8px',
																fontSize: 13,
																color: '#000',
																width: '100%',
															}}>
															9 - Your ability to travel is at the sole
															discretion of the airport authorities and we shall
															not be held responsible.
														</td>
													</tr>
													<br />
												</tbody>
											</table>
											<table style={{ marginTop: 12, width: '100%' }}>
												<tbody>
													<tr>
														<td>
															<p
																style={{
																	background: '#000',
																	color: '#fff',
																	padding: '1px',
																	paddingLeft: '20px',
																	fontSize: 12,
																	webkitPrintColorAdjust: 'exact',
																}}>
																{/* Important Information */}
															</p>
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<table className={classes.table} style={{ marginBottom: 20 }}>
				<tbody>
					<tr>
						<td>
							<LoadingButton
								id='myPrntbtn'
								onClick={() => window.print()}
								loadingPosition='start'
								startIcon={<PrintIcon />}
								variant='contained'>
								Print
							</LoadingButton>
						</td>

						<td>
							<LoadingButton
								id='mail'
								onClick={handleClick}
								loadingPosition='start'
								startIcon={<EmailIcon />}
								variant='contained'>
								Mail
							</LoadingButton>
						</td>
						<td>
							<LoadingButton
								id='editprice'
								onClick={() => dispatch(toggleEditTicketModal(true))}
								loadingPosition='start'
								startIcon={<EditIcon />}
								variant='contained'>
								Edit Price
							</LoadingButton>
						</td>
						<td>
							<LoadingButton
								id='editprice'
								onClick={() => {
									toggle ? setToggle(false) : setToggle(true);
								}}
								// onClick={() => dispatch(toggleEditTicketModal(true))}
								loadingPosition='start'
								startIcon={<EditIcon />}
								variant='contained'>
								{`${toggle ? 'Show' : 'Hide'} Agency Detail`}
							</LoadingButton>
						</td>
					</tr>
				</tbody>
			</table>
			<EditTicketPriceModal />
		</>
	);
};

export default TicketDisplay;
