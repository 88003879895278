import React from 'react';
import {
	Avatar,
	Box,
	Hidden,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SuccessModal from './SuccessModal';
import { setSection, toggleLoginModal } from '../../reducers/UiReducer';
import { agentloginSuccess } from '../../reducers/UserReducer';
import managebookingIcon from '../../assets/images/managebookingIcon.png';
import ErrorModal from './ErrorModal';
import Login from './Login';
const HeaderSection = () => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const history = useHistory();
	const { section } = useSelector((state) => state.ui);
	const { logo, admin, header } = useSelector((state) => state.home);
	const { travellerlogin, agentlogin, walletagent, wallet, agentlogoget } =
		useSelector((state) => state.user);
	return (
		<>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					width: '100%',
					paddingRight: 15,
					paddingLeft: 15,
					marginRight: 'auto',
					marginLeft: 'auto',
				}}>
				<Box style={{ paddingBottom: 4.5, paddingTop: 4.5 }}>
					<i
						class='fas fa-blender-phone'
						style={{ fontSize: matches ? 12 : '' }}></i>
					<span style={{ color: '#065999', fontSize: 13 }}>
						{admin?.result[0]?.mobile_no}
					</span>
					<span style={{ display: 'inline-block', width: '1px' }}></span>
					<i
						class='fa fa-fw fa-envelope'
						style={{ fontSize: matches ? 12 : '' }}></i>
					<span style={{ color: '#065999', fontSize: 13 }}>
						{admin?.result[0]?.email}
					</span>
				</Box>
				<Box
					alignItems='center'
					display={'flex'}
					style={{
						paddingLeft: 5,
						paddingRight: 5,
						cursor: 'pointer',
					}}>
					<img src={managebookingIcon} style={{ height: 25 }} />
					<span
						style={{ fontFamily: 'Poppins' }}
						onClick={() => history.push('/printticket')}>
						Manage your Booking
					</span>
					{agentlogin && agentlogin.status === 200 ? (
						<Box
							style={{ marginRight: 15, marginLeft: 15, cursor: 'pointer' }}
							onClick={() => {
								dispatch(agentloginSuccess(null));
								sessionStorage.removeItem('userInfoAgent');
								history.push('/');
							}}>
							Signout
						</Box>
					) : (
						''
					)}
				</Box>
			</Box>
			<header className='header' id='headerticket'>
				<img
					style={{
						cursor: 'pointer',
						height: matches ? 30 : 45,
					}}
					src={logo?.result[0].logo}
					onClick={() => history.push('/')}></img>
				<Hidden only={['xs']}>
					<Box display='flex'>
						{header?.result[0]?.status === '1' ? (
							<Box
								display='flex'
								alignItems='center'
								onClick={() => {
									dispatch(setSection('flight'));
									history.push('/Flights-Booking');
								}}>
								<i
									class='fas fa-plane-departure'
									style={{
										color: section === 'flight' ? 'orange' : '#0A95FF',
										cursor: 'pointer',
									}}></i>
								<span
									style={{
										color: section === 'flight' ? 'orange' : '#0A95FF',
										marginLeft: 5,
										cursor: 'pointer',
									}}>
									Flights
								</span>
							</Box>
						) : (
							''
						)}

						{header?.result[2]?.status === '1' ? (
							<Box
								display='flex'
								alignItems='center'
								ml={'20px'}
								mr={'10px'}
								onClick={() => {
									dispatch(setSection('hotel'));
									history.push('/hotel-booking');
								}}>
								<i
									class='fas fa-building'
									style={{
										color: section === 'hotel' ? 'orange' : '#0A95FF',
										cursor: 'pointer',
									}}></i>

								<span
									style={{
										color: section === 'hotel' ? 'orange' : '#0A95FF',
										marginLeft: 5,
										cursor: 'pointer',
									}}>
									Hotels
								</span>
							</Box>
						) : (
							''
						)}
						{header?.result[1]?.status === '1' ? (
							<Box
								display='flex'
								alignItems='center'
								ml={'20px'}
								mr={'20px'}
								onClick={() => {
									dispatch(setSection('bus'));
									history.push('/bus-booking');
								}}>
								<i
									class='fas fa-bus'
									style={{
										color: section === 'bus' ? 'orange' : '#0A95FF',
										cursor: 'pointer',
									}}></i>
								<span
									style={{
										color: section === 'bus' ? 'orange' : '#0A95FF',
										marginLeft: 5,
										cursor: 'pointer',
									}}>
									Buses
								</span>
							</Box>
						) : (
							''
						)}
						{header?.result[3]?.status === '1' ? (
							<Box
								display='flex'
								alignItems='center'
								ml={'20px'}
								mr={'30px'}
								onClick={() => {
									dispatch(setSection('Forex'));
									history.push('/forex');
								}}>
								<i
									class='fas fa-file-invoice-dollar'
									style={{
										color: section === 'Forex' ? 'orange' : '#0A95FF',
										cursor: 'pointer',
									}}></i>
								<span
									style={{
										color: section === 'Forex' ? 'orange' : '#0A95FF',
										marginLeft: 5,
										cursor: 'pointer',
									}}>
									Forex
								</span>
							</Box>
						) : (
							''
						)}
						<Hidden only={['xs']}>
							<Box display='flex' alignItems='center'>
								{travellerlogin && travellerlogin.status === 200 ? (
									<Box
										ml={'10px'}
										display='flex'
										alignItems='center'
										style={{ cursor: 'pointer' }}
										onClick={() => history.push('/dashboard')}>
										<Avatar
											style={{
												color: 'white',
												background: 'rgb(239, 51, 57)',
											}}>
											{travellerlogin &&
												travellerlogin.result &&
												travellerlogin.result.result.name
													.match(/(\b\S)?/g)
													.join('')
													.match(/(^\S|\S$)?/g)
													.join('')
													.toUpperCase()}
										</Avatar>
									</Box>
								) : agentlogin && agentlogin.status === 200 ? (
									<Box style={{ alignItems: 'center' }} display='flex'>
										<Box
											style={{
												marginRight: 10,
												fontSize: 14,
												cursor: 'pointer',
											}}
											onClick={() => history.push('/dashboard')}>
											{agentlogin ? agentlogin?.result.result.agencyName : ''}
										</Box>

										<Box style={{ fontSize: 20, marginRight: 15 }}>
											₹ Available Balance:{' '}
											{walletagent ? walletagent?.result.balanceAmount : ''}
										</Box>
										<Avatar
											onClick={() => history.push('/dashboard')}
											style={{
												color: 'white',
												background: 'rgb(239, 51, 57)',
												marginRight: 15,
												cursor: 'pointer',
											}}>
											{agentlogin &&
												agentlogin.result &&
												agentlogin.result.result.name
													.match(/(\b\S)?/g)
													.join('')
													.match(/(^\S|\S$)?/g)
													.join('')
													.toUpperCase()}
										</Avatar>
									</Box>
								) : (
									<>
										<Box
											onClick={() => dispatch(toggleLoginModal(true))}
											style={{
												background: 'rgb(0, 55, 114)',
												color: 'white',
												padding: '8px',
												fontWeight: 'bold',
												fontSize: '.8em',
												cursor: 'pointer',
											}}>
											Login or Create Account
										</Box>
									</>
								)}
							</Box>
						</Hidden>
					</Box>
				</Hidden>
				<Hidden only={['lg', 'xl', 'sm', 'md']}>
					<div className='icons'>
						{agentlogin && agentlogin.status === 200 ? (
							<Avatar
								onClick={() => history.push('/dashboard')}
								style={{
									color: 'white',
									background: 'rgb(239, 51, 57)',
									marginRight: 15,
									cursor: 'pointer',
									borderRadius: 15,
								}}>
								{agentlogin &&
									agentlogin.result &&
									agentlogin.result.result.name
										.match(/(\b\S)?/g)
										.join('')
										.match(/(^\S|\S$)?/g)
										.join('')
										.toUpperCase()}
							</Avatar>
						) : (
							<Box
								onClick={() => dispatch(toggleLoginModal(true))}
								style={{
									background: 'rgb(0, 55, 114)',
									color: 'white',
									fontWeight: 'bold',
									fontSize: '10px',
									cursor: 'pointer',
									width: '100%',
									paddingLeft: 5,
									paddingRight: 5,
								}}>
								<span>Login or Create Account</span>
							</Box>
						)}
					</div>
				</Hidden>
			</header>
			<SuccessModal />
			<ErrorModal />
			<Login />
		</>
	);
};

export default HeaderSection;
