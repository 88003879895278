import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { makeStyles, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setOrigin, setOriginO } from '../../reducers/UiReducer';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		'& label': {
			display: 'none',
		},
		width: '100%',
		border: '1px solid lightgrey',
		borderRadius: 4,
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			fontSize: 12,
			padding: '12px',
			fontWeight: 600,
		},
		'& .MuiInputAdornment-root': {
			marginRight: 14,
		},
		'& .MuiInput-root': {
			marginTop: 0,
		},
	},
}));
export default function DesktopDateBus() {
	const [value, setValue] = React.useState(new Date());
	const classes = useStyles();
	const [isOpen, setIsOpen] = React.useState(false);
	const dispatch = useDispatch();
	const convert = (str) => {
		var date = new Date(str),
			mnth = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join('-');
	};
	dispatch(setOrigin(convert(value)));
	dispatch(setOriginO(value));
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DesktopDatePicker
				label='From'
				value={value}
				disablePast
				inputFormat='dd/MM/yyyy'
				minDate={new Date('2017-01-01')}
				onChange={(newValue) => {
					setValue(newValue);
				}}
				open={isOpen}
				onOpen={() => setIsOpen(true)}
				onClose={() => setIsOpen(false)}
				renderInput={(params) => (
					<TextField
						{...params}
						className={classes.selectField}
						onClick={(e) => setIsOpen(true)}
					/>
				)}
			/>
		</LocalizationProvider>
	);
}
