import React from "react";
import { Grid, Box } from "@mui/material";
import "react-alice-carousel/lib/alice-carousel.css"; // requires a loader
import AliceCarousel from "react-alice-carousel";
import { useSelector } from "react-redux";
const CarouselBottom = () => {
  const { travelBlog } = useSelector((state) => state.home);
  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1024: { items: 2 },
  };
  const items =
    travelBlog &&
    travelBlog?.result.map((i, index) => {
      return (
        <div className="item" data-value="1" style={{ marginTop: 20 }}>
          <Grid container>
            <Grid
              item
              xs={8}
              style={{
                border: "2px solid #dfdfdf",
                padding: "10px 65px 10px 10px",
                borderRadius: 9,
              }}
            >
              <Box>
                <h4 style={{ fontFamily: "Conv_Rupee" }}>{i.heading}</h4>
                <h6
                  style={{ width: "75%", fontFamily: "Poppins", fontSize: 15 }}
                >
                  {i.short_des}
                </h6>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box
                style={{
                  position: "relative",
                  marginTop: "13px",
                  right: "25%",
                }}
              >
                <img
                  src={i.image}
                  style={{
                    // width: '300px',
                    height: "150px",
                    padding: "15px 15px 15px 14px",
                    borderRadius: "32px",
                  }}
                />
                <img
                  src={i.image1}
                  style={{
                    height: "150px",
                    padding: "15px 15px 15px 14px",
                    borderRadius: "32px",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
      );
    });
  const demo = [
    <div className="item" data-value="1">
      1
    </div>,
    <div className="item" data-value="2">
      2
    </div>,
    <div className="item" data-value="3">
      3
    </div>,
    <div className="item" data-value="4">
      4
    </div>,
    <div className="item" data-value="5">
      5
    </div>,
  ];

  return (
    <AliceCarousel
      autoPlay
      infinite
      mouseTracking
      items={travelBlog ? items : demo}
      autoPlayInterval={3000}
      responsive={responsive}
      controlsStrategy="alternate"
      keyboardNavigation={true}
      renderPrevButton={() => {
        return <p></p>;
      }}
      renderNextButton={() => {
        return <p></p>;
      }}
    />
  );
};

export default CarouselBottom;
