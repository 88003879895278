import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { agenttransactionhistoryDispatch } from '../../reducers/UserReducer';
const tableHeaders = [
	'TYPE',
	'CART_ID',
	'DOJ',
	'COMMISSION',
	'MARKUP',
	'AMOUNT',
	'AVAL_BAL',
	'PAY_TYPE',
	'DATE',
	'HOLD_S',
	'TRANS_S',
	'DESC',
];

const TransactionHistory = () => {
	const { transagent, agentlogin, loadinguser } = useSelector(
		(state) => state.user
	);
	const [val, setVal] = useState('');
	const [data, setData] = useState('');
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			agenttransactionhistoryDispatch(agentlogin && agentlogin.result.result.id)
		);
	}, []);
	let tranData = [];
	if (loadinguser) {
		console.log('first');
	} else {
		// tranData= transagent &&
		// transagent.result &&
		// transagent.result.result?.length > 0 &&
		// transagent.result.result?.slice().sort((a,b)=>{
		//   return a.id - b.id
		// })
	}
	// console.log('tranData', tranData);
	const handleSearch = (event) => {
		let value = event.target.value.toLowerCase();
		let results = [];
		console.log('filter',value);
		// setVal(value);

		if(event.target.value.toLowerCase()==='new'){
			results =
			transagent &&
		transagent.result &&
		transagent.result.result?.length > 0 &&
		transagent.result.result?.slice().sort((a,b)=>{
		  return b.id - a.id
		})
		setData(results);
		}else if(event.target.value.toLowerCase()==='old'){
			results =
			transagent &&
		transagent.result &&
		transagent.result.result?.length > 0 &&
		transagent.result.result?.slice().sort((a,b)=>{
		  return a.id - b.id
		})
		setData(results);
		}else if(event.target.value.toLowerCase()==='lh'){
			results =
			transagent &&
		transagent.result &&
		transagent.result.result?.length > 0 &&
		transagent.result.result?.slice().sort((a,b)=>{
		  return a.amount - b.amount
		})
		setData(results);
		}else if(event.target.value.toLowerCase()==='hl'){
			results =
			transagent &&
		transagent.result &&
		transagent.result.result?.length > 0 &&
		transagent.result.result?.slice().sort((a,b)=>{
		  return b.amount - a.amount
		})
		setData(results);
		}
		else{
			results =
			transagent &&
			transagent.result &&
			transagent.result.result.filter((item) => {
				return (
					
					item.type.toLowerCase().includes(value.toLowerCase())
					
				);
			});
		setData(results);
		}
		console.log('results',results);
	};
	console.log('Result', data);
	return (
		<>
			<div className='row ' style={{ padding: 15 }}>
			<Box display="flex" justifyContent="space-between">
          <h3 className="fs-4 mb-3">Booking Details</h3>
		  <Box display='flex'>
		  <Box display="flex" alignItems="center">
            {/* <span>Filter</span> */}
            <select onChange={handleSearch}
              name="Filter by"
              id="cars"
              style={{padding: 10,border:'1px solid grey' }}
            >
              <option value="booked"> Filter</option>
              <option value="CR"> Credit</option>
              <option value="DR">Debit</option>
              {/* <option value="offline">Offline Payment</option>
              <option value="online">Online Payment</option>
              <option value="di">DI Amount</option> */}
            </select>
          </Box>
		  <Box display="flex" alignItems="center" ml={'5px'} >
            {/* <span>Filter</span> */}
            <select onChange={handleSearch}
              name="Sort by"
              id="cars"
              style={{  padding: 10,border:'1px solid grey'}}
            >
              <option value="booked"> Sort</option>
              <option value="new"> Newest to Oldest</option>
              <option value="old">Oldest to Newest</option>
              <option value="lh">Price Low-High</option>
              <option value="hl">Price High-Low</option>
            </select>
          </Box>
		  </Box>
         
        </Box>
				<div className='col'>
					<table className='table bg-white rounded shadow-sm  table-hover'>
						<thead>
							<tr>
								<th scope='col' width='50'>
									#
								</th>
								{tableHeaders.map((i) => {
									return (
										<th scope='col' style={{ fontFamily: 'monospace' }}>
											{i}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{data&&data.length>0?data.map((i,index)=>{
								return <tr>
								<th
									scope='row'
									style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.id}
								</th>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.type}
								</td>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.cart_id}
								</td>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.doj}
								</td>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.commission}
								</td>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.markup}
								</td>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.amount}
								</td>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.avail_bal}
								</td>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.payment_type}
								</td>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.date.split(' ')[0]}
								</td>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.hold_status}
								</td>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.trans_section}
								</td>
								<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
									{i.description}
								</td>
							</tr>
							}):transagent &&
								transagent.result &&
								transagent.result.result?.length > 0 &&
								transagent.result.result?.map((i, index) => {
									return (
										<tr>
											<th
												scope='row'
												style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.id}
											</th>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.type}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.cart_id}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.doj}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.commission}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.markup}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.amount}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.avail_bal}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.payment_type}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.date.split(' ')[0]}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.hold_status}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.trans_section}
											</td>
											<td style={{ fontSize: 12, fontFamily: 'Poppins' }}>
												{i.description}
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default TransactionHistory;
