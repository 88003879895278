import React from 'react';
import { Box, makeStyles, TableRow, TableCell } from '@material-ui/core';
import BodyText from './BodyText';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { toggleFlightDetailModal } from '../../reducers/UiReducer';
const useStyles = makeStyles(() => ({
	optionsIcon: {
		height: 25,
		width: 25,
		backgroundColor: '#FDE8EE',
		padding: 3,
		borderRadius: '50%',
	},
	tableCell: {
		fontSize: 14,
		// padding: 3,
		color: 'rgb(0 0 0 / 73%)',
		fontWeight: 600,
	},
	img: {
		'& .MuiAvatar-img': {
			width: 30,
			height: 30,
		},
	},
}));
const BusSegment = ({ parseData }) => {
	const classes = useStyles();
	console.log(
		'🚀  👿🤮😸~ file: FlightSegment.js ~ line 30 ~ FlightSegment ~ par̥seData',
		parseData
	);

	return (
		<>
			<TableRow style={{ marginTop: 20 }}>
				<TableCell className={classes.tableCell}>
					{parseData?.busType}
				</TableCell>
				<TableCell className={classes.tableCell}>
					{parseData?.sourceCity}
				</TableCell>
				<TableCell className={classes.tableCell}>
					{parseData?.destinationCity}
				</TableCell>
				<TableCell className={classes.tableCell}>{parseData?.doj}</TableCell>
				<TableCell className={classes.tableCell}>
					{parseData?.dateOfIssue}
				</TableCell>
			</TableRow>
		</>
	);
};

export default BusSegment;
