const addBookingReturn = (
	location,
	agentlogin,
	payment,
	payableAmout,
	priceDetails2,
	priceDetails3,
	selectedFlight,
	dispatch,
	FlightAddBookingDispatch,
	PGCharge,
	BookingFormData,
	fareQuoteEtrav,
	fareQuote,
	fareQuoteReturn,
	handlePayment
) => {
	let etrav2 = location.state.selectedonward
		? location.state.selectedonward[0].Airline_Code &&
		  location.state.selectedReturn[0].Airline_Code
		: '';
	let etrvotbor = location.state.selectedonward
		? location.state.selectedonward[0].Airline_Code &&
		  location.state.selectedReturn[0].AirlineCode
		: '';
	let etravrtboo = location.state.selectedonward
		? location.state.selectedReturn[0].Airline_Code &&
		  location.state.selectedonward[0].AirlineCode
		: '';
	let tbo2 = location.state.selectedonward
		? location.state.selectedonward[0].AirlineCode &&
		  location.state.selectedReturn[0].AirlineCode
		: '';
	const tboPGCharge = tbo2
		? Number(
				Number(
					Number(
						Number(
							Number(
								Math.round(
									fareQuote
										? fareQuote?.result?.Response?.Results?.Fare.Total_Amount
										: location.state.selectedonward[0].Fare.Total_Amount
								)
							) -
								Number(
									Math.round(location.state.selectedonward[0].Fare.agentMarkup)
								) -
								Number(
									Math.round(
										fareQuote
											? fareQuote?.result?.Response?.Results?.Fare.MFB_Discount
											: location.state.selectedonward[0].Fare.MFB_Discount
									)
								)
						) +
							Number(
								Number(
									Math.round(
										fareQuoteReturn
											? fareQuoteReturn?.result?.Response?.Results?.Fare
													.Total_Amount
											: location.state.selectedReturn[0].Fare.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.agentMarkup
										)
									) -
									Number(
										Math.round(
											fareQuoteReturn
												? fareQuoteReturn?.result?.Response?.Results?.Fare
														.MFB_Discount
												: location.state.selectedReturn[0].Fare.MFB_Discount
										)
									)
							)
					) * 2.5
				) / 100
		  )
		: '';
	let etravPGCharge = etrav2
		? Number(
				Number(
					Number(
						Number(
							Number(
								Math.round(location.state.selectedonward[0].Fares.Total_Amount)
							) -
								Number(
									Math.round(location.state.selectedonward[0].Fares.agentMarkup)
								)
						) +
							Number(
								Number(
									Math.round(
										location.state.selectedReturn[0].Fares.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedReturn[0].Fares.agentMarkup
										)
									)
							)
					) * 2.5
				) / 100
		  )
		: '';
	let etrvotborPGCharge = etrvotbor
		? Number(
				Number(
					Number(
						Number(
							Number(
								Math.round(location.state.selectedonward[0].Fares.Total_Amount)
							) -
								Number(
									Math.round(location.state.selectedonward[0].Fares.agentMarkup)
								)
						) +
							Number(
								Number(
									Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
								) -
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.agentMarkup
										)
									)
							)
					) * 2.5
				) / 100
		  )
		: '';
	let etravrtbooPGCharge = etravrtboo
		? Number(
				Number(
					Number(
						Number(
							Number(
								Math.round(location.state.selectedonward[0].Fare.Total_Amount)
							) -
								Number(
									Math.round(location.state.selectedonward[0].Fare.agentMarkup)
								)
						) +
							Number(
								Number(
									Math.round(
										location.state.selectedReturn[0].Fares.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedReturn[0].Fares.agentMarkup
										)
									)
							)
					) * 2.5
				) / 100
		  )
		: '';
	let formaddBooking = {
		orderId: location.state.orderID,
		supplier: `${location.state.selectedonward[0].Supplier}|${location.state.selectedReturn[0].Supplier}`,
		userEmail: location.state.formData.email,
		userPhone: location.state.formData.phone,
		user_type: agentlogin && agentlogin.status === 200 ? 'agent' : 'guest',
		origin: location.state.returnBody.Segments[0].Origin,
		destination: location.state.returnBody.Segments[0].Destination,
		travelDate: location.state.returnBody.Segments[0].PreferredDepartureTime,
		returnDate: location.state.returnBody.Segments[1].PreferredDepartureTime,
		journeyType: location.state.returnBody.JourneyType === 2 ? 'RoundTrip' : '',
		origin1: location.state.returnBody.Segments[1].Origin,
		destination1: location.state.returnBody.Segments[1].Destination,
		travelDate1: '',
		origin2: '',
		destination2: '',
		travelDate2: '',
		origin3: '',
		destination3: '',
		travelDate3: '',
		PGCharge: agentlogin
			? payment === 'offline'
				? 0
				: tbo2
				? tboPGCharge
				: etrav2
				? etravPGCharge
				: etrvotbor
				? etrvotborPGCharge
				: etravrtboo
				? etravrtbooPGCharge
				: ''
			: '',
		class: 'Economy',
		nop: `${location.state.returnBody.AdultCount} | ${location.state.returnBody.ChildCount} | ${location.state.returnBody.InfantCount} `,
		airlineType: '',
		carrier: etrav2
			? `${location.state.selectedonward[0].Airline_Code}|${location.state.selectedReturn[0].Airline_Code}`
			: tbo2
			? `${location.state.selectedonward[0].AirlineCode}|${location.state.selectedReturn[0].AirlineCode}`
			: etrvotbor
			? `${location.state.selectedonward[0].Airline_Code}|${location.state.selectedReturn[0].AirlineCode}`
			: etravrtboo
			? `${location.state.selectedonward[0].AirlineCode}|${location.state.selectedReturn[0].Airline_Code}`
			: '',
		fareType: 'R',
		eTicketType: 'eTicket',
		actualFare: tbo2
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.Total_Amount)
					) -
						Number(Math.round(location.state.selectedonward[0].Fare.markup)) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.agentMarkup)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.MFB_Discount)
						)
			  ) +
			  Number(
					Number(
						Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
					) -
						Number(Math.round(location.state.selectedReturn[0].Fare.markup)) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare.agentMarkup)
						) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare.MFB_Discount)
						)
			  )
			: etrav2
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.Total_Amount)
					) -
						Number(Math.round(location.state.selectedonward[0].Fares.markup)) -
						Number(
							Math.round(location.state.selectedonward[0].Fares.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fares.agentMarkup)
						)
			  ) +
			  Number(
					Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
			  ) -
			  Number(Math.round(location.state.selectedReturn[0].Fares.markup)) -
			  Number(
					Math.round(location.state.selectedReturn[0].Fares.partnerMarkup)
			  ) -
			  Number(Math.round(location.state.selectedReturn[0].Fares.agentMarkup))
			: etrvotbor
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.Total_Amount)
					) -
						Number(Math.round(location.state.selectedonward[0].Fares.markup)) -
						Number(
							Math.round(location.state.selectedonward[0].Fares.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fares.agentMarkup)
						)
			  ) +
			  Number(Math.round(location.state.selectedReturn[0].Fare.Total_Amount)) -
			  Number(Math.round(location.state.selectedReturn[0].Fare.markup)) -
			  Number(
					Math.round(location.state.selectedReturn[0].Fare.partnerMarkup)
			  ) -
			  Number(Math.round(location.state.selectedReturn[0].Fare.agentMarkup))
			: etravrtboo
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.Total_Amount)
					) -
						Number(Math.round(location.state.selectedonward[0].Fare.markup)) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.agentMarkup)
						)
			  ) +
			  Number(
					Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
			  ) -
			  Number(Math.round(location.state.selectedReturn[0].Fares.markup)) -
			  Number(
					Math.round(location.state.selectedReturn[0].Fares.partnerMarkup)
			  ) -
			  Number(Math.round(location.state.selectedReturn[0].Fares.agentMarkup))
			: '',
		markup: etrav2
			? Number(
					Number(Math.round(location.state.selectedonward[0].Fares.markup)) +
						Number(Math.round(location.state.selectedReturn[0].Fares.markup))
			  )
			: etravrtboo
			? Number(
					Number(Math.round(location.state.selectedonward[0].Fare.markup)) +
						Number(Math.round(location.state.selectedReturn[0].Fares.markup))
			  )
			: etrvotbor
			? Number(
					Number(Math.round(location.state.selectedonward[0].Fares.markup)) +
						Number(Math.round(location.state.selectedReturn[0].Fare.markup))
			  )
			: tbo2
			? Number(
					Number(Math.round(location.state.selectedonward[0].Fare.markup)) +
						Number(Math.round(location.state.selectedReturn[0].Fare.markup))
			  )
			: '',
		partnerMarkup: etrav2
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.partnerMarkup)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fares.partnerMarkup)
						)
			  )
			: etravrtboo
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.partnerMarkup)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fares.partnerMarkup)
						)
			  )
			: etrvotbor
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.partnerMarkup)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fare.partnerMarkup)
						)
			  )
			: tbo2
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.partnerMarkup)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fare.partnerMarkup)
						)
			  )
			: '',
		agentMarkup: etrav2
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.agentMarkup)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fares.agentMarkup)
						)
			  )
			: etravrtboo
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.agentMarkup)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fares.agentMarkup)
						)
			  )
			: etrvotbor
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.agentMarkup)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fare.agentMarkup)
						)
			  )
			: tbo2
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.agentMarkup)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fare.agentMarkup)
						)
			  )
			: '',
		baseFare: tbo2
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.agentMarkup)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.MFB_Discount)
						)
			  ) +
			  Number(
					Number(
						Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare.agentMarkup)
						) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare.MFB_Discount)
						)
			  )
			: etrav2
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedonward[0].Fares.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fares.agentMarkup)
						)
			  ) +
			  Number(
					Number(
						Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedReturn[0].Fares.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedReturn[0].Fares.agentMarkup)
						)
			  )
			: etrvotbor
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedonward[0].Fares.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fares.agentMarkup)
						)
			  ) +
			  Number(
					Number(
						Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare.agentMarkup)
						)
			  )
			: etravrtboo
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.agentMarkup)
						)
			  ) +
			  Number(
					Number(
						Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedReturn[0].Fares.partnerMarkup)
						) -
						Number(
							Math.round(location.state.selectedReturn[0].Fares.agentMarkup)
						)
			  )
			: '',
		totalFare: tbo2
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.agentMarkup)
						) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.MFB_Discount)
						)
			  ) +
			  Number(
					Number(
						Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare.agentMarkup)
						) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare.MFB_Discount)
						)
			  )
			: etrav2
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedonward[0].Fares.agentMarkup)
						)
			  ) +
			  Number(
					Number(
						Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedReturn[0].Fares.agentMarkup)
						)
			  )
			: etrvotbor
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedonward[0].Fares.agentMarkup)
						)
			  ) +
			  Number(
					Number(
						Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedReturn[0].Fare.agentMarkup)
						)
			  )
			: etravrtboo
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedonward[0].Fare.agentMarkup)
						)
			  ) +
			  Number(
					Number(
						Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
					) -
						Number(
							Math.round(location.state.selectedReturn[0].Fares.agentMarkup)
						)
			  )
			: '',
		payableAmount: agentlogin
			? payment === 'offline'
				? tbo2
					? Number(
							Number(
								Number(
									Math.round(
										fareQuote
											? fareQuote?.result?.Response?.Results?.Fare.Total_Amount
											: location.state.selectedonward[0].Fare.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fare.agentMarkup
										)
									) -
									Number(
										Math.round(
											fareQuote
												? fareQuote?.result?.Response?.Results?.Fare
														.MFB_Discount
												: location.state.selectedonward[0].Fare.MFB_Discount
										)
									)
							) +
								Number(
									Number(
										Math.round(
											fareQuoteReturn
												? fareQuoteReturn?.result?.Response?.Results?.Fare
														.Total_Amount
												: location.state.selectedReturn[0].Fare.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fare.agentMarkup
											)
										) -
										Number(
											Math.round(
												fareQuoteReturn
													? fareQuoteReturn?.result?.Response?.Results?.Fare
															.MFB_Discount
													: location.state.selectedReturn[0].Fare.MFB_Discount
											)
										)
								)
					  )
					: etrav2
					? Number(
							Number(
								Number(
									Math.round(
										fareQuoteEtrav
											? fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight
													.Fares.Total_Amount
											: location.state.selectedonward[0].Fares.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fares.agentMarkup
										)
									)
							) +
								Number(
									Number(
										Math.round(
											fareQuoteEtrav
												? fareQuoteEtrav?.result?.AirRepriceResponses[1].Flight
														.Fares.Total_Amount
												: location.state.selectedReturn[0].Fares.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fares.agentMarkup
											)
										)
								)
					  )
					: etrvotbor
					? Number(
							Number(
								Number(
									Math.round(
										location.state.selectedonward[0].Fares.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fares.agentMarkup
										)
									)
							) +
								Number(
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fare.agentMarkup
											)
										)
								)
					  )
					: etravrtboo
					? Number(
							Number(
								Number(
									Math.round(location.state.selectedonward[0].Fare.Total_Amount)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fare.agentMarkup
										)
									)
							) +
								Number(
									Number(
										Math.round(
											location.state.selectedReturn[0].Fares.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fares.agentMarkup
											)
										)
								)
					  )
					: ''
				: tbo2
				? Number(
						Number(
							Number(
								Math.round(
									fareQuote
										? fareQuote?.result?.Response?.Results?.Fare.Total_Amount
										: location.state.selectedonward[0].Fare.Total_Amount
								)
							) -
								Number(
									Math.round(location.state.selectedonward[0].Fare.agentMarkup)
								) -
								Number(
									Math.round(
										fareQuote
											? fareQuote?.result?.Response?.Results?.Fare.MFB_Discount
											: location.state.selectedonward[0].Fare.MFB_Discount
									)
								)
						) +
							Number(
								Number(
									Math.round(
										fareQuoteReturn
											? fareQuoteReturn?.result?.Response?.Results?.Fare
													.Total_Amount
											: location.state.selectedReturn[0].Fare.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.agentMarkup
										)
									) -
									Number(
										Math.round(
											fareQuoteReturn
												? fareQuoteReturn?.result?.Response?.Results?.Fare
														.MFB_Discount
												: location.state.selectedReturn[0].Fare.MFB_Discount
										)
									)
							)
				  ) +
				  Number(
						Number(
							Number(
								Number(
									Number(
										Math.round(
											fareQuote
												? fareQuote?.result?.Response?.Results?.Fare
														.Total_Amount
												: location.state.selectedonward[0].Fare.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedonward[0].Fare.agentMarkup
											)
										) -
										Number(
											Math.round(
												fareQuote
													? fareQuote?.result?.Response?.Results?.Fare
															.MFB_Discount
													: location.state.selectedonward[0].Fare.MFB_Discount
											)
										)
								) +
									Number(
										Number(
											Math.round(
												fareQuoteReturn
													? fareQuoteReturn?.result?.Response?.Results?.Fare
															.Total_Amount
													: location.state.selectedReturn[0].Fare.Total_Amount
											)
										) -
											Number(
												Math.round(
													location.state.selectedReturn[0].Fare.agentMarkup
												) -
													Number(
														Math.round(
															fareQuoteReturn
																? fareQuoteReturn?.result?.Response?.Results
																		?.Fare.MFB_Discount
																: location.state.selectedReturn[0].Fare
																		.MFB_Discount
														)
													)
											)
									)
							) * 2.5
						) / 100
				  )
				: etrav2
				? Number(
						Number(
							Number(
								Math.round(
									fareQuoteEtrav
										? fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight
												.Fares.Total_Amount
										: location.state.selectedonward[0].Fares.Total_Amount
								)
							) -
								Number(
									Math.round(location.state.selectedonward[0].Fares.agentMarkup)
								)
						) +
							Number(
								Number(
									Math.round(
										fareQuoteEtrav
											? fareQuoteEtrav?.result?.AirRepriceResponses[1].Flight
													.Fares.Total_Amount
											: location.state.selectedReturn[0].Fares.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedReturn[0].Fares.agentMarkup
										)
									)
							)
				  ) +
				  Number(
						Number(
							Number(
								Number(
									Number(
										Math.round(
											fareQuoteEtrav
												? fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight
														.Fares.Total_Amount
												: location.state.selectedonward[0].Fares.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedonward[0].Fares.agentMarkup
											)
										)
								) +
									Number(
										Number(
											Math.round(
												fareQuoteEtrav
													? fareQuoteEtrav?.result?.AirRepriceResponses[1]
															.Flight.Fares.Total_Amount
													: location.state.selectedReturn[0].Fares.Total_Amount
											)
										) -
											Number(
												Math.round(
													location.state.selectedReturn[0].Fares.agentMarkup
												)
											)
									)
							) * 2.5
						) / 100
				  )
				: etrvotbor
				? Number(
						Number(
							Number(
								Math.round(location.state.selectedonward[0].Fares.Total_Amount)
							) -
								Number(
									Math.round(location.state.selectedonward[0].Fares.agentMarkup)
								)
						) +
							Number(
								Number(
									Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
								) -
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.agentMarkup
										)
									)
							)
				  ) +
				  Number(
						Number(
							Number(
								Number(
									Number(
										Math.round(
											location.state.selectedonward[0].Fares.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedonward[0].Fares.agentMarkup
											)
										)
								) +
									Number(
										Number(
											Math.round(
												location.state.selectedReturn[0].Fare.Total_Amount
											)
										) -
											Number(
												Math.round(
													location.state.selectedReturn[0].Fare.agentMarkup
												)
											)
									)
							) * 2.5
						) / 100
				  )
				: etravrtboo
				? Number(
						Number(
							Number(
								Math.round(location.state.selectedonward[0].Fare.Total_Amount)
							) -
								Number(
									Math.round(location.state.selectedonward[0].Fare.agentMarkup)
								)
						) +
							Number(
								Number(
									Math.round(
										location.state.selectedReturn[0].Fares.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedReturn[0].Fares.agentMarkup
										)
									)
							)
				  ) +
				  Number(
						Number(
							Number(
								Number(
									Number(
										Math.round(
											location.state.selectedonward[0].Fare.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedonward[0].Fare.agentMarkup
											)
										)
								) +
									Number(
										Number(
											Math.round(
												location.state.selectedReturn[0].Fares.Total_Amount
											)
										) -
											Number(
												Math.round(
													location.state.selectedReturn[0].Fares.agentMarkup
												)
											)
									)
							) * 2.5
						) / 100
				  )
				: ''
			: tbo2
			? Number(
					Number(
						Number(
							Math.round(location.state.selectedonward[0].Fare.Total_Amount)
						) -
							Number(
								Math.round(location.state.selectedonward[0].Fare.agentMarkup)
							) -
							Number(
								Math.round(location.state.selectedonward[0].Fare.MFB_Discount)
							)
					) +
						Number(
							Number(
								Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
							) -
								Number(
									Math.round(location.state.selectedReturn[0].Fare.agentMarkup)
								) -
								Number(
									Math.round(location.state.selectedReturn[0].Fare.MFB_Discount)
								)
						)
			  ) +
			  Number(
					Number(
						Number(
							Number(
								Number(
									Math.round(location.state.selectedonward[0].Fare.Total_Amount)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fare.agentMarkup
										)
									) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fare.MFB_Discount
										)
									)
							) +
								Number(
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fare.agentMarkup
											)
										) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fare.MFB_Discount
											)
										)
								)
						) * 2.5
					) / 100
			  )
			: etrav2
			? Number(
					Number(
						Number(
							Math.round(location.state.selectedonward[0].Fares.Total_Amount)
						) -
							Number(
								Math.round(location.state.selectedonward[0].Fares.agentMarkup)
							)
					) +
						Number(
							Number(
								Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
							) -
								Number(
									Math.round(location.state.selectedReturn[0].Fares.agentMarkup)
								)
						)
			  ) +
			  Number(
					Number(
						Number(
							Number(
								Number(
									Math.round(
										location.state.selectedonward[0].Fares.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fares.agentMarkup
										)
									)
							) +
								Number(
									Number(
										Math.round(
											location.state.selectedReturn[0].Fares.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fares.agentMarkup
											)
										)
								)
						) * 2.5
					) / 100
			  )
			: etrvotbor
			? Number(
					Number(
						Number(
							Math.round(location.state.selectedonward[0].Fares.Total_Amount)
						) -
							Number(
								Math.round(location.state.selectedonward[0].Fares.agentMarkup)
							)
					) +
						Number(
							Number(
								Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
							) -
								Number(
									Math.round(location.state.selectedReturn[0].Fare.agentMarkup)
								)
						)
			  ) +
			  Number(
					Number(
						Number(
							Number(
								Number(
									Math.round(
										location.state.selectedonward[0].Fares.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fares.agentMarkup
										)
									)
							) +
								Number(
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fare.agentMarkup
											)
										)
								)
						) * 2.5
					) / 100
			  )
			: etravrtboo
			? Number(
					Number(
						Number(
							Math.round(location.state.selectedonward[0].Fare.Total_Amount)
						) -
							Number(
								Math.round(location.state.selectedonward[0].Fare.agentMarkup)
							)
					) +
						Number(
							Number(
								Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
							) -
								Number(
									Math.round(location.state.selectedReturn[0].Fares.agentMarkup)
								)
						)
			  ) +
			  Number(
					Number(
						Number(
							Number(
								Number(
									Math.round(location.state.selectedonward[0].Fare.Total_Amount)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fare.agentMarkup
										)
									)
							) +
								Number(
									Number(
										Math.round(
											location.state.selectedReturn[0].Fares.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fares.agentMarkup
											)
										)
								)
						) * 2.5
					) / 100
			  )
			: '',
		ticketCost: agentlogin
			? payment === 'offline'
				? tbo2
					? Number(
							Number(
								Math.round(location.state.selectedonward[0].Fare.Total_Amount)
							)
					  ) +
					  Number(
							Number(
								Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
							)
					  ) -
					  Number(
							Number(
								Math.round(location.state.selectedonward[0].Fare.MFB_Discount)
							)
					  ) -
					  Number(
							Number(
								Math.round(location.state.selectedReturn[0].Fare.MFB_Discount)
							)
					  )
					: etrav2
					? Number(
							Number(
								Math.round(location.state.selectedonward[0].Fares.Total_Amount)
							)
					  ) +
					  Number(
							Number(
								Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
							)
					  )
					: etrvotbor
					? Number(
							Number(
								Math.round(location.state.selectedonward[0].Fares.Total_Amount)
							)
					  ) +
					  Number(
							Number(
								Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
							)
					  )
					: etravrtboo
					? Number(
							Number(
								Math.round(location.state.selectedonward[0].Fare.Total_Amount)
							)
					  ) +
					  Number(
							Number(
								Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
							)
					  )
					: ''
				: tbo2
				? Number(
						Number(
							Math.round(location.state.selectedonward[0].Fare.Total_Amount)
						)
				  ) +
				  Number(
						Number(
							Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
						)
				  ) +
				  Number(
						Number(
							Number(
								Number(
									Number(
										Math.round(
											location.state.selectedonward[0].Fare.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedonward[0].Fare.agentMarkup
											)
										)
								) +
									Number(
										Number(
											Math.round(
												location.state.selectedReturn[0].Fare.Total_Amount
											)
										) -
											Number(
												Math.round(
													location.state.selectedReturn[0].Fare.agentMarkup
												)
											)
									)
							) * 2.5
						) / 100
				  )
				: etrav2
				? Number(
						Number(
							Math.round(location.state.selectedonward[0].Fares.Total_Amount)
						)
				  ) +
				  Number(
						Number(
							Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
						)
				  ) +
				  Number(
						Number(
							Number(
								Number(
									Number(
										Math.round(
											location.state.selectedonward[0].Fares.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedonward[0].Fares.agentMarkup
											)
										)
								) +
									Number(
										Number(
											Math.round(
												location.state.selectedReturn[0].Fares.Total_Amount
											)
										) -
											Number(
												Math.round(
													location.state.selectedReturn[0].Fares.agentMarkup
												)
											)
									)
							) * 2.5
						) / 100
				  )
				: etrvotbor
				? Number(
						Number(
							Math.round(location.state.selectedonward[0].Fares.Total_Amount)
						)
				  ) +
				  Number(
						Number(
							Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
						)
				  ) +
				  Number(
						Number(
							Number(
								Number(
									Number(
										Math.round(
											location.state.selectedonward[0].Fares.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedonward[0].Fares.agentMarkup
											)
										)
								) +
									Number(
										Number(
											Math.round(
												location.state.selectedReturn[0].Fare.Total_Amount
											)
										) -
											Number(
												Math.round(
													location.state.selectedReturn[0].Fare.agentMarkup
												)
											)
									)
							) * 2.5
						) / 100
				  )
				: etravrtboo
				? Number(
						Number(
							Math.round(location.state.selectedonward[0].Fare.Total_Amount)
						)
				  ) +
				  Number(
						Number(
							Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
						)
				  ) +
				  Number(
						Number(
							Number(
								Number(
									Number(
										Math.round(
											location.state.selectedonward[0].Fare.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedonward[0].Fare.agentMarkup
											)
										)
								) +
									Number(
										Number(
											Math.round(
												location.state.selectedReturn[0].Fares.Total_Amount
											)
										) -
											Number(
												Math.round(
													location.state.selectedReturn[0].Fares.agentMarkup
												)
											)
									)
							) * 2.5
						) / 100
				  )
				: ''
			: tbo2
			? Number(
					Number(Math.round(location.state.selectedonward[0].Fare.Total_Amount))
			  ) +
			  Number(
					Number(Math.round(location.state.selectedReturn[0].Fare.Total_Amount))
			  ) +
			  Number(
					Number(
						Number(
							Number(
								Number(
									Math.round(location.state.selectedonward[0].Fare.Total_Amount)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fare.agentMarkup
										)
									)
							) +
								Number(
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fare.agentMarkup
											)
										)
								)
						) * 2.5
					) / 100
			  )
			: etrav2
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.Total_Amount)
					)
			  ) +
			  Number(
					Number(
						Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
					)
			  ) +
			  Number(
					Number(
						Number(
							Number(
								Number(
									Math.round(
										location.state.selectedonward[0].Fares.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fares.agentMarkup
										)
									)
							) +
								Number(
									Number(
										Math.round(
											location.state.selectedReturn[0].Fares.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fares.agentMarkup
											)
										)
								)
						) * 2.5
					) / 100
			  )
			: etrvotbor
			? Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.Total_Amount)
					)
			  ) +
			  Number(
					Number(Math.round(location.state.selectedReturn[0].Fare.Total_Amount))
			  ) +
			  Number(
					Number(
						Number(
							Number(
								Number(
									Math.round(
										location.state.selectedonward[0].Fares.Total_Amount
									)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fares.agentMarkup
										)
									)
							) +
								Number(
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fare.agentMarkup
											)
										)
								)
						) * 2.5
					) / 100
			  )
			: etravrtboo
			? Number(
					Number(Math.round(location.state.selectedonward[0].Fare.Total_Amount))
			  ) +
			  Number(
					Number(
						Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
					)
			  ) +
			  Number(
					Number(
						Number(
							Number(
								Number(
									Math.round(location.state.selectedonward[0].Fare.Total_Amount)
								) -
									Number(
										Math.round(
											location.state.selectedonward[0].Fare.agentMarkup
										)
									)
							) +
								Number(
									Number(
										Math.round(
											location.state.selectedReturn[0].Fares.Total_Amount
										)
									) -
										Number(
											Math.round(
												location.state.selectedReturn[0].Fares.agentMarkup
											)
										)
								)
						) * 2.5
					) / 100
			  )
			: '',
		totalEarn: 0,
		cashBack: '0.00',
		couponDiscount: 0.0,
		couponDetails: '',
		pricingDetails: tbo2
			? [priceDetails2, priceDetails3]
			: etrav2
			? fareQuoteEtrav.result.AirRepriceResponses
			: etrvotbor
			? [priceDetails2, fareQuoteEtrav.result.AirRepriceResponses[0]]
			: etravrtboo
			? [fareQuoteEtrav.result.AirRepriceResponses[0], priceDetails2]
			: '',
		selectedFlight: [
			location.state.selectedonward[0],
			location.state.selectedReturn[0],
		],
		// airlineType: '',
		selectedPax: BookingFormData,
		ticketSent: 0,
		availableOnNewPrice: tbo2
			? Number(
					Number(
						Math.round(fareQuote?.result?.Response?.Results?.Fare.Total_Amount)
					) +
						Number(
							Math.round(
								fareQuoteReturn?.result?.Response?.Results.Fare.Total_Amount
							)
						) -
						Number(
							Number(
								Math.round(
									fareQuote?.result?.Response?.Results?.Fare.MFB_Discount
								)
							) +
								Number(
									Math.round(
										fareQuoteReturn?.result?.Response?.Results.Fare.MFB_Discount
									)
								)
						)
			  ) -
			  Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.Total_Amount)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
						) -
						Number(
							Number(
								Math.round(location.state.selectedonward[0].Fare.MFB_Discount)
							) >
								Number(
									Math.round(location.state.selectedReturn[0].Fare.MFB_Discount)
								)
						)
			  )
				? true
				: Number(
						Number(
							Math.round(
								fareQuote?.result?.Response?.Results?.Fare.Total_Amount
							)
						) +
							Number(
								Math.round(
									fareQuoteReturn?.result?.Response?.Results.Fare.Total_Amount
								)
							) -
							Number(
								Number(
									Math.round(
										fareQuote?.result?.Response?.Results?.Fare.MFB_Discount
									)
								) +
									Number(
										Math.round(
											fareQuoteReturn?.result?.Response?.Results.Fare
												.MFB_Discount
										)
									)
							)
				  ) <
				  Number(
						Number(
							Math.round(location.state.selectedonward[0].Fare.Total_Amount)
						) +
							Number(
								Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
							) -
							Number(
								Number(
									Math.round(location.state.selectedonward[0].Fare.MFB_Discount)
								) +
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.MFB_Discount
										)
									)
							)
				  )
				? true
				: false
			: '',
		priceChange: tbo2
			? Number(
					Number(
						Math.round(fareQuote?.result?.Response?.Results?.Fare.Total_Amount)
					) +
						Number(
							Math.round(
								fareQuoteReturn?.result?.Response?.Results.Fare.Total_Amount
							)
						) -
						Number(
							Number(
								Math.round(
									fareQuote?.result?.Response?.Results?.Fare.MFB_Discount
								)
							) +
								Number(
									Math.round(
										fareQuoteReturn?.result?.Response?.Results.Fare.MFB_Discount
									)
								)
						)
			  ) -
			  Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.Total_Amount)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
						) -
						Number(
							Number(
								Math.round(location.state.selectedonward[0].Fare.MFB_Discount)
							) +
								Number(
									Math.round(location.state.selectedReturn[0].Fare.MFB_Discount)
								)
						)
			  )
			: etrav2
			? Number(
					Number(
						Math.round(
							fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight.Fares
								.Total_Amount
						)
					) +
						Number(
							Math.round(
								fareQuoteEtrav?.result?.AirRepriceResponses[1].Flight.Fares
									.Total_Amount
							)
						)
			  ) -
			  Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.Total_Amount)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
						)
			  )
			: '',
		priceValue: tbo2
			? Number(
					Number(
						Math.round(fareQuote?.result?.Response?.Results?.Fare.Total_Amount)
					) +
						Number(
							Math.round(
								fareQuoteReturn?.result?.Response?.Results.Fare.Total_Amount
							)
						) -
						Number(
							Number(
								Math.round(
									fareQuote?.result?.Response?.Results?.Fare.MFB_Discount
								)
							) +
								Number(
									Math.round(
										fareQuoteReturn?.result?.Response?.Results.Fare.MFB_Discount
									)
								)
						)
			  ) -
			  Number(
					Number(
						Math.round(location.state.selectedonward[0].Fare.Total_Amount)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
						) -
						Number(
							Number(
								Math.round(location.state.selectedonward[0].Fare.MFB_Discount)
							) >
								Number(
									Math.round(location.state.selectedReturn[0].Fare.MFB_Discount)
								)
						)
			  )
				? 'PRICEUP'
				: Number(
						Number(
							Math.round(
								fareQuote?.result?.Response?.Results?.Fare.Total_Amount
							)
						) +
							Number(
								Math.round(
									fareQuoteReturn?.result?.Response?.Results.Fare.Total_Amount
								)
							) -
							Number(
								Number(
									Math.round(
										fareQuote?.result?.Response?.Results?.Fare.MFB_Discount
									)
								) +
									Number(
										Math.round(
											fareQuoteReturn?.result?.Response?.Results.Fare
												.MFB_Discount
										)
									)
							)
				  ) <
				  Number(
						Number(
							Math.round(location.state.selectedonward[0].Fare.Total_Amount)
						) +
							Number(
								Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
							) -
							Number(
								Number(
									Math.round(location.state.selectedonward[0].Fare.MFB_Discount)
								) +
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.MFB_Discount
										)
									)
							)
				  )
				? 'PRICEDOWN'
				: 'NOCHANGE'
			: etrav2
			? Number(
					Number(
						Math.round(
							fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight.Fares
								.Total_Amount
						)
					) +
						Number(
							Math.round(
								fareQuoteEtrav?.result?.AirRepriceResponses[1].Flight.Fares
									.Total_Amount
							)
						)
			  ) -
			  Number(
					Number(
						Math.round(location.state.selectedonward[0].Fares.Total_Amount)
					) +
						Number(
							Math.round(location.state.selectedReturn[0].Fares.Total_Amount)
						)
			  )
				? 'PRICEUP'
				: Number(
						Number(
							Math.round(
								fareQuoteEtrav?.result?.AirRepriceResponses[0].Flight.Fares
									.Total_Amount
							)
						) +
							Number(
								Math.round(
									fareQuoteEtrav?.result?.AirRepriceResponses[1].Flight.Fares
										.Total_Amount
								)
							)
				  ) <
				  Number(
						Number(
							Math.round(location.state.selectedonward[0].Fare.Total_Amount)
						) +
							Number(
								Math.round(location.state.selectedReturn[0].Fare.Total_Amount)
							) -
							Number(
								Number(
									Math.round(location.state.selectedonward[0].Fare.MFB_Discount)
								) +
									Number(
										Math.round(
											location.state.selectedReturn[0].Fare.MFB_Discount
										)
									)
							)
				  )
				? 'PRICEDOWN'
				: 'NOCHANGE'
			: '',
		paymentMode: agentlogin
			? payment === 'offline'
				? 'OFFLINE'
				: 'ONLINE'
			: 'ONLINE',
		agent_id:
			agentlogin && agentlogin.status === 200
				? agentlogin && agentlogin.result.result.id
				: 0,
	};
	dispatch(
		FlightAddBookingDispatch(
			formaddBooking,
			payment,
			payableAmout,
			handlePayment,
			location
		)
	);
	console.log('add booking', formaddBooking);
};
export default addBookingReturn;
