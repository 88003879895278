import React from 'react';
import {
	Dialog,
	IconButton,
	DialogTitle,
	DialogContent,
	makeStyles,
	Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector, useDispatch } from 'react-redux';
import { toggleLoginModal } from '../../reducers/UiReducer';
import LoginForm from './LoginForm';
const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
		backdropFilter: 'blur(2px)',
		'&MuiDialog-paperScrollPaper': {
			borderRadius: 5,
		},
		'& .MuiDialog-paper': {
			[theme.breakpoints.down('xs')]: {
				margin: '10px',
				position: 'relative',
				overflowY: 'auto',
			},
		},
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		// top: theme.spacing(3),
		padding: 0,
		[theme.breakpoints.down('xs')]: {
			top: theme.spacing(2.5),
		},
		color: theme.palette.grey[500],
	},
}));

const Login = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { createlogin } = useSelector((state) => state.ui);
	const { logo } = useSelector((state) => state.home);
	const handleClose = () => {
		dispatch(toggleLoginModal(false));
	};
	return (
		<Dialog
			PaperProps={{
				style: { borderRadius: 20, maxWidth: 400 },
			}}
			TransitionProps={{
				style: { backgroundColor: 'transparent' },
			}}
			onClose={handleClose}
			aria-labelledby='customized-dialog-title'
			className={classes.root}
			open={createlogin}>
			<DialogTitle
				id='customized-dialog-title'
				onClose={handleClose}
				style={{ marginTop: 12, padding: 0 }}>
				<Box textAlign='center'>
					<Box display='flex' justifyContent='space-evenly'>
						<img src={logo?.result[0].logo} height='40px'></img>
						<IconButton
							aria-label='close'
							className={classes.closeButton}
							onClick={handleClose}>
							<CloseIcon style={{ color: '#0035F0' }} fontSize='large' />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>
			<DialogContent
				style={{
					paddingBottom: 20,
				}}>
				<LoginForm />
			</DialogContent>
		</Dialog>
	);
};

export default Login;
