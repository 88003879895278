import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {
	makeStyles,
	TextField,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setOrigin, setOriginO } from '../../reducers/UiReducer';
import { useMediaQuery, useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		'& label': {
			display: 'none',
		},
		width: '100%',
		border: '1px solid lightgrey',
		borderRadius: 4,
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			fontSize: 12,
			padding: '12px',
			fontWeight: 600,
		},
		'& .MuiInputAdornment-root': {
			marginRight: 14,
		},
		'& .MuiInput-root': {
			marginTop: 0,
		},
	},
	new: {
		'& .css-mvmu1r': {
			marginTop: 18,
		},

		'& .MuiButtonBase-root': {
			fontSize: 20,
		},
		'& input::placeholder': {
			fontSize: '30px',
		},
	},
}));
export default function DesktopDateFlight() {
	const [value, setValue] = React.useState(new Date());
	const [isOpen, setIsOpen] = React.useState(false);
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const convert = (str) => {
		var date = new Date(str),
			mnth = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join('-');
	};
	dispatch(setOrigin(convert(value)));
	return (
		<div className={classes.new}>
			{matches ? (
				<TextField
					id='date'
					label='Choose your date'
					type='date'
					className={classes.selectField}
					onChange={(e) => {
						setValue(e.target.value);
						console.log(e.target.value);
						dispatch(setOriginO(e.target.value));
					}}
					// defaultValue="2017-05-24"
					InputLabelProps={{
						shrink: true,
					}}
				/>
			) : (
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DesktopDatePicker
						label='From'
						value={value}
						disablePast
						// minDate={new Date("2017-01-01")}
						// shouldDisableDate={disableWeekends}
						inputFormat='dd/MM/yyyy'
						onChange={(newValue) => {
							setValue(newValue);
							console.log(newValue);
							dispatch(setOriginO(newValue));
						}}
						open={isOpen}
						onOpen={() => setIsOpen(true)}
						onClose={() => setIsOpen(false)}
						renderInput={(params) => (
							<TextField
								{...params}
								className={classes.selectField}
								onClick={(e) => setIsOpen(true)}
							/>
						)}
					/>
				</LocalizationProvider>
			)}
		</div>
	);
}