import { axiosInstance } from '../Axios';
import { loader, storeData, toggleSessionExpiredModal } from './UiReducer';
const { createSlice } = require('@reduxjs/toolkit');

const TripjackReducer = createSlice({
	name: 'Tripjack',
	initialState: {
		success: false,
		error: null,
		loadtrip: false,
		flightresult: null,
		balance: null,
		payment: null,
		farequotetripjack: null,
		fareruletripjack: null,
		flightbook: null,
		bookingDetail: null,
		ssrtripjack: null,
	},
	reducers: {
		fareQuoteTripJackRequest(state, action) {
			state.loadtrip = true;
		},
		fareQuoteTripJackSuccess(state, action) {
			state.loadtrip = false;
			state.farequotetripjack = action.payload;
			state.error = null;
		},
		fareQuoteTripJackFail(state, action) {
			state.loadtrip = false;
			state.error = action.payload;
		},
		ssrTripJackRequest(state, action) {
			state.loadtrip = true;
		},
		ssrTripJackSuccess(state, action) {
			state.loadtrip = false;
			state.ssrtripjack = action.payload;
			state.error = null;
		},
		ssrTripJackFail(state, action) {
			state.loadtrip = false;
			state.error = action.payload;
		},
		fareRuleTripJackRequest(state, action) {
			state.loadtrip = true;
		},
		fareRuleTripJackSuccess(state, action) {
			state.loadtrip = false;
			state.fareruletripjack = action.payload;
			state.error = null;
		},
		fareRuleTripJackFail(state, action) {
			state.loadtrip = false;
			state.error = action.payload;
		},
		flightTripJackRequest(state, action) {
			state.loadtrip = true;
		},
		flightTripJackSuccess(state, action) {
			state.loadtrip = false;
			state.flightbook = action.payload;
			state.error = null;
		},
		flightTripJackFail(state, action) {
			state.loadtrip = false;
			state.error = null;
		},
		flightBDRequest(state, action) {
			state.loadtrip = true;
		},
		flightBDSuccess(state, action) {
			state.loadtrip = false;
			state.bookingDetail = action.payload;
			state.error = null;
		},
		flightBDFail(state, action) {
			state.loadtrip = false;
			state.error = null;
		},
	},
});

const { actions } = TripjackReducer;
const config = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': 'NjA4MzFiNDBkZmI1NQ==',
	},
};
export const {
	fareQuoteTripJackRequest,
	fareQuoteTripJackSuccess,
	fareQuoteTripJackFail,
	flightTripJackRequest,
	flightTripJackSuccess,
	flightTripJackFail,
	flightBDRequest,
	flightBDSuccess,
	flightBDFail,
	fareRuleTripJackRequest,
	fareRuleTripJackSuccess,
	fareRuleTripJackFail,
	ssrTripJackRequest,
	ssrTripJackSuccess,
	ssrTripJackFail,
} = actions;

export const FlightFareQuoteTripJackDispatch =
	(bodyData) => async (dispatch) => {
		try {
			dispatch(fareQuoteTripJackRequest());

			const { data } = await axiosInstance.post(
				'FlyTjk/flightfarequote',
				bodyData,
				config
			);
			dispatch(fareQuoteTripJackSuccess(data));
			if (data.status === 200) {
				let ssr = {
					bookingId: data.result.bookingId,
				};
				// dispatch(FlightSSRTripJackDispatch(ssr));
			} else {
				dispatch(toggleSessionExpiredModal(true));
			}
		} catch (error) {
			dispatch(toggleSessionExpiredModal(true));
			dispatch(
				fareQuoteTripJackFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const FlightSSRTripJackDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(ssrTripJackRequest());

		const { data } = await axiosInstance.post(
			'FlyTjk/specialservicerequest',
			bodyData,
			config
		);
		dispatch(ssrTripJackSuccess(data));
		if (data.status === 200) {
			console.log('first');
		} else {
			dispatch(toggleSessionExpiredModal(true));
		}
	} catch (error) {
		dispatch(
			ssrTripJackFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const FlightFareRuleTripJackDispatch = (id) => async (dispatch) => {
	try {
		let bodyData = {
			id: id,
			flowType: 'SEARCH',
		};
		dispatch(fareRuleTripJackRequest());

		const { data } = await axiosInstance.post(
			'FlyTjk/flightfarerule',
			bodyData,
			config
		);
		// console.log(data);
		dispatch(fareRuleTripJackSuccess(data));
		// dispatch(flightBalanceEtravDispatch());
		// if (data.status === 202) {
		// 	dispatch(toggleErrorModal(true));
		// 	dispatch(setErrorMessage(data.message));
		// }
	} catch (error) {
		// dispatch(toggleErrorModal(true));
		// dispatch(
		// 	setErrorMessage(
		// 		error.response && error.response.data.message
		// 			? error.response.data.message
		// 			: error.message
		// 	)
		// );
		dispatch(
			fareRuleTripJackFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const FlightBookTripJackDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(flightTripJackRequest());

			const { data } = await axiosInstance.post(
				'FlyTjk/flightbook',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(flightTripJackSuccess(data));
			if (data.status === 200) {
				let formData = {
					bookingId: data.result.bookingId,
					orderId: bodyData.orderId,
				};
				dispatch(BookingDetailTripJackDispatch(formData, history));
			} else {
				history.push('/response');
			}
			// dispatch(flightBalanceEtravDispatch());
			// if (data.status === 202) {
			// 	dispatch(toggleErrorModal(true));
			// 	dispatch(setErrorMessage(data.message));
			// }
		} catch (error) {
			// dispatch(toggleErrorModal(true));
			// dispatch(
			// 	setErrorMessage(
			// 		error.response && error.response.data.message
			// 			? error.response.data.message
			// 			: error.message
			// 	)
			// );
			dispatch(
				flightTripJackFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};

export const BookingDetailTripJackDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(flightBDRequest());

			const { data } = await axiosInstance.post(
				'FlyTjk/flightbookingdetails',
				bodyData,
				config
			);
			// console.log(data);
			dispatch(flightBDSuccess(data));
			if (data.status === 200) {
				history.push('/ticket');
			}
			// dispatch(flightBalanceEtravDispatch());
			// if (data.status === 202) {
			// 	dispatch(toggleErrorModal(true));
			// 	dispatch(setErrorMessage(data.message));
			// }
		} catch (error) {
			// dispatch(toggleErrorModal(true));
			// dispatch(
			// 	setErrorMessage(
			// 		error.response && error.response.data.message
			// 			? error.response.data.message
			// 			: error.message
			// 	)
			// );
			dispatch(
				flightBDFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export default TripjackReducer;
