import React, { useEffect } from 'react';
import { Grid, Box, Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fetchCityDispatch } from '../reducers/HomeReducer';
import { setSection } from '../reducers/UiReducer';
import SearchEngineBus from './SearchEngineBus';
import SearchEngineHotel from './SearchEngineHotel';
import SearchEngineFlight from './SearchEngineFlight';
import SearchEngineForex from './SearchEngineForex';

const SearchEngine = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { section } = useSelector((state) => state.ui);
	const { header } = useSelector((state) => state.home);
	const handleSection = (value) => {
		dispatch(setSection(value));
	};
	useEffect(() => {
		let cityData = {
			keywords: 'l',
			limits: 8,
		};
		dispatch(fetchCityDispatch(cityData));
	}, []);

	return (
		<>
			<div style={{ marginTop: matches ? 100 : 100 }}>
				<Grid container>
					<Grid item xs={12}>
						<Box display='flex'>
							{header?.result[0]?.status === '1' ? (
								<Box
									onClick={() => {
										handleSection('flight');
										history.push('/Flights-Booking');
									}}
									style={{
										background: section === 'flight' ? 'white' : '#003772',
										padding: 14,
										cursor: 'pointer',
									}}
									display='flex'
									alignItems='center'>
									<i
										class='fa-solid fa-plane'
										style={{
											color: section === 'flight' ? 'blue' : 'white',
											fontSize: matches ? 12 : '',
										}}></i>
									<span
										style={{
											color: section === 'flight' ? 'blue' : 'white',
											marginLeft: '12px',
											fontSize: matches ? '12px' : '.8em',
										}}>
										Flights
									</span>
								</Box>
							) : (
								''
							)}
							{header?.result[2]?.status === '1' ? (
								<Box
									onClick={() => {
										handleSection('hotel');
										history.push('/hotel-booking');
									}}
									style={{
										background: section === 'hotel' ? 'white' : '#003772',
										padding: 14,
										cursor: 'pointer',
									}}
									display='flex'
									alignItems='center'>
									<i
										class='fa-solid fa-hotel'
										style={{
											color: section === 'hotel' ? 'blue' : 'white',
										}}></i>
									<span
										style={{
											color: section === 'hotel' ? 'blue' : 'white',
											marginLeft: '12px',
											fontSize: matches ? 12 : '.8em',
										}}>
										Hotels
									</span>
								</Box>
							) : (
								''
							)}
							{header?.result[1]?.status === '1' ? (
								<Box
									onClick={() => {
										handleSection('bus');
										history.push('/bus-booking');
									}}
									style={{
										background: section === 'bus' ? 'white' : '#003772',
										padding: 14,
										cursor: 'pointer',
									}}
									display='flex'
									alignItems='center'>
									<i
										class='fa-solid fa-bus'
										style={{
											color: section === 'bus' ? 'blue' : 'white',
											fontSize: matches ? 12 : '',
										}}></i>
									<span
										style={{
											color: section === 'bus' ? 'blue' : 'white',
											marginLeft: '12px',
											fontSize: matches ? 12 : '.8em',
										}}>
										Buses
									</span>
								</Box>
							) : (
								''
							)}

							{/* <Hidden only={['xs']}>
								<Box
									onClick={() => {
										handleSection('Forex');
										history.push('/forex');
									}}
									style={{
										background: section === 'Forex' ? 'white' : '#003772',
										padding: 14,
										cursor: 'pointer',
									}}
									display='flex'
									alignItems='center'>
									<i
										class='fas fa-file-invoice-dollar'
										style={{
											color: section === 'Forex' ? 'blue' : 'white',
										}}></i>
									<span
										style={{
											color: section === 'Forex' ? 'blue' : 'white',
											marginLeft: '12px',
											fontSize: '.8em',
										}}>
										Forex
									</span>
								</Box>
							</Hidden> */}
						</Box>
					</Grid>
				</Grid>
				{section === 'flight' ? (
					<>
						<SearchEngineFlight />
					</>
				) : section === 'bus' ? (
					<>
						<div
							style={{
								background: '#fff',
								borderRadius: '0.25rem',
								padding: '1.25rem 1.875rem',
								boxShadow: '0 0 57px rgb(0 0 0 / 20%)',
							}}>
							<SearchEngineBus />
						</div>
					</>
				) : section === 'hotel' ? (
					<>
						<div
							style={{
								background: '#fff',
								borderRadius: '0.25rem',
								padding: '1.25rem 1.875rem',
								boxShadow: '0 0 57px rgb(0 0 0 / 20%)',
							}}>
							<SearchEngineHotel />
						</div>
					</>
				) : section === 'Forex' ? (
					<>
						<SearchEngineForex />
					</>
				) : (
					''
				)}
			</div>
		</>
	);
};

export default SearchEngine;
