import React from 'react';
import { Grid, Box, Hidden } from '@material-ui/core';
import '../../main.css';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import ResetPasswordForm from '../../form/ResetPasswordForm';
import SuccessModal from '../../components/reusableComponents/SuccessModal';
import ErrorModal from '../../components/reusableComponents/ErrorModal';
const ResetPassword = () => {
	return (
		<>
			<HeaderSection id={1} />
			<section
				style={{
					background: '#0c2f55',
					color: 'white',
					marginTop: 50,
					padding: 5,
				}}
				id='ticket2'>
				<h1 style={{ textAlign: 'center', fontFamily: 'Poppins' }}>
					Reset Password
				</h1>
			</section>
			<Grid container style={{ paddingLeft: 20 }}>
				<Hidden only={['xs', 'sm']}>
					<Grid item xs={4}></Grid>
				</Hidden>

				<Grid item xs={12} id='ticket' lg={4}>
					<Box
						style={{
							backgroundColor: '#FF F',
							boxShadow: '0px 0px 50px -35px rgb(0 0 0 / 40%)',
							padding: 20,
						}}>
						<ResetPasswordForm />
					</Box>
				</Grid>
				<Hidden only={['xs', 'sm']}>
					<Grid item xs={4}></Grid>
				</Hidden>
			</Grid>
			<SuccessModal />
			<ErrorModal />
		</>
	);
};

export default ResetPassword;
