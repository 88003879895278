import React, { useState } from 'react';
import {
	Dialog,
	DialogContent,
	makeStyles,
	Box,
	useTheme,
	useMediaQuery,
	Divider,
	Grid,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	withStyles,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import BodyText from './BodyText';
import TitleText from './TitleText';
import SuccessModal from './SuccessModal';
import { toggleBusDetailModal } from '../../reducers/UiReducer';
import {
	agentloginDispatch,
	agentlogoDispatch,
} from '../../reducers/UserReducer';
import ErrorModal from './ErrorModal';
import PassengerDetailRow from './PassengerDetailRow';
import FlightSegment from './FlightSegment';
import BusPassengerRow from './BusPassengerRow';
import BusSegment from './BusSegment';
const tableHeaders = ['Passenger Name', 'Gender', 'Age'];
const tableHeaders2 = [
	'Bus Type',
	'Origin',
	'Destination',
	'Date of Journey',
	'Date Of Issue',
];

const StyledTableCell = withStyles((theme) => ({
	head: {
		// fontWeight: 600,

		// padding: 3,
		fontSize: 12,
		textTransform: 'uppercase',
	},
	root: {
		borderBottom: 'none',
		paddingBottom: 0,
	},
}))(TableCell);
const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: 'transparent',
		backdropFilter: 'blur(2px)',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(3),
		[theme.breakpoints.down('xs')]: {
			top: theme.spacing(2.5),
		},
		color: theme.palette.grey[500],
	},
	nextButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 15,
		fontWeight: 600,
		width: '100%',
		padding: 10,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
}));

const BusModalDetail = ({ title }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { busdetail, selectbusbookingHistory } = useSelector(
		(state) => state.ui
	);
	const { bookinghistory, bookinghistoryagent, agentlogin } = useSelector(
		(state) => state.user
	);
	const [upload, setUpload] = useState(null);
	const [user, setUser] = useState({
		filename: '',
	});
	const { filename } = user;
	const onInputChange = (e) => {
		// setUser({ ...user, [e.target.name]: e.target.value });
		const fileToUpload = e.target.files;
		// setUser({ ...user, [e.target.name]: fileToUpload[0] });
		// console.log(e.target);
		setUpload(fileToUpload[0]);
	};
	let bookdata = [];

	// const parseData = JSON.parse(bookdata[0]?.bookingDetails)
	// console.log("Book2",parseData);
	const handleClose = () => {
		dispatch(toggleBusDetailModal(false));
		// dispatch(setSuccessMessage(''));
	};
	console.log('first', selectbusbookingHistory);
	const onSubmit = async (e) => {
		e.preventDefault();
		// var dat = user;
		// var formData = JSON.stringify(dat);
		// console.log(formData);
		// let formData2 = {
		// 	agent_id: 1,
		// 	agentLogo: dat,
		// };
		// console.log(e);
		let formUpdate = new FormData();
		formUpdate.append('agent_id', 1);
		formUpdate.append('agentLogo', upload);
		dispatch(agentlogoDispatch(formUpdate));
	};
	const theme = useTheme();
	const fileInputRef = React.createRef();
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	// const bookingDetail = JSON.parse(selectbusbookingHistory?.bookingDetails);
	// console.log('bookingDetail', bookingDetail);
	return (
		<Dialog
			PaperProps={{
				style: {
					borderRadius: 20,
					maxWidth: 1020,
				},
			}}
			TransitionProps={{
				style: { backgroundColor: 'transparent' },
			}}
			onClose={handleClose}
			aria-labelledby='customized-dialog-title'
			className={classes.root}
			open={busdetail}
			fullWidth={true}
			maxWidth={'xs'}>
			<DialogContent
				style={{
					paddingBottom: 20,
				}}>
				<h3
					three={matches ? false : true}
					style={{ fontWeight: 600, textAlign: 'center' }}>
					Bus Details
				</h3>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<h6>Order Id</h6>
						<h6>{selectbusbookingHistory?.orderId}</h6>
					</Grid>
					<Grid item xs={4}>
						<h6>PNR</h6>
						<h6>
							{selectbusbookingHistory
								? JSON.parse(selectbusbookingHistory?.bookingDetails)?.pnr
								: ''}
						</h6>
					</Grid>
					<Grid item xs={4}>
						<h6>Booking Status</h6>
						<h6>{selectbusbookingHistory?.bookingStatus}</h6>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<h6>Inventory Id</h6>
						<h6>
							{selectbusbookingHistory
								? JSON.parse(selectbusbookingHistory?.bookingDetails)
										?.inventoryId
								: ''}
						</h6>
					</Grid>
					<Grid item xs={4}>
						<h6>Bus Type</h6>
						<h6>
							{selectbusbookingHistory
								? JSON.parse(selectbusbookingHistory?.bookingDetails)?.busType
								: ''}
						</h6>
					</Grid>
					<Grid item xs={4}>
						<h6>Ticket Cost</h6>
						<h6>
							{selectbusbookingHistory
								? JSON.parse(selectbusbookingHistory?.bookingDetails)
										?.inventoryItems.fare
								: ''}
						</h6>
					</Grid>
				</Grid>

				<Divider />
				<h4 style={{ marginTop: 10 }}>Passenger Details</h4>
				<TableContainer style={{ marginTop: 15 }}>
					<Table>
						<TableHead>
							<TableRow>
								{tableHeaders.map((title, index) => (
									<StyledTableCell key={title}>{title}</StyledTableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							<BusPassengerRow
								parseData={
									selectbusbookingHistory
										? JSON.parse(selectbusbookingHistory.selectedPax)
										: null
								}
							/>
						</TableBody>
					</Table>
				</TableContainer>
				<h4>Bus Segments</h4>
				<TableContainer style={{ marginTop: 15 }}>
					<Table>
						<TableHead>
							<TableRow>
								{tableHeaders2.map((title, index) => (
									<StyledTableCell key={title}>{title}</StyledTableCell>
								))}
							</TableRow>
						</TableHead>
						{/* <FlightSegment
							parseData={
								bookdata[0] ? JSON.parse(bookdata[0].selectedFlight) : null
							}
						/> */}
						<BusSegment
							parseData={
								selectbusbookingHistory
									? JSON.parse(selectbusbookingHistory?.bookingDetails)
									: null
							}
						/>
					</Table>
				</TableContainer>
				<Grid container style={{ justifyContent: 'center' }}>
					<Box
						textAlign='center'
						style={{ cursor: 'pointer' }}
						mt={2}
						onClick={() => dispatch(toggleBusDetailModal(false))}>
						<BodyText textStyle={{ color: '#E61855', fontWeight: 600 }}>
							Close
							<Divider style={{ backgroundColor: '#E61855' }} />
						</BodyText>
					</Box>
				</Grid>
				<SuccessModal />
				<ErrorModal />
			</DialogContent>
		</Dialog>
	);
};

export default BusModalDetail;
