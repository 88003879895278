import React, { useEffect } from 'react';
import { Grid, Container, Box, Divider } from '@material-ui/core';
import HeaderSection from '../../components/reusableComponents/HeaderSection';
import Footer from './Footer';
// import Meta from '../../components/reusableComponents/Meta';
import {
	fetchFooterMenu,
	fetchFooterSub_Menu,
} from '../../reducers/HomeReducer';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const AboutusPage = () => {
	const dispatch = useDispatch();
	const { footer_submenu } = useSelector((state) => state.home);
	const location = useLocation();
	let data = [];
	if (footer_submenu) {
		if (footer_submenu && footer_submenu?.result?.length > 0) {
			footer_submenu?.result?.map((i) => {
				if (i.link_url === location?.pathname.split('/')[2]) {
					data.push({
						...i,
					});
				}
			});
		}
	}
	useEffect(() => {
		window.scrollTo(0, 0);
		let form = {};
		dispatch(fetchFooterMenu(form));
		dispatch(fetchFooterSub_Menu(form));
		if (location) {
			document
				.querySelector('meta[name="description"]')
				.setAttribute('content', location.state.content);
			document
				.querySelector('meta[name="keywords"]')
				.setAttribute('content', location.state.keywords);
			document.title = location.state.title;
		}
	}, []);

	console.log('location', location);
	return (
		<>
			{/* <Meta
				title={`Mariners Forex | ${
					data
						? data[0]?.name
						: location && location.state && location.state.name
				}`}
				description={
					'Mariners Forex provides you online hotel bookings all over the world. Book cheap, budget and luxury hotels at best price from leading hotel booking site.'
				}
				keywords={
					'online hotel booking, book hotels online, hotel booking site, cheap hotels, budget hotels, luxury hotels, star hotels, hotel booking, hotel reservation, hotel stay, accommodations'
				}
			/> */}
			<HeaderSection id={1} />
			<Box style={{ background: '#0c2f55', color: 'white', marginTop: 50 }}>
				<Grid container>
					<Grid item xs={8}>
						<h1 style={{ marginLeft: 20 }}>
							{data
								? data[0]?.name
								: location && location.state && location.state.name}
						</h1>
					</Grid>
					<Grid item xs={4}></Grid>
				</Grid>
			</Box>
			{location && location.state && location.state.name === 'SiteMap' ? (
				<Container
					style={{
						background: '#FFF',
						boxShadow: '0px 0px 50px -35px rgb(0 0 0 / 40%)',
						borderRadius: '.25rem',
						padding: '1.5em',
						marginTop: 20,
					}}>
					<Grid container>
						<Grid item xs={4}>
							<h4>Home</h4>
							<a href='/Flights-Booking' target='_blank'>
								Flight Booking
							</a>
							<br></br>
							<a href='/bus-booking' target='_blank'>
								Bus Booking
							</a>
							<br></br>
							<a href='/hotel-booking' target='_blank'>
								Hotel Booking
							</a>
							<br></br>
						</Grid>
						<Grid item xs={4}>
							<h4>Services</h4>
							<a href='/Flights-Booking' target='_blank'>
								Cheap Air Tickets
							</a>
							<br></br>
							<a href='/bus-booking' target='_blank'>
								Cheap Bus Tickets
							</a>
							<br></br>
							<a href='/hotel-booking' target='_blank'>
								Cheap Hotel Tickets
							</a>
							<br></br>
						</Grid>
						<Grid item xs={4}></Grid>
					</Grid>
				</Container>
			) : (
				<Container
					style={{
						background: '#FFF',
						boxShadow: '0px 0px 50px -35px rgb(0 0 0 / 40%)',
						borderRadius: '.25rem',
						padding: '1.5em',
						marginTop: 20,
					}}>
					<div
						style={{ fontSize: 11, fontWeight: 'bold' }}
						dangerouslySetInnerHTML={{
							__html: data
								? data[0]?.content
								: location && location.state && location.state.content,
						}}
					/>
				</Container>
			)}
			<Container>
				<Divider style={{ marginTop: 20 }} />
			</Container>
			<Footer />
		</>
	);
};

export default AboutusPage;
