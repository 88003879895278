import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import LoaderModal from '../components/reusableComponents/LoaderModal';
import Dashboard from './reusableComponents/Dashboard';
import { useSelector } from 'react-redux';
import HomeLoader from './reusableComponents/HomeLoader';
import YourTicket from './reusableComponents/YourTicket';
import MainLayout from '../layout/MainLayout';
import MyProfileForm from './reusableComponents/MyProfileForm';
import ChangePasswordForm from './reusableComponents/ChangePasswordForm';
import ManageMarkupForm from './reusableComponents/ManageMarkupForm';
import BankDetails from './reusableComponents/BankDetails';
import BookingHistory from './reusableComponents/BookingHistory';
import TransactionHistory from './reusableComponents/TransactionHistory';
import OneWay from '../pages/Desktop/OneWay';
import BusList from '../pages/Desktop/BusList';
import RoundTrip from '../pages/Desktop/RoundTrip';
import SummaryFlight from '../pages/Desktop/SummaryFlight';
import RoundTicket from '../components/reusableComponents/RoundTicket';
import ConfirmFlight from '../pages/Desktop/ConfirmFlight';
import AgentInvoice from './reusableComponents/AgentInvoice';
import AccountVerify from '../pages/Desktop/AccountVerify';
import AboutusPage from '../pages/Desktop/AboutusPage';
import HomePage from '../pages/Desktop/HomePage';
import Hotel from './reusableComponents/Hotel';
import TicketDisplay from './reusableComponents/TicketDisplay';
import ManageBookingTicket from './reusableComponents/ManageBookingTicket';
import ResetPassword from '../pages/Desktop/ResetPassword';
import International from '../pages/Desktop/International';
import PaymentVerify from '../pages/Desktop/PaymentVerify';
import PaymentDetailsBus from '../pages/Desktop/PaymentDetailsBus';
import EtravTBOTicket from './reusableComponents/EtravTBOTicket';
import TBOEtravTicket from './reusableComponents/TBOEtravTicket';
import HotelList from '../pages/Desktop/HotelList';
import BusTicket from './reusableComponents/BusTicket';
import ErrorPage from '../pages/Desktop/ErrorPage';
import FailedPage from '../pages/Desktop/FailedPage';
import HotelSummaryForm from '../form/HotelSummaryForm';
import SummaryHotel from '../pages/Desktop/SummaryHotel';
import HotelTicket from './reusableComponents/HotelTicket';
import ConfirmHotel from '../pages/Desktop/ConfirmHotel';
import Error404Page from '../pages/Desktop/Error404Page';
import PrintTicket from '../pages/Desktop/PrintTicket';
import BusBookingHistory from './reusableComponents/BusBookingHistory';
import BusTicketDisplay from './reusableComponents/BusTicketDisplay';
const LazyHome = React.lazy(() => import('../pages/Desktop/HomePage'));

const MainRouter = () => {
	const { load } = useSelector((state) => state.etrav);
	const { loading, loadingBook } = useSelector((state) => state.home);
	const { loadingbus, loadingbus2 } = useSelector((state) => state.bus);
	const { loadtrip } = useSelector((state) => state.tripjack);
	const { loadinghoteldes } = useSelector((state) => state.hotel);
	const { loadinguser } = useSelector((state) => state.user);
	return (
		<div>
			<Router>
				<Switch>
					<Route exact path={'/'}>
						<React.Suspense fallback={<HomeLoader />}>
							<LazyHome />
						</React.Suspense>
					</Route>
					<Route exact path={'/Flights-Booking'}>
						<React.Suspense fallback={<HomeLoader />}>
							<LazyHome />
						</React.Suspense>
					</Route>
					<Route exact path={'/bus-booking'}>
						<React.Suspense fallback={<HomeLoader />}>
							<LazyHome />
						</React.Suspense>
					</Route>
					<Route exact path={'/hotel-booking'}>
						<React.Suspense fallback={<HomeLoader />}>
							<LazyHome />
						</React.Suspense>
					</Route>
					<Route exact path={'/forex'}>
						<React.Suspense fallback={<HomeLoader />}>
							<LazyHome />
						</React.Suspense>
					</Route>
					<Route exact path={'/online/:menu'}>
						<AboutusPage />
					</Route>
					<Route exact path={'/accountVerify'}>
						<AccountVerify />
					</Route>
					<Route exact path={'/paymentVerify'}>
						<PaymentVerify />
					</Route>
					<Route exact path={'/oneway/:from/:to/:date'}>
						<OneWay />
					</Route>
					<Route exact path={'/international'}>
						<International />
					</Route>
					<Route extact path={'/roundway/:from/:to/:date/:tor/:fromr/:dater'}>
						<RoundTrip />
					</Route>
					<Route exact path={'/buslist'}>
						<BusList />
					</Route>
					<Route exact path={'/response'}>
						<ErrorPage />
					</Route>
					<Route exact path={'/failed'}>
						<FailedPage />
					</Route>
					<Route exact path={'/hotellist'}>
						<HotelList />
					</Route>
					<Route exact path={'/hotellist/:id'}>
						<Hotel />
					</Route>
					<Route exact path={'/buspaymentdetails'}>
						<PaymentDetailsBus />
						{loadingbus || loadingbus2 ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/resetpassword'}>
						<ResetPassword />
						{loadinguser ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/printticket'}>
						<PrintTicket />
					</Route>
					<Route exact path={'/showticket'}>
						<ManageBookingTicket />
						{loadinguser ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/summaryflight'}>
						<SummaryFlight />
						{load || loadinguser || loading || loadtrip ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/summaryhotel'}>
						<SummaryHotel />
						{loadinghoteldes ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/confirmhotel'}>
						<ConfirmHotel />
					</Route>
					<Route exact path={'/confirmflight'}>
						<ConfirmFlight />
						{load || loadinguser || loading || loadtrip ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/ticket'}>
						<YourTicket />
						{load || loadingBook ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/busticket'}>
						<BusTicket />
						{load ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/busticketdisplay'}>
						<BusTicketDisplay />
						{load ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/hotelticket'}>
						<HotelTicket />
						{load ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/ticketroundtrip'}>
						<RoundTicket />
						{load || loadinguser || loading || loadtrip || loadingBook ? (
							<LoaderModal />
						) : (
							''
						)}
					</Route>
					<Route exact path={'/ticketroundtripet'}>
						<EtravTBOTicket />
						{load || loadinguser || loading || loadtrip ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/ticketroundtripte'}>
						<TBOEtravTicket />
						{load || loadinguser || loading || loadtrip ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/ticketdisplay'}>
						<TicketDisplay />
						{load ? <LoaderModal /> : ''}
					</Route>
					<Route exact path={'/agentinvoice'}>
						<AgentInvoice />
						{load ? <LoaderModal /> : ''}
					</Route>
					<Route path='/dashboard'>
						<MainLayout>
							<Dashboard />
						</MainLayout>
					</Route>
					<Route path='/settings'>
						<MainLayout>
							<Dashboard />
						</MainLayout>
					</Route>
					<Route path='/editprofile'>
						<MainLayout>
							{loadinguser ? <LoaderModal /> : <MyProfileForm />}
						</MainLayout>
					</Route>
					<Route path='/changepassword'>
						<MainLayout>
							{loadinguser ? <LoaderModal /> : <ChangePasswordForm />}
						</MainLayout>
					</Route>
					<Route path='/managemarkup'>
						<MainLayout>
							<ManageMarkupForm />
						</MainLayout>
					</Route>
					<Route path='/bank'>
						<MainLayout>{/* <BankDetails /> */}</MainLayout>
					</Route>
					<Route path='/bookhistory'>
						<MainLayout>
							<BookingHistory />
							{load || loadinguser || loading ? <LoaderModal /> : ''}
						</MainLayout>
					</Route>{' '}
					<Route path='/busbookhistory'>
						<MainLayout>
							<BusBookingHistory />
							{load || loadinguser || loading ? <LoaderModal /> : ''}
						</MainLayout>
					</Route>
					<Route path='/transactionhistory'>
						<MainLayout>
							<TransactionHistory />
						</MainLayout>
					</Route>
					<Route exact path='/404'>
						<Error404Page />
					</Route>
					<Route render={() => <Redirect to='/404' />} />
				</Switch>
			</Router>
		</div>
	);
};

export default MainRouter;
