import React from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import TitleText from '../../components/reusableComponents/TitleText';
import BodyText from '../../components/reusableComponents/BodyText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	toggleFlightModal,
	setEtravFlightFare,
} from '../../reducers/UiReducer';
import { setFlightId } from '../../reducers/UiReducer';
import {
	FlightFareRuleEtravDispatch,
	fareRuleEtravSuccess,
	fareRuleSuccess,
} from '../../reducers/HomeReducer';
import { FlightFareRuleDispatch } from '../../reducers/HomeReducer';

const OneWayMobileRow = ({
	item,
	loading,
	timeConvert,
	location,
	onewayflightdata,
	onewayflightdataEtrav,
	convertH2M,
	net,
}) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const history = useHistory();
	const dispatch = useDispatch();
	const { orderID, currency } = useSelector((state) => state.home);
	const { curr } = useSelector((state) => state.ui);
	return (
		<>
			<Box
				display='flex'
				style={{
					padding: 8,
					background: 'white',
					borderTopLeftRadius: '5px',
					borderTopRightRadius: '5px',
				}}
				justifyContent='space-between'
				alignItems='center'
				mt={'20px'}>
				<Box alignItems='center'>
					{loading ? (
						<Skeleton variant='circle' width={40} height={40} />
					) : (
						<img
							src={`https://nitish.musafirbazar.com/static/media/${
								item.AirlineCode
									? item.AirlineCode
									: item.Airline_Code
									? item.Airline_Code
									: item.Supplier === 'TJK'
									? item.sI[0].fD.aI.code
									: ''
							}.gif`}
							width='50px'
							height='25px'></img>
					)}

					<Box alignItems='center' ml={'5px'}>
						<TitleText
							two={matches ? false : true}
							textStyle={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 500,
								color: '#3c3c3c',
								fontSize: matches ? 12 : '',
							}}>
							{loading ? (
								<Skeleton variant='text' width='60px' />
							) : item.Airline_Code ? (
								item.Segments[0].Airline_Name
							) : item.AirlineCode ? (
								item.Segments[0].slice(0, 1).map((i) => {
									return i.Airline.AirlineName;
								})
							) : item.Supplier === 'TJK' ? (
								item.sI[0].fD.aI.name
							) : (
								''
							)}
						</TitleText>
						{loading ? (
							<Skeleton variant='text' width='60px' />
						) : (
							<BodyText
								four
								textStyle={{
									fontFamily: 'Roboto Serif, sans-serif',
									color: '#8f8f8f',
									fontWeight: 600,
								}}>
								{item.Airline_Code
									? item.Segments[0].Airline_Code
									: item.AirlineCode
									? item.Segments[0].slice(0, 1).map((i) => {
											return i.Airline.AirlineCode;
									  })
									: item.Supplier === 'TJK'
									? item.sI[0].fD.aI.code
									: ''}
								-
								{item.Airline_Code
									? item.Segments[0].Flight_Number
									: item.AirlineCode
									? item.Segments[0].slice(0, 1).map((i) => {
											return i.Airline.FlightNumber;
									  })
									: item.Supplier === 'TJK'
									? item.sI[0].fD.fN
									: ''}
							</BodyText>
						)}
					</Box>
				</Box>
				<Box>
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText
							four
							textStyle={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 600,
							}}>
							{item.Origin
								? item.Origin
								: item.AirlineCode
								? item.FareRules[0].Origin
								: item.Supplier === 'TJK'
								? item.sI[0].da.cityCode
								: ''}
						</TitleText>
					)}
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText four>
							{item.Airline_Code
								? item.Segments[0].Departure_DateTime.substr(11, 5)
								: item.AirlineCode
								? item.Segments[0].slice(0, 1).map((i) => {
										return i.Origin.DepTime.substr(11, 5);
								  })
								: item.Supplier === 'TJK'
								? item.sI[0].dt.substr(11, 5)
								: ''}
						</TitleText>
					)}
				</Box>
				<Box alignItems='center' textAlign='center'>
					{loading ? (
						<Skeleton variant='text' width='100px' />
					) : (
						<BodyText
							two
							textStyle={{
								color: 'rgb(239, 51, 57)',
								fontWeight: 700,
								fontSize: 10,
							}}>
							{timeConvert(
								item.Airline_Code
									? item.Segments.length === 2
										? Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 0).map(
														(it) => it.Duration
													)[0]
												)
										  ) +
										  Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 1).map(
														(it) => it.Duration
													)[0]
												)
										  )
										: item.Segments.length === 1
										? Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 0).map(
														(it) => it.Duration
													)[0]
												)
										  )
										: item.Segments.length === 3
										? Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 0).map(
														(it) => it.Duration
													)[0]
												)
										  ) +
										  Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 1).map(
														(it) => it.Duration
													)[0]
												)
										  ) +
										  Number(
												convertH2M(
													item.Segments.filter((i, index) => index === 2).map(
														(it) => it.Duration
													)[0]
												)
										  )
										: ''
									: item.AirlineCode
									? item.Segments[0].length == 2
										? Number(
												item.Segments[0]
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.Duration;
													})
										  ) +
										  Number(
												item.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Duration;
													})
										  )
										: item.Segments[0].length == 1
										? item.Segments[0].map((i) => i.Duration)
										: item.Segments[0].length == 3
										? Number(
												item.Segments[0]
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.Duration;
													})
										  ) +
										  Number(
												item.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Duration;
													})
										  ) +
										  Number(
												item.Segments[0]
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.Duration;
													})
										  )
										: ''
									: item.Supplier === 'TJK'
									? item.sI.length == 2
										? Number(
												item.sI
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.duration;
													})
										  ) +
										  Number(
												item.sI
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.duration;
													})
										  )
										: item.sI.length == 1
										? item.sI.map((i) => i.duration)
										: item.sI.length == 3
										? Number(
												item.sI
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.duration;
													})
										  ) +
										  Number(
												item.sI
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.duration;
													})
										  ) +
										  Number(
												item.sI
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.duration;
													})
										  )
										: ''
									: ''
							)}
						</BodyText>
					)}
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<BodyText
							two
							textStyle={{
								color: 'green',
								fontWeight: 'bold',
								fontSize: '14px',
							}}>
							{item.AirlineCode
								? item.Segments[0].length === 1
									? 'Non Stop'
									: item.Segments[0].length === 2
									? '1 Stop'
									: item.Segments[0].length === 3
									? '2 Stop'
									: ''
								: item.Airline_Code
								? item.Segments.length === 1
									? 'Non Stop'
									: item.Segments.length === 2
									? '1 Stop'
									: item.Segments.length === 3
									? '2 Stop'
									: ''
								: item.Supplier === 'TJK'
								? item.sI.length === 1
									? 'Non Stop'
									: item.sI.length === 2
									? '1 Stop'
									: item.sI.length === 3
									? '2 Stop'
									: ''
								: ''}
						</BodyText>
					)}
				</Box>
				<Box>
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText
							four
							textStyle={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 600,
							}}>
							{item.Airline_Code
								? item.Segments.length === 1
									? item.Segments.filter((i, index) => index === 0).map(
											(it) => {
												return it.Destination;
											}
									  )
									: item.Segments.length === 2
									? item.Segments.filter((i, index) => index === 1).map(
											(it) => {
												return it.Destination;
											}
									  )
									: item.Segments.length === 3
									? item.Segments.filter((i, index) => index === 2).map(
											(it) => {
												return it.Destination;
											}
									  )
									: ''
								: item.AirlineCode
								? item.Segments[0].length == 2
									? item.Segments[0]
											.filter((i, index) => index === 1)
											.map((it) => {
												return it.Destination.Airport.AirportCode;
											})
									: item.Segments[0].length == 3
									? item.Segments[0]
											.filter((i, index) => index === 2)
											.map((it) => {
												return it.Destination.Airport.AirportCode;
											})
									: item.Segments[0].length == 1
									? item.Segments[0].map((it) => {
											return it.Destination.Airport.AirportCode;
									  })
									: ''
								: item.Supplier === 'TJK'
								? item.sI.length === 1
									? item.sI
											.filter((i, index) => index === 0)
											.map((it) => {
												return it.aa.cityCode;
											})
									: item.sI.length === 2
									? item.sI
											.filter((i, index) => index === 1)
											.map((it) => {
												return it.aa.cityCode;
											})
									: item.sI.length === 3
									? item.sI
											.filter((i, index) => index === 2)
											.map((it) => {
												return it.aa.cityCode;
											})
									: ''
								: ''}
						</TitleText>
					)}
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText four>
							{item.Airline_Code
								? item.Segments.length === 1
									? item.Segments.filter((i, index) => index === 0).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: item.Segments.length === 2
									? item.Segments.filter((i, index) => index === 1).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: item.Segments.length === 3
									? item.Segments.filter((i, index) => index === 2).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: ''
								: item.AirlineCode
								? item.Segments[0].length == 3
									? item.Segments[0]
											.filter((i, index) => index === 2)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: item.Segments[0].length == 2
									? item.Segments[0]
											.filter((i, index) => index === 1)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: item.Segments[0].length == 1
									? item.Segments[0]
											.filter((i, index) => index === 0)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: ''
								: item.Supplier === 'TJK'
								? item.sI.length === 1
									? item.sI
											.filter((i, index) => index === 0)
											.map((it) => {
												return it.at.substr(11, 5);
											})
									: item.sI.length === 2
									? item.sI
											.filter((i, index) => index === 1)
											.map((it) => {
												return it.at.substr(11, 5);
											})
									: item.sI.length === 3
									? item.sI
											.filter((i, index) => index === 2)
											.map((it) => {
												return it.at.substr(11, 5);
											})
									: ''
								: ''}
						</TitleText>
					)}
				</Box>
				<Box alignItems='center' display='flex'>
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText
							four={matches ? false : true}
							textStyle={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 500,
								fontSize: 18,
							}}>
							{/* ₹{' '}
									{item.Airline_Code
										? Math.round(item.Fares[0].FareDetails[0].Total_Amount)
										: Math.round(item.Fare.PublishedFare)} */}
						</TitleText>
					)}

					{/* <MoreVertIcon /> */}
					{loading ? (
						<Skeleton variant='text' width='60px' height='60px' />
					) : (
						<>
							<Box
								onClick={() => {
									history.push({
										pathname: '/summaryflight',
										state: {
											OneflightData: item,
											body: location.state.body,
											IDTBO:
												onewayflightdata &&
												onewayflightdata.result &&
												onewayflightdata.result.Response &&
												onewayflightdata.result.Response.TraceId,
											IDEtrav:
												onewayflightdataEtrav &&
												onewayflightdataEtrav.result &&
												onewayflightdataEtrav.result.Search_Key,
											orderID: orderID.result,
										},
									});
								}}
								style={{
									color: 'white',
									marginLeft: 5,
									background: 'rgb(239, 51, 57)',
									padding: '8px 12px',
									borderRadius: 8,
									cursor: 'pointer',
									fontFamily: 'Josefin Sans, sans-serif',
									fontWeight: 500,
									boxShadow: '3px 3px 12px -6px rgb(0 0 0 / 70%)',
								}}>
								<TitleText
									textStyle={{
										fontSize: matches ? 14 : '',
									}}>
									<span style={{ marginRight: 5 }}>{'₹'}</span>
									{item.Airline_Code
										? Number(
												Math.round(Number(Math.round(item.Fares.Total_Amount)))
										  )
										: item.AirlineCode
										? Number(
												Math.round(
													Number(
														Number(Math.round(item.Fare.Total_Amount)) -
															Number(Math.round(item.Fare.MFB_Discount))
													)
												)
										  )
										: item.Supplier === 'TJK'
										? Number(
												Math.round(
													Number(
														Math.round(item.totalPriceList.fd.Total_Amount)
													)
												)
										  )
										: ''}
								</TitleText>
							</Box>
							{item.Airline_Code ? (
								<span>*</span>
							) : item.Supplier === 'TJK' ? (
								<span>**</span>
							) : (
								''
							)}
						</>
					)}
				</Box>
			</Box>

			<Box
				style={{
					padding: '6px 10px',
					background: 'white',
					// borderBottomLeftRadius: 4,
					// borderBottomRightRadius: 4,
					borderTop: '1px solid #e2e2e2',
				}}
				justifyContent='space-between'
				alignItems='center'
				display='flex'>
				<Box display='flex' alignItems='center'>
					{loading ? (
						<Skeleton variant='text' width='60px' height='40px' />
					) : (
						<Box
							style={{
								padding: '2px 10px',
								background: item.AirlineCode
									? item.FareClassification
										? item.FareClassification.Color
										: ''
									: '#f56d6d',
								borderRadius: 4,
								boxShadow: '3px 3px 12px -6px rgb(0 0 0 / 70%)',
							}}>
							<BodyText
								four
								textStyle={{
									color: item.AirlineCode
										? item.FareClassification
											? item.FareClassification.Color === 'Blue'
												? 'white'
												: 'black'
											: 'white'
										: 'white',
								}}>
								{item.AirlineCode
									? item.FareClassification?.Type
									: item.Airline_Code
									? item.Fares.Warning === null || item.Fares.Warning === ''
										? item.Fares.FareDetails[0].FareClasses[0].Class_Desc
										: 'MBFARE'
									: item.Supplier === 'TJK'
									? item.totalPriceList.fareIdentifier
									: ''}
							</BodyText>
						</Box>
					)}
					{item.Supplier === 'TBO' ? (
						item.IsRefundable ? (
							<span
								style={{
									color: 'white',
									marginLeft: 15,
									background: 'green',
									padding: '2px 10px',
									borderRadius: 5,
									fontSize: 12,
								}}>
								R
							</span>
						) : (
							<span
								style={{
									color: 'white',
									marginLeft: 15,
									background: 'red',
									padding: '2px 10px',
									borderRadius: 5,
									fontSize: 12,
								}}>
								N
							</span>
						)
					) : item.Supplier === 'ETR' ? (
						item.Fares.Refundable ? (
							<span
								style={{
									color: 'white',
									marginLeft: 15,
									background: 'green',
									padding: '2px 10px',
									borderRadius: 5,
									fontSize: 12,
								}}>
								R
							</span>
						) : (
							<span
								style={{
									color: 'white',
									marginLeft: 15,
									background: 'red',
									padding: '2px 10px',
									borderRadius: 5,
									fontSize: 12,
								}}>
								N
							</span>
						)
					) : (
						''
					)}
				</Box>

				<Box
					onClick={() => {
						dispatch(toggleFlightModal(true));
						dispatch(
							setFlightId(
								item.Airline_Code ? item.Fares.Fare_Id : item.ResultIndex
							)
						);

						if (item.Airline_Code) {
							dispatch(
								FlightFareRuleEtravDispatch(
									onewayflightdataEtrav &&
										onewayflightdataEtrav.result &&
										onewayflightdataEtrav.result.Search_Key,
									item.Flight_Key,
									item.Fares.Fare_Id
								)
							);
							dispatch(fareRuleSuccess(null));
						} else {
							dispatch(
								FlightFareRuleDispatch(
									item.ResultIndex,
									onewayflightdata &&
										onewayflightdata.result &&
										onewayflightdata.result.Response.TraceId
								)
							);
							dispatch(fareRuleEtravSuccess(null));
						}
					}}
					display='flex'
					justifyContent='space-between'>
					<Box></Box>
					<Box
						display='flex'
						alignItems={'center'}
						style={{ cursor: 'pointer' }}>
						<p style={{ fontSize: 10 }}>Flight Details</p>
						<ArrowDropDownIcon />
					</Box>
				</Box>
			</Box>
			<Box>
				<span
					style={{ fontSize: 10, color: 'rgb(243 111 17)', fontWeight: 600 }}>
					{item.AirlineCode
						? ''
						: item.Airline_Code
						? item.Fares.Warning === null || item.Fares.Warning === ''
							? ''
							: 'SPECIAL FARE. IT MAY TAKE 20-30 MINS FOR ISSUANCE. ISSUANCE DEPENDS UPON AVAILABILITY. PASSENGER NAME WILL BE UPDATED 12 TO 24 HOURS PRIOR TO DEPARTURE ON AIRLINE WEBSITE'
						: ''}
				</span>
			</Box>
		</>
	);
};

export default OneWayMobileRow;
